/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './css/AdminPanel.css';
import Form from '../Form/Form';
import { getOrderStatusText } from '../../Utils/CommonUtils';

function AdminPanel() {
  const [rates, setRates] = useState();
  const [orders, setOrders] = useState();

  const getExchangeRates = () => {
    const token = window.localStorage.getItem('accessToken');
    axios
      .get('/api/exchange/rates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setRates(result.data);
      }).catch(() => {});
  };

  const getOrders = () => {
    const token = window.localStorage.getItem('accessToken');

    axios
      .get('/api/order/all', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setOrders(result.data);
      }).catch(() => {});
  };

  useEffect(() => {
    getExchangeRates();
    getOrders();
  }, []);

  async function handleSubmin(id, currency, value) {
    let isSuccessfull = false;
    const token = window.localStorage.getItem('accessToken');

    const resoponse = await axios
      .post(`/api/exchange/rate/${id}/${currency}/${value}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    if (resoponse && resoponse.data === 'Successfully updated') {
      isSuccessfull = true;
    }

    return isSuccessfull;
  }

  const renderRates = (ratesData) => ratesData.map((rate, index) => {
    return (
      <div key={`ap-rate-${index}`} className="ap_rate_w">
        <div className="ap_rate_operator">{rate.operator}</div>
        <Form id={rate.id} inputValue={rate.usd} currency="usd" submit={handleSubmin} />
        <Form id={rate.id} inputValue={rate.eur} currency="eur" submit={handleSubmin} />
      </div>
    );
  });

  const renderOrders = (ordersData) => ordersData.map((order, index) => {
    return (
      <Link key={`ap-order-${index}`} className="ap_order_table_line" target="_blank" to={`/ap/order?id=${order.id}`}>
        <div className="ap_orders_table_column_id">
          {order.externalId}
        </div>
        <div className="ap_orders_table_column">
          {order.originalPrice}
        </div>
        <div className="ap_orders_table_column">
          {order.currency}
        </div>
        <div className="ap_orders_table_column">
          { getOrderStatusText(order.status) }
        </div>
      </Link>
    );
  });

  return (
    <div className="content">
      <div className="container">
        {rates && (
          <div className="ap_rates_wrapper">
            <h3>Echange rates</h3>
            <div className="ap_rates_w">
              {rates && renderRates(rates)}
            </div>
          </div>
        )}
        {orders && (
          <div>
            <h3>Orders</h3>
            <div className="ap_orders_wrapper">
              <div className="ap_order_table_line_head">
                <div className="ap_orders_table_column_id ap_orders_table_head_item">
                  Id
                </div>
                <div className="ap_orders_table_column ap_orders_table_head_item">
                  Price
                </div>
                <div className="ap_orders_table_column ap_orders_table_head_item">
                  Currency
                </div>
                <div className="ap_orders_table_column ap_orders_table_head_item">
                  Status
                </div>
              </div>
              {(orders && orders.length > 0)
                ? renderOrders(orders)
                : <div className="ap_text">Нет новых заказов</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPanel;
