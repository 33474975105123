import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserMenu from './UserMenu';

function UserMenuFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <UserMenu location={location} navigate={navigate} />
  );
}

export default UserMenuFunc;
