/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compareAsc } from 'date-fns';
import './css/ToursResult.css';
import { Bars, ProgressBar } from 'react-loader-spinner';
import { initTourSearch, getSearchStatus, getToursPartial } from '../../Utils/RestUtils';
import { getCountryById, getResortsByIds } from '../../Utils/ElasticUtils';
import { CITY_OPTIONS } from '../../Utils/Constants/CitiesFrom';
import { SORT, SORT_PRICE, TOURS_NOT_FOUND, ERROR_HAPPEND } from '../../Utils/Constants/ToursResultConstants';
import starIco from '../../images/star.svg';
import goldStarIco17 from '../../images/gold_star_17.svg';
import grayStarIco17 from '../../images/gray_star_17.svg';
import filterIco from '../../images/filter.svg';
import whiteLike from '../../images/white-like.svg';
import {
  changeDestination, changeDeparturetDate, setNightsFrom, changeTabletFilterMenuVisibility,
  setNightsTo, setAdults, setKids, changeDepatureCity, changeSelectedResorts, changeCharterOnly,
} from '../../DataStore/actions/searchFormActions';
import { getKidsText } from '../../Utils/CommonUtils';
import { HOME_PAGE, TOUR_SEARCH_RESULT } from '../../Utils/Constants/GlobalConstants';
import SearchFormFunc from '../SearchForm/SearchFormFunc';
import HotelsFilter from './HotelsFilter/HotelsFilter';

const mapStateToProps = (state) => ({ ...state });

class ToursResult extends React.Component {
  constructor(props) {
    super(props);
    this.fetchDataCounter = 0;
    this.state = {
      hotels: [],
      page: 1,
      allStarsFilterSelected: true,
      starFilters: [
        { id: '404', name: '5 звезд', value: '404', checked: false },
        { id: '403', name: '4 звезды', value: '403', checked: false },
        { id: '402', name: '3 звезды', value: '402', checked: false },
        { id: '401', name: '1-2 звезды', value: '401', checked: false },
      ],
      mealFilters: [
        { id: '112', code: 'FB', desc: 'Завтрак, обед, ужин', value: '112', checked: false, count: 0 },
        { id: '113', code: 'HB ', desc: 'Завтрак, ужин', value: '113', checked: false, count: 0 },
        { id: '114', code: 'BB', desc: 'Завтрак', value: '114', checked: false, count: 0 },
        { id: '115', code: 'AI', desc: 'Всё включено', value: '115', checked: false, count: 0 },
        { id: '116', code: 'UAI', desc: 'Всё включено ультра', value: '116', checked: false, count: 0 },
        { id: '117', code: 'RO', desc: 'Без питания', value: '117', checked: false, count: 0 },
        { id: '121', code: 'FB+', desc: 'FB ультра', value: '121', checked: false, count: 0 },
        { id: '122', code: 'HB+', desc: 'HB ультра', value: '122', checked: false, count: 0 },
        { id: '129', code: 'SC', desc: 'Самообслуживание', value: '129', checked: false, count: 0 },
      ],
      appliedStarFilters: [],
      appliedMealFilters: [],
      allMealsFilterSelected: true,
      isDisabled: false,
      isSpinnerVisible: false,
      selectedHotels: [],
      hotelsFromUrl: [],
      selectedSorting: 'price',
      requestId: 0,
      isStartFilterButtonVisible: false,
      isMealFilterButtonVisible: false,
      isEmptySearch: false,
      isSearchError: false,
      currentCountry: '',
    };
  }

  componentDidMount() {
    const { location, countryFilter } = this.props;
    let payload;

    if (location.state && location.state.location === HOME_PAGE) {
      // clear state
      window.history.replaceState({}, document.title);
    } else {
      const {
        depatureCity,
        destination,
        selectedResorts,
        departureDate,
        nightsFrom,
        nightsTo,
        adults,
        changeDestination: changeDestinationAction,
        changeDeparturetDate: changeDeparturetDateAction,
        setNightsFrom: setNightsFromAction,
        setNightsTo: setNightsToAction,
        setAdults: setAdultsAction,
        setKids: setKidsAction,
        changeDepatureCity: changeDepatureCityAction,
        changeSelectedResorts: changeSelectedResortsAction,
        changeCharterOnly: changeCharterOnlyAction,
      } = this.props;

      const {
        starFilters,
        allStarsFilterSelected,
        allMealsFilterSelected,
        mealFilters,
      } = this.state;

      const queryParameters = new URLSearchParams(location.search);

      let fromPayload;
      if (queryParameters.has('from') && queryParameters.get('from') !== '') {
        const fromId = queryParameters.get('from');

        const from = CITY_OPTIONS.filter((e) => e.id == fromId);

        if (from.length > 0) {
          changeDepatureCityAction(from[0]);
          fromPayload = from[0].id;
        }
      }

      if (!fromPayload) {
        fromPayload = depatureCity.id;
      }

      let toPayload;
      if (countryFilter && countryFilter.length > 0) {
        toPayload = countryFilter;
      } else if (queryParameters.has('to') && queryParameters.get('to') !== '') {
        toPayload = queryParameters.get('to');
      }

      getCountryById(toPayload).then((countryResult) => {
        // eslint-disable-next-line no-underscore-dangle
        const countriesArray = countryResult.data.hits.hits.map((h) => h._source);

        if (countriesArray.length > 0) {
          changeDestinationAction(countriesArray[0]);
          // eslint-disable-next-line prefer-destructuring
        }
      });

      if (!toPayload) {
        toPayload = destination.id;
      }

      let cityPayload;
      if (queryParameters.has('cities') && queryParameters.get('cities') !== '') {
        const ids = queryParameters.get('cities').split(',');
        cityPayload = ids;

        if (ids.length > 0) {
          getResortsByIds(toPayload, ids).then((cityResult) => {
            // eslint-disable-next-line no-underscore-dangle
            const resorts = cityResult.data.hits.hits.map((h) => h._source);
            changeSelectedResortsAction(resorts);
          });
        }
      }

      if (!cityPayload) {
        cityPayload = selectedResorts;
      }

      let dateFromPayload;
      let dateToPayload;
      if (queryParameters.has('departureFrom') && queryParameters.get('departureFrom') !== '') {
        const dateFrom = this.formatDate(queryParameters.get('departureFrom'));

        if (dateFrom && compareAsc(dateFrom, new Date()) >= 0) {
          if (queryParameters.has('departureTo') && queryParameters.get('departureTo') !== '') {
            const dateTo = this.formatDate(queryParameters.get('departureTo'));

            if (dateTo && compareAsc(dateTo, dateFrom) >= 0) {
              changeDeparturetDateAction({ from: dateFrom, to: dateTo });
              dateFromPayload = dateFrom.toLocaleDateString('en-GB');
              dateToPayload = dateTo.toLocaleDateString('en-GB');
            }
          }
        }
      }

      if (!dateFromPayload || !dateToPayload) {
        dateFromPayload = departureDate.from.toLocaleDateString('en-GB');
        dateToPayload = departureDate.to.toLocaleDateString('en-GB');
      }

      let nightsFromPayload;
      let nightToPayload;
      if (queryParameters.has('nightsFrom') && queryParameters.get('nightsFrom') !== '') {
        let nFrom;
        let nTo;

        if (!isNaN(queryParameters.get('nightsFrom'))) {
          nFrom = parseInt(queryParameters.get('nightsFrom'), 10);

          if (nFrom > 0) {
            if (queryParameters.has('nightsTo') && queryParameters.get('nightsTo') !== '') {
              if (!isNaN(queryParameters.get('nightsTo'))) {
                nTo = parseInt(queryParameters.get('nightsTo'), 10);

                if (nFrom <= nTo) {
                  setNightsFromAction(nFrom);
                  setNightsToAction(nTo);

                  nightsFromPayload = nFrom;
                  nightToPayload = nTo;
                }
              }
            }
          }
        }
      }

      if (!nightsFromPayload) {
        nightsFromPayload = nightsFrom;
        nightToPayload = nightsTo;
      }

      let adultsPayload;
      if (queryParameters.has('adults') && queryParameters.get('adults') !== '') {
        if (!isNaN(queryParameters.get('adults'))) {
          const adultsCount = parseInt(queryParameters.get('adults'), 10);
          if (adultsCount > 0 && adultsCount <= 4) {
            setAdultsAction(adultsCount);
            adultsPayload = adultsCount;
          }
        }
      }

      if (!adultsPayload) {
        adultsPayload = adults;
      }

      const kidsPayload = [];
      if (queryParameters.has('kids') && queryParameters.get('kids') !== '') {
        const ages = queryParameters.get('kids').split(',');
        const validatedAges = [];

        ages.forEach((age, index) => {
          if (!isNaN(age)) {
            validatedAges.push({ age, text: getKidsText(parseInt(age, 10)), index });
            kidsPayload.push(age);
          }
        });

        if (validatedAges.length > 0) {
          setKidsAction(validatedAges);
        }
      }

      const starsPayload = [];
      if (queryParameters.has('stars') && queryParameters.get('stars') !== '') {
        const values = queryParameters.get('stars').split(',');

        values.forEach((value) => {
          const sFilters = starFilters.filter((e) => e.value === value);

          if (sFilters.length > 0) {
            if (allStarsFilterSelected) {
              this.setState({ allStarsFilterSelected: false });
            }

            starsPayload.push(value);
          }
        });

        this.updateStarFilterState(starsPayload, true);
      }

      const mealsPayload = [];
      if (queryParameters.has('meals') && queryParameters.get('meals') !== '') {
        const meals = queryParameters.get('meals').split(',');

        meals.forEach((value) => {
          const mealsF = mealFilters.filter((e) => e.value === value);

          if (mealsF.length > 0) {
            if (allMealsFilterSelected) {
              this.setState({ allMealsFilterSelected: false });
            }

            mealsPayload.push(value);
          }
        });

        this.updateMealsFilterState(mealsPayload, true);
      }

      let sortPayload = SORT_PRICE;
      if (queryParameters.has('sort') && queryParameters.get('sort') !== '') {
        const sortParams = queryParameters.get('sort');

        const sortF = SORT.filter((e) => e === sortParams.toLocaleLowerCase());

        if (sortF.length > 0) {
          this.setState({ selectedSorting: sortF[0] });
          // eslint-disable-next-line prefer-destructuring
          sortPayload = sortF[0];
        }
      }

      const hotelPayload = [];
      if (queryParameters.has('hotels') && queryParameters.get('hotels') !== '') {
        const hotels = queryParameters.get('hotels').split(',');

        hotels.forEach((hotel) => {
          if (!isNaN(hotel)) {
            hotelPayload.push(hotel);
          }
        });
      }

      let charterPayload;
      if (queryParameters.has('charter') && queryParameters.get('charter') !== '') {
        charterPayload = queryParameters.get('charter');

        if (charterPayload === 'true') {
          changeCharterOnlyAction(true);
          charterPayload = true;
        } else {
          changeCharterOnlyAction(false);
          charterPayload = false;
        }
      }

      payload = {
        departureCity: fromPayload,
        destinationCountry: toPayload,
        selectedResorts: cityPayload,
        departureDateFrom: dateFromPayload,
        departureDateTo: dateToPayload,
        nightsFrom: nightsFromPayload,
        nightsTo: nightToPayload,
        adults: adultsPayload,
        kids: kidsPayload,
        page: 1,
        stars: starsPayload,
        meals: mealsPayload,
        requestId: 0,
        sort: sortPayload,
        hotels: hotelPayload,
        charterOnly: charterPayload,
      };
    }

    this.fetchData(payload);
  }

  updateStarFilterState = (ids, value, stateCallback) => {
    const { starFilters } = this.state;

    const newState = starFilters.map((star) => {
      if (ids.includes(star.id)) {
        return { ...star, checked: value };
      }

      return star;
    });

    this.setState({ starFilters: newState }, stateCallback);
  };

  updateMealsFilterState = (ids, value, stateCallback) => {
    const { mealFilters } = this.state;

    const newState = mealFilters.map((meal) => {
      if (ids.includes(meal.id)) {
        return { ...meal, checked: value };
      }

      return meal;
    });

    this.setState({ mealFilters: newState }, stateCallback);
  };

  formatDate = (value) => {
    let result = null;
    const date = value.split('/');

    if (date.length === 3
        && !isNaN(date[0])
        && !isNaN(date[1])
        && !isNaN(date[2])) {
      const d = parseInt(date[0], 10);
      const m = parseInt(date[1], 10);
      const y = parseInt(date[2], 10);

      result = new Date(y, m - 1, d);
    }

    return result;
  };

  onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight) {
      window.removeEventListener('scroll', this.onScroll);
      this.fetchNextPage();
    }
  };

  renderHotelCategory = (starsCount) => {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < starsCount; i++) {
      content.push(<img alt="" key={i} className="tr_category_star" src={starIco} />);
    }
    return content;
  };

  addHotelFilter = (hotel) => {
    const { changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction } = this.props;
    const { selectedHotels } = this.state;

    const hotels = [...selectedHotels, ...hotel];

    this.renderHotelsQueryParams(hotels);

    changeTabletFilterMenuVisibilityAction(false);

    this.setState({ selectedHotels: hotels }, () => this.fetchData());
  };

  removeHotelFilter = (id) => {
    const { changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction } = this.props;
    const { selectedHotels } = this.state;

    const hotels = selectedHotels.filter((e) => e.id !== id);

    this.renderHotelsQueryParams(hotels);

    changeTabletFilterMenuVisibilityAction(false);

    this.setState({ selectedHotels: hotels }, () => this.fetchData());
  };

  getTouristsText = (tour) => {
    let result = '';

    if (tour.adultsCount === 1) {
      result = '1 взрослый, ';
    } else {
      result = `${tour.adultsCount} взрослых, `;
    }

    if (tour.kidsCount === 1) {
      result += ', 1 ребенок,';
    } else if (tour.kidsCount > 1) {
      result += `, ${tour.kidsCount} детей,`;
    }

    return result;
  };

  getDateText = (tour) => {
    let result = `с ${tour.departureDate} на`;

    if (tour.nightsCount === 1) {
      result = `${result} 1 ночь`;
    } else if (tour.nightsCount < 5) {
      result = `${result} ${tour.nightsCount} ночи`;
    } else {
      result = `${result} ${tour.nightsCount} ночей`;
    }

    return result;
  };

  handleStarFilter = (item) => {
    this.updateStarFilterState([item.id], !item.checked, this.starFilterListener);
  };

  starFilterListener = () => {
    const { starFilters } = this.state;

    if (starFilters.filter((e) => e.checked === true).length === 0) {
      this.setState({ allStarsFilterSelected: true, isStartFilterButtonVisible: true });
    } else {
      this.setState({ allStarsFilterSelected: false, isStartFilterButtonVisible: true });
    }
  };

  renderQueryParams = (param, filter) => {
    const { navigate, location } = this.props;
    const queryParameters = new URLSearchParams(location.search);

    queryParameters.delete(param);

    const queryParamsValues = [];
    filter.forEach((i) => {
      queryParamsValues.push(i.value);
    });

    if (queryParamsValues.length > 0) {
      queryParameters.append(param, queryParamsValues);
    }

    navigate(
      {
        pathname: '/search',
        search: queryParameters.toString(),
      },
    );
  };

  renderHotelsQueryParams = (hotels) => {
    const { navigate, location } = this.props;
    const queryParameters = new URLSearchParams(location.search);

    queryParameters.delete('hotels');

    if (hotels.length > 0) {
      queryParameters.append('hotels', hotels.map((e) => e.id));
    }

    navigate(
      {
        pathname: '/search',
        search: queryParameters.toString(),
      },
    );
  };

  renderHotels = (hotels) => hotels.map((item, index) => {
    const backgroundValue = `linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 45%), url("/i/p/${item.hotelId}_0_228_300_0.jpg")`;
    const backgroundImage = { background: backgroundValue, backgroundSize: 'cover' };

    let beachLine = 0;
    if (item.facilities && item.facilities[6]) {
      if (item.facilities[6] == 110) {
        beachLine = 1;
      } else if (item.facilities[6] == 111) {
        beachLine = 2;
      } else if (item.facilities[6] == 122) {
        beachLine = 3;
      }
    }

    const beachType = [];
    if (item.facilities && item.facilities[20]) {
      item.facilities[20].forEach((e) => {
        if (e == 147) {
          beachType.push('Песок мелкий');
        } else if (e == 148) {
          beachType.push('Песок крупный');
        } else if (e == 149) {
          beachType.push('Песок вулканический');
        } else if (e == 150) {
          beachType.push('Песок жёлтый');
        } else if (e == 151) {
          beachType.push('Песок белый');
        } else if (e == 152) {
          beachType.push('Песок красный');
        } else if (e == 153) {
          beachType.push('Песок серый');
        } else if (e == 154) {
          beachType.push('Песок чёрный');
        } else if (e == 155) {
          beachType.push('Галька');
        } else if (e == 156) {
          beachType.push('Песчано-ракушечный');
        } else if (e == 157) {
          beachType.push('Каменистый');
        } else if (e == 158) {
          beachType.push('Террасный');
        } else if (e == 159) {
          beachType.push('Бетонный');
        }
      });
    }

    return (
      <div key={item.hotelId} className="tour_item">
        <div className="tr_hotel_image" style={backgroundImage}>
          <Link className="tr_hotel_image_link" target="_blank" to={this.buildHotelDetailUrl(item.hotelId)}>
            <div className="tr_distance">
              <div className="tr_airport_distance_w">
                <div className="tr_airport_distance_km">{`${item.airportDistance} км`}</div>
                <div>до аэропорта</div>
              </div>
            </div>
          </Link>
        </div>
        <div className="tr_tour_info">
          <div className="tr_hotel_info_w1">
            <div className="tr_hotel_info_w2">
              <div className="tr_hotels_stars_w1">
                <div>{this.renderHotelCategory(item.hotelCategory)}</div>
                <Link target="_blank" className="tr_hotel_name" to={this.buildHotelDetailUrl(item.hotelId)}>
                  {item.hotelName}
                </Link>
                <div className="tr_location">
                  {item.city}
                  ,
                  &nbsp;
                  {item.country}
                </div>
              </div>
              {item.tripAdvisorRating > 0 && (
                <div className="tr_hotel_raiting_w1">
                  <div className="tr_hotel_raiting_w2">
                    <img src={whiteLike} alt="" />
                    <div className="tr_hotel_ta_raiting">{item.tripAdvisorRating}</div>
                  </div>
                  <div className="tr_hotel_ta_reviews_count">
                    {item.tripAdvisorReviewsCount}
                    &nbsp;
                    отзывов
                  </div>
                </div>
              )}
            </div>
            <div className="tr_hotel_description">{item.hotelDescription}</div>
          </div>
          {(item.facilities && !(!item.facilities[1] && !item.facilities[8]
            && !item.facilities[6] && beachType.length === 0)) && (
            <div className="tr_hotel_facilities_w1">
              <div className="tr_hotel_facilities_first_line">
                { (item.facilities && item.facilities[6])
                      && (
                      <div className="tr_hotel_facility facility_beachline">
                        {beachLine}
                        -ая пляжная линия
                      </div>
                      )}
                { (item.facilities && item.facilities[8]
                  && item.facilities[8].filter((e) => e === 24).length > 0)
                  && <div className="tr_hotel_facility facility_ac">кондиционер</div>}
              </div>
              <div className="tr_hotel_facilities_second_line">
                { beachType.length > 0
                    && (
                    <div className="tr_hotel_facility facility_beach_type">
                      <div>
                        {beachType.map((e, i) => <div key={`beachType-${index}-${i}`}>{e}</div>)}
                      </div>
                    </div>
                    )}
                { (item.facilities && item.facilities[1])
                      && <div className="tr_hotel_facility facility_wifi">wi-fi</div>}
              </div>
            </div>
          )}
        </div>
        <div className="tr_tour_price_info">
          <div className="tr_price_details_w">
            <div className="tr_price_details">
              <div>{this.getTouristsText(item)}</div>
              <div>{this.getDateText(item)}</div>
            </div>
          </div>
          <Link target="_blank" className="tr_price_w" to={this.buildHotelDetailUrl(item.hotelId)}>
            <div>
              <div className="tr_price_currencies_w">
                <div className="tr_price">от&nbsp;</div>
                <div>
                  <div className="tr_price">{`${item.lowestPrice} ${item.currency}`}</div>
                  {(item.bynPrice > 0) && <div className="tr_byn_price">{`${item.bynPrice} BYN`}</div>}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });

  buildHotelDetailUrl = (hotelId) => {
    const { mealFilters, allMealsFilterSelected } = this.state;
    const {
      destination, depatureCity, selectedResorts, departureDate, nightsFrom,
      nightsTo, adults, kids, charterOnly,
    } = this.props;

    const selectedMeals = allMealsFilterSelected
      ? []
      : mealFilters.filter((e) => e.checked === true).map((e) => e.id);

    const queryParameters = new URLSearchParams();

    queryParameters.append('from', depatureCity.id);
    queryParameters.append('to', destination.id);
    queryParameters.append('cities', selectedResorts.map((item) => item.id));
    queryParameters.append('departureFrom', departureDate.from ? departureDate.from.toLocaleDateString('en-GB') : '');
    queryParameters.append('departureTo', departureDate.to ? departureDate.to.toLocaleDateString('en-GB') : departureDate.from.toLocaleDateString('en-GB'));
    queryParameters.append('nightsFrom', nightsFrom);
    queryParameters.append('nightsTo', nightsTo);
    queryParameters.append('adults', adults);
    queryParameters.append('kids', kids.map((kid) => kid.age));
    queryParameters.append('hotels', hotelId);
    queryParameters.append('meals', selectedMeals);
    queryParameters.append('charter', charterOnly);

    return `/hotel?${queryParameters.toString()}`;
  };

  renderStarsFilter = (stars) => stars.map((item) => {
    // const { isDisabled } = this.state;
    let gold = 0;
    if (item.id === '5stars') {
      gold = 5;
    } else if (item.id === '4stars') {
      gold = 4;
    } else if (item.id === '3stars') {
      gold = 3;
    } else if (item.id === '1-2stars') {
      gold = 2;
    }

    return (
      <div key={item.id}>
        <label htmlFor={item.id} className="checkbox_container filters_container">
          <input
            id={item.id}
            type="checkbox"
            value={item.value}
            checked={item.checked}
            onChange={() => this.handleStarFilter(item)}
            // disabled={isDisabled}
          />
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="checkmark"></span>
          { item.id !== 'allStars'
            && (
            <div>
              {this.renderStars(gold)}
            </div>
            )}
          <div className="filter_description">
            {item.name}
          </div>
        </label>
      </div>
    );
  });

  renderStars = (gold) => {
    const content = [];
    const grey = 5 - gold;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < gold; i++) {
      content.push(<img key={`gold${i}`} alt="" className="tr_category_star" src={goldStarIco17} />);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < grey; i++) {
      content.push(<img key={`gray${i}`} alt="" className="tr_category_star" src={grayStarIco17} />);
    }

    return content;
  };

  handleAllStarsFilter = () => {
    const { allStarsFilterSelected, starFilters } = this.state;

    if (!allStarsFilterSelected) {
      this.setState({ allStarsFilterSelected: true, isStartFilterButtonVisible: true });

      this.updateStarFilterState(starFilters.map((star) => star.id), false);
    }
  };

  handleMealsFilter = (item) => {
    this.updateMealsFilterState([item.id], !item.checked, this.mealsFilterListener);
  };

  handleCharterFilter = () => {
    const { charterOnly, changeCharterOnly: changeCharterOnlyFunc,
      changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction } = this.props;

    changeTabletFilterMenuVisibilityAction(false);
    changeCharterOnlyFunc(!charterOnly);

    this.renderQueryParams('charter', [{ value: !charterOnly }]);

    this.fetchData(null, null, null, null, !charterOnly);
  };

  mealsFilterListener = () => {
    const { mealFilters } = this.state;

    if (mealFilters.filter((e) => e.checked === true).length === 0) {
      this.setState({ allMealsFilterSelected: true, isMealFilterButtonVisible: true });
    } else {
      this.setState({ allMealsFilterSelected: false, isMealFilterButtonVisible: true });
    }
  };

  handleAllMeals = () => {
    const { mealFilters, allMealsFilterSelected } = this.state;

    if (!allMealsFilterSelected) {
      this.setState({ allMealsFilterSelected: true, isMealFilterButtonVisible: true });

      this.updateMealsFilterState(mealFilters.map((meal) => meal.id), false);
    }
  };

  renderMealFilters = (meals) => meals.map((item) => (
    <div key={item.id}>
      <label htmlFor={item.id} className="checkbox_container filters_container">
        <input
          id={item.id}
          type="checkbox"
          checked={item.checked}
          onChange={() => this.handleMealsFilter(item)}
        />
        <span className="checkmark" />
        <div className="filter_description">
          <span className="tr_meals_code">{item.code}</span>
          {item.desc}
        </div>
      </label>
    </div>
  ));

  handleSort = (e) => {
    const { selectedSorting } = this.state;
    const targetSelecteSorting = e.target.value;

    if (selectedSorting !== targetSelecteSorting) {
      this.setState({ selectedSorting: targetSelecteSorting });

      const { navigate, location } = this.props;
      const queryParameters = new URLSearchParams(location.search);

      queryParameters.delete('sort');
      queryParameters.append('sort', targetSelecteSorting);

      navigate(
        {
          pathname: '/search',
          search: queryParameters.toString(),
        },
      );

      this.fetchData(null, targetSelecteSorting);
    }
  };

  filterStarData = () => {
    const { starFilters, allStarsFilterSelected } = this.state;
    const { changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction } = this.props;

    this.renderQueryParams('stars', starFilters.filter((e) => e.checked === true));

    changeTabletFilterMenuVisibilityAction(false);

    const selectedStars = allStarsFilterSelected
      ? []
      : starFilters.filter((e) => e.checked === true).map((item) => item.value);

    this.setState({ isStartFilterButtonVisible: false, appliedStarFilters: selectedStars });

    this.fetchData(null, null, selectedStars);
  };

  filterMealData = () => {
    const { mealFilters, allMealsFilterSelected } = this.state;
    const { changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction } = this.props;

    this.renderQueryParams('meals', mealFilters.filter((e) => e.checked === true));

    changeTabletFilterMenuVisibilityAction(false);

    const selectedMeals = allMealsFilterSelected
      ? []
      : mealFilters.filter((e) => e.checked === true).map((e) => e.id);

    this.setState({ isMealFilterButtonVisible: false, appliedMealFilters: selectedMeals });

    this.fetchData(null, null, null, selectedMeals);
  };

  handleFilteAppearncerButton = () => {
    const { changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction,
      tabletFilterMenuVisibility } = this.props;

    changeTabletFilterMenuVisibilityAction(!tabletFilterMenuVisibility);
  };

  fetchData = (payload, targetSorting, selectedStars, selectedMeals, charterOnly) => {
    this.fetchDataCounter++;
    this.fetchDataLazy(
      payload,
      targetSorting,
      this.fetchDataCounter,
      selectedStars,
      selectedMeals,
      charterOnly,
    );
  };

  async fetchDataLazy(
    payload,
    targetSorting,
    fetchDataCounter,
    appliedStars,
    appliedMeals,
    charter,
  ) {
    const localFetchDataCounter = fetchDataCounter;

    this.setState({
      isDisabled: true,
      isSpinnerVisible: true,
      isEmptySearch: false,
      isSearchError: false });

    const { selectedSorting } = this.state;

    let finalPayload;

    if (payload) {
      finalPayload = payload;
    } else {
      const {
        destination, depatureCity, selectedResorts, departureDate, nightsFrom,
        nightsTo, adults, kids, charterOnly,
      } = this.props;

      const {
        starFilters, mealFilters, allStarsFilterSelected,
        allMealsFilterSelected, selectedHotels, currentCountry,
        appliedMealFilters, appliedStarFilters,
      } = this.state;

      let selectedStars = [];
      let selectedMeals = [];
      let filterHotels = [];

      if (currentCountry.length == 0 || currentCountry === destination.id) {
        this.setState({ currentCountry: destination.id });

        if (appliedStars) {
          selectedStars = appliedStars;
        } else {
          selectedStars = appliedStarFilters;
        }

        if (appliedMeals) {
          selectedMeals = appliedMeals;
        } else {
          selectedMeals = appliedMealFilters;
        }

        filterHotels = selectedHotels
          ? selectedHotels.map((e) => e.id)
          : [];
      } else if (currentCountry !== destination.id) {
        this.setState({
          currentCountry: destination.id, selectedHotels: [],
        });

        if (!allStarsFilterSelected) {
          this.setState({ allStarsFilterSelected: true });

          this.updateStarFilterState(starFilters.map((star) => star.id), false);
          this.setState({ isStartFilterButtonVisible: false });
        }

        if (!allMealsFilterSelected) {
          this.setState({ allMealsFilterSelected: true });

          this.updateMealsFilterState(mealFilters.map((meal) => meal.id), false);
          this.setState({ isMealFilterButtonVisible: false });
        }
      }

      finalPayload = {
        departureCity: depatureCity.id,
        destinationCountry: destination.id,
        selectedResorts: selectedResorts.map((item) => item.id),
        departureDateFrom: departureDate.from.toLocaleDateString('en-GB'),
        departureDateTo: departureDate.to ? departureDate.to.toLocaleDateString('en-GB') : departureDate.from.toLocaleDateString('en-GB'),
        nightsFrom,
        nightsTo,
        adults,
        kids: kids.map((kid) => kid.age),
        page: 1,
        stars: selectedStars,
        meals: selectedMeals,
        requestId: '',
        sort: selectedSorting,
        hotels: filterHotels,
        // eslint-disable-next-line no-unneeded-ternary
        charterOnly: charter != null ? charter : charterOnly,
      };
    }

    if (targetSorting) {
      finalPayload.sort = targetSorting;
    }

    const requestIdResponse = await initTourSearch(finalPayload)
      .catch(() => {
        this.setState({ isSearchError: true, isSpinnerVisible: false, isDisabled: false });
      });

    if (requestIdResponse && requestIdResponse.data) {
      const requestId = requestIdResponse.data;
      this.setState({ requestId });

      finalPayload.requestId = requestId;

      let processedCount = 0;
      let resultProcessedCount = 0;
      let operatorsCount = 100;
      let isEmptyResult = true;

      let isErr = false;
      let counter = 0;

      while (processedCount !== operatorsCount && localFetchDataCounter === this.fetchDataCounter) {
        counter++;
        if (counter <= 5) {
          await this.timeout(1000);
        } else {
          await this.timeout(2000);
        }

        const statusRespoinse = await getSearchStatus(requestId).catch(() => {
          if (counter >= 5) {
            isErr = true;
          }
        });

        if (isErr) {
          this.setState({ isSearchError: true, isSpinnerVisible: false, isDisabled: false });
          break;
        }

        if (statusRespoinse && statusRespoinse.data) {
          operatorsCount = statusRespoinse.data.filter((e) => e.error === false).length;
          resultProcessedCount = statusRespoinse.data
            .filter((e) => e.processed === true && e.error === false).length;

          if (resultProcessedCount > processedCount) {
            const toursResult = await getToursPartial(finalPayload);

            if (toursResult && toursResult.data
                && localFetchDataCounter === this.fetchDataCounter) {
              if (toursResult.data.hotels.length > 0) {
                this.setState({
                  hotels: toursResult.data.hotels,
                });

                isEmptyResult = false;
                this.setState({ isSpinnerVisible: false });
              }
            }

            processedCount = resultProcessedCount;
          }
        }
      }

      if (localFetchDataCounter === this.fetchDataCounter) {
        if (isEmptyResult && !isErr) {
          this.setState({
            hotels: [],
            isEmptySearch: true,
            isSpinnerVisible: false,
          });
        }

        this.setState({ isDisabled: false });
        window.addEventListener('scroll', this.onScroll);
      }
    }
  }

  async timeout(delay) {
    return new Promise((res) => { setTimeout(res, delay); });
  }

  fetchNextPage() {
    const {
      destination, depatureCity, selectedResorts, departureDate, nightsFrom,
      nightsTo, adults, kids, charterOnly,
    } = this.props;

    const {
      starFilters, mealFilters, allStarsFilterSelected,
      allMealsFilterSelected, selectedHotels, selectedSorting,
      page, requestId, hotels,
    } = this.state;

    const selectedStars = allStarsFilterSelected
      ? []
      : starFilters.filter((e) => e.checked === true).map((item) => item.value);

    const selectedMeals = allMealsFilterSelected
      ? []
      : mealFilters.filter((e) => e.checked === true).map((e) => e.id);

    const filterHotels = selectedHotels
      ? selectedHotels.map((e) => e.id)
      : [];

    const payload = {
      departureCity: depatureCity.id,
      destinationCountry: destination.id,
      selectedResorts: selectedResorts.map((item) => item.id),
      departureDateFrom: departureDate.from.toLocaleDateString('en-GB'),
      departureDateTo: departureDate.to ? departureDate.to.toLocaleDateString('en-GB') : departureDate.from.toLocaleDateString('en-GB'),
      nightsFrom,
      nightsTo,
      adults,
      kids: kids.map((kid) => kid.age),
      page: page + 1,
      stars: selectedStars,
      meals: selectedMeals,
      requestId,
      sort: selectedSorting,
      hotels: filterHotels,
      charterOnly,
    };

    getToursPartial(payload).then((toursResult) => {
      if (toursResult.data.hotels && toursResult.data.hotels.length > 0) {
        this.setState({
          hotels: [...hotels, ...toursResult.data.hotels],
        });

        window.addEventListener('scroll', this.onScroll);
      }
    });

    this.setState({ page: page + 1 });
  }

  render() {
    const {
      hotels, starFilters, allStarsFilterSelected, allMealsFilterSelected,
      mealFilters, isDisabled, selectedHotels, hotelsFromUrl, selectedSorting,
      isStartFilterButtonVisible, isMealFilterButtonVisible, isEmptySearch,
      isSpinnerVisible, isSearchError,
    } = this.state;

    const { location, tabletFilterMenuVisibility, charterOnly, text } = this.props;
    return (
      <div className="content">
        <div className="tr_intro">
          <div className="container">
            <div className="intro__inner">
              <SearchFormFunc
                page={TOUR_SEARCH_RESULT}
                search={() => this.fetchData()}
              />
            </div>
          </div>
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="tr_intro__wave"></div>
        </div>
        <div className="container">
          <div className="tr_topline_wrapper">
            {/* eslint-disable-next-line react/self-closing-comp */}
            <div className="tr_topline_left"></div>
            <div className="tr_topline_right">
              <div className="hotel_sort">
                <span className="hotel_sort_title">Сортировать:</span>
                <button
                  type="button"
                  className={selectedSorting === 'popularity' ? 'sort_option_selected sort_option' : 'sort_option'}
                  value="popularity"
                  onClick={(e) => this.handleSort(e)}
                >
                  по популярности
                </button>
                <button
                  type="button"
                  className={selectedSorting === 'price' ? 'sort_option_selected sort_option' : 'sort_option'}
                  value="price"
                  onClick={(e) => this.handleSort(e)}
                >
                  от дешевых к дорогим
                </button>
              </div>
              {(!isSpinnerVisible && isDisabled) && (
                <div className="search_progress_bar">
                  <div className="hd_look_for_prices">Ищем лучшие цены...</div>
                  <ProgressBar
                    visible
                    height="40"
                    width="40"
                    barColor="#00BFFF"
                    borderColor="#00BFFF"
                    ariaLabel="progress-bar-loading"
                  />
                </div>
              )}
            </div>
          </div>
          <button type="button" onClick={this.handleFilteAppearncerButton} className="tr_tablet_filter_buttom">
            <div className="tr_tablet_filter_buttom_text_w">
              <div className="tr_tablet_filter_buttom_text">Фильтры</div>
              <img alt="" src={filterIco} />
            </div>
          </button>
          <div className="tr_wrapper">
            {/* Filters bar */}
            <div className={tabletFilterMenuVisibility
              ? 'tr_left_bar_wrapper text_16_400'
              : 'tr_left_bar_wrapper text_16_400 tablet_menu_invisible'}
            >
              <div className="tr_left_bar">
                <HotelsFilter
                  addHotelFilter={this.addHotelFilter}
                  removeHotelFilter={this.removeHotelFilter}
                  selectedHotels={selectedHotels}
                  hotelsFromUrl={hotelsFromUrl}
                  location={location}
                />
                <div className="filter_item">
                  <div className="hotel_class_title">Рейс</div>
                  <div key="charterOnly">
                    <label htmlFor="charterOnly" className="checkbox_container filters_container">
                      <input
                        id="charterOnly"
                        type="checkbox"
                        onChange={this.handleCharterFilter}
                        checked={charterOnly}
                      />
                      {/* eslint-disable-next-line react/self-closing-comp */}
                      <span className="checkmark"></span>
                      <div className="filter_description">Только чартер</div>
                    </label>
                  </div>
                </div>
                <div className="filter_item">
                  <div className="hotel_class_title">Класс отеля</div>
                  <div key="allStars">
                    <label htmlFor="allStars" className="checkbox_container filters_container">
                      <input
                        id="allStars"
                        type="checkbox"
                        onChange={() => this.handleAllStarsFilter()}
                        checked={allStarsFilterSelected}
                      />
                      {/* eslint-disable-next-line react/self-closing-comp */}
                      <span className="checkmark"></span>
                      <div className="filter_description">Любой</div>
                    </label>
                  </div>
                  {this.renderStarsFilter(starFilters)}
                  <button
                    type="button"
                    className={isStartFilterButtonVisible ? 'btn__blue tr_filter_button' : 'btn__blue tr_filter_button tr_invisible'}
                    onClick={this.filterStarData}
                  >
                    Показать
                  </button>
                </div>
                <div className="filter_item">
                  <div className="hotel_class_title">Питание</div>
                  <label htmlFor="anyFood" className="checkbox_container filters_container">
                    <input
                      id="anyFood"
                      type="checkbox"
                      onChange={this.handleAllMeals}
                      checked={allMealsFilterSelected}
                    />
                    <span className="checkmark" />
                    <div className="filter_description">
                      Любое
                    </div>
                  </label>
                  {this.renderMealFilters(mealFilters)}
                  <button
                    type="button"
                    className={isMealFilterButtonVisible ? 'btn__blue tr_filter_button' : 'btn__blue tr_filter_button tr_invisible'}
                    onClick={this.filterMealData}
                  >
                    Показать
                  </button>
                </div>
              </div>
            </div>
            <div className="tr_right_bar">
              <div className={isSpinnerVisible ? 'spinner_wrapper' : 'spinner_wrapper tr_invisible'}>
                <div className="spinner_container">
                  <Bars
                    height="40"
                    width="40"
                    color="#00BFFF"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isSpinnerVisible}
                  />
                </div>
              </div>
              {isEmptySearch && (
                <div className="tr_not_found">{TOURS_NOT_FOUND}</div>
              )}
              {isSearchError && (
                <div className="tr_not_found">{ERROR_HAPPEND}</div>
              )}
              {this.renderHotels(hotels)}
            </div>
          </div>
        </div>
        <div className="container">{text}</div>
      </div>
    );
  }
}

ToursResult.propTypes = {
  adults: PropTypes.number.isRequired,
  kids: PropTypes.instanceOf(Array).isRequired,
  setKids: PropTypes.func.isRequired,
  nightsFrom: PropTypes.number.isRequired,
  nightsTo: PropTypes.number.isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  departureDate: PropTypes.instanceOf(Object).isRequired,
  depatureCity: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  changeDeparturetDate: PropTypes.func.isRequired,
  setNightsFrom: PropTypes.func.isRequired,
  setNightsTo: PropTypes.func.isRequired,
  setAdults: PropTypes.func.isRequired,
  changeDestination: PropTypes.func.isRequired,
  changeDepatureCity: PropTypes.func.isRequired,
  changeSelectedResorts: PropTypes.func.isRequired,
  changeTabletFilterMenuVisibility: PropTypes.func.isRequired,
  navigate: PropTypes.instanceOf(Object).isRequired,
  tabletFilterMenuVisibility: PropTypes.bool.isRequired,
  charterOnly: PropTypes.bool.isRequired,
  changeCharterOnly: PropTypes.func.isRequired,
  countryFilter: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object,
};

ToursResult.defaultProps = {
  countryFilter: '',
  text: '',
};

export default connect(mapStateToProps, {
  changeDestination,
  changeDeparturetDate,
  setNightsFrom,
  setNightsTo,
  setAdults,
  setKids,
  changeDepatureCity,
  changeSelectedResorts,
  changeTabletFilterMenuVisibility,
  changeCharterOnly,
})(ToursResult);
