import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './css/NightsPopover.css';
import { ReactComponent as PlusIco } from '../../../images/search-form-plus.svg';
import { ReactComponent as MinusIco } from '../../../images/search-form-minus.svg';
import {
  SELECTED_DAYS, SELECTED_NA,
} from '../../../Utils/Constants/HomePageConstants';
import {
  increaseNightsFrom, decreaseNightsFrom, increaseNightsTo, decreaseNightsTo,
} from '../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  increaseNightsFromAction: () => dispatch(increaseNightsFrom),
  decreaseNightsFromAction: () => dispatch(decreaseNightsFrom),
  increaseNightsToAction: () => dispatch(increaseNightsTo),
  decreaseNightsToAction: () => dispatch(decreaseNightsTo),
});

class NightsPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  tourPeriod = () => {
    const { nightsFrom, nightsTo } = this.props;
    let value;
    if (nightsFrom === nightsTo && nightsFrom === 1) {
      value = `${nightsFrom} ночь`;
    } else if (nightsFrom === nightsTo) {
      value = `${nightsFrom} ночей`;
    } else {
      value = `${nightsFrom} - ${nightsTo} ночей`;
    }
    return value;
  };

  render() {
    const {
      nightsFrom, increaseNightsFromAction, decreaseNightsFromAction,
      nightsTo, decreaseNightsToAction, increaseNightsToAction,
    } = this.props;
    return (
      <div className="sf__nights_popover_wrapper">
        <div className="sf__nights__selector__wrapper">
          <div className="sf__nights__selector">
            <div className="selected__days__text">от</div>
            <button type="button" onClick={decreaseNightsFromAction}>
              <MinusIco />
            </button>
            <div className="selected__days">{nightsFrom}</div>
            <button type="button" onClick={increaseNightsFromAction}>
              <PlusIco />
            </button>
          </div>
          <div className="sf__nights__selector">
            <div className="selected__days__text">до</div>
            <button className="select_button" type="button" onClick={decreaseNightsToAction}>
              <MinusIco />
            </button>
            <div className="selected__days">{nightsTo}</div>
            <button className="select_button" type="button" onClick={increaseNightsToAction}>
              <PlusIco />
            </button>
          </div>
        </div>
        <div className="sf__nights__select__result">
          {SELECTED_DAYS}
          &nbsp;
          {SELECTED_NA}
          &nbsp;
          <span className="bold_font">
            {this.tourPeriod()}
          </span>
        </div>
      </div>
    );
  }
}

NightsPopover.propTypes = {
  nightsFrom: PropTypes.number.isRequired,
  increaseNightsFromAction: PropTypes.func.isRequired,
  decreaseNightsFromAction: PropTypes.func.isRequired,
  decreaseNightsToAction: PropTypes.func.isRequired,
  increaseNightsToAction: PropTypes.func.isRequired,
  nightsTo: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NightsPopover);
