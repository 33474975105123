import axios from 'axios';
// import HttpsAgent from 'https-agent';

// const agent = new HttpsAgent({
//   rejectUnauthorized: false,
// });

const agent = '';

export function getResortsByCountryId(destinatinId) {
  const query = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [{
          match: {
            type: 'city',
          },
        },
        {
          match: {
            parentId: destinatinId,
          },
        }],
      },
    },
  };

  return axios
    .post(
      '/th/_search',
      query,
      {
        auth: {
          username: process.env.REACT_APP_ES_USER,
          password: process.env.REACT_APP_ES_PASSWORD,
        },
        httpsAgent: agent,
      },
    );
}

export function getAllCountries() {
  const query = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [{
          match: {
            type: 'country',
          },
        }],
      },
    },
  };

  return axios
    .post(
      '/th/_search',
      query,
      {
        auth: {
          username: process.env.REACT_APP_ES_USER,
          password: process.env.REACT_APP_ES_PASSWORD,
        },
        httpsAgent: agent,
      },
    );
}

export function getCountryById(countryId) {
  const query = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [{
          match: {
            type: 'country',
          },
        },
        {
          match: {
            id: countryId,
          },
        }],
      },
    },
  };

  return axios
    .post(
      '/th/_search',
      query,
      {
        auth: {
          username: process.env.REACT_APP_ES_USER,
          password: process.env.REACT_APP_ES_PASSWORD,
        },
        httpsAgent: agent,
      },
    );
}

export function getResortsByIds(countryId, ids) {
  const query = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [
          { match: { type: 'city' } },
          { match: { parentId: countryId } },
          {
            bool: {
              should: [],
            },
          },
        ],
      },
    },
  };

  ids.forEach((id) => {
    const match = { match: { id } };
    query.query.bool.must[2].bool.should.push(match);
  });

  return axios
    .post(
      '/th/_search',
      query,
      {
        auth: {
          username: process.env.REACT_APP_ES_USER,
          password: process.env.REACT_APP_ES_PASSWORD,
        },
        httpsAgent: agent,
      },
    );
}

export default getResortsByCountryId;
