import {
  BEFORE_YEAR, ONE_YEAR, TWO_YEARS, THREE_YEARS, FOUR_YEARS, FIVE_YEARS, SIX_YEARS,
  SEVEN_YEARS, EIGHT_YEARS, NINE_YEARS, TEN_YEARS, ELEVEN_YEARS, TWELVE_YEARS, THIRTEEN_YEARS,
  FOURTEEN_YEARS, FIFTEEN_YEARS, SIXTEEN_YEARS, SEVENTEEN_YEARS,
} from './Constants/HomePageConstants';
import {
  ORDER_WAIT_PAYMENT_STATUS, ORDER_WAIT_PAIMENT, ORDER_WAIT_APPROVAL_STATUS, ORDER_WAIT_APPROVAL,
  ORDER_APPROVED_STATUS, ORDER_APPROVED, ORDER_DECLINED_STATUS, ORDER_DECLINED,
} from './Constants/GlobalConstants';

export const getKidsText = (age) => {
  let result = '';

  switch (age) {
    case 0:
      result = BEFORE_YEAR;
      break;
    case 1:
      result = ONE_YEAR;
      break;
    case 2:
      result = TWO_YEARS;
      break;
    case 3:
      result = THREE_YEARS;
      break;
    case 4:
      result = FOUR_YEARS;
      break;
    case 5:
      result = FIVE_YEARS;
      break;
    case 6:
      result = SIX_YEARS;
      break;
    case 7:
      result = SEVEN_YEARS;
      break;
    case 8:
      result = EIGHT_YEARS;
      break;
    case 9:
      result = NINE_YEARS;
      break;
    case 10:
      result = TEN_YEARS;
      break;
    case 11:
      result = ELEVEN_YEARS;
      break;
    case 12:
      result = TWELVE_YEARS;
      break;
    case 13:
      result = THIRTEEN_YEARS;
      break;
    case 14:
      result = FOURTEEN_YEARS;
      break;
    case 15:
      result = FIFTEEN_YEARS;
      break;
    case 16:
      result = SIXTEEN_YEARS;
      break;
    case 17:
      result = SEVENTEEN_YEARS;
      break;
    default:
      break;
  }

  return result;
};

export const getTouristsText = (adults, kids) => {
  let result = '';

  if (adults) {
    // eslint-disable-next-line eqeqeq
    if (adults == 1) {
      result = '1 взрослый';
    } else {
      result = `${adults} взрослых`;
    }
  }

  if (kids) {
    // eslint-disable-next-line eqeqeq
    if (kids == 1) {
      result += ', 1 ребенок';
    } else if (kids > 1) {
      result += `, ${kids} детей`;
    }
  }

  return result;
};

export const getDateAndNightsText = (priceData) => {
  let result = `с ${priceData.departureDate} на`;

  if (priceData.nightsCount === 1) {
    result = `${result} 1 ночь`;
  } else if (priceData.nightsCount < 5) {
    result = `${result} ${priceData.nightsCount} ночи`;
  } else {
    result = `${result} ${priceData.nightsCount} ночей`;
  }

  return result;
};

export const getOrderStatusText = (status) => {
  let result = '';

  if (status === ORDER_WAIT_PAYMENT_STATUS) {
    result = ORDER_WAIT_PAIMENT;
  } else if (status === ORDER_WAIT_APPROVAL_STATUS) {
    result = ORDER_WAIT_APPROVAL;
  } else if (status === ORDER_APPROVED_STATUS) {
    result = ORDER_APPROVED;
  } else if (status === ORDER_DECLINED_STATUS) {
    result = ORDER_DECLINED;
  }

  return result;
};
