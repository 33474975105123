import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCountryById } from '../../../../Utils/ElasticUtils';
import {
  changeDestination, changeResortOptions, changeSearchResult,
  changeSearchInputValue, changeSelectedResorts, changeCharterOnly,
} from '../../../../DataStore/actions/searchFormActions';
import { TURKEY_COUNTRY_ID } from '../../../../Utils/Constants/GlobalConstants';
import './css/SearchResultPopup.css';

const mapStateToProps = (state) => ({ ...state });

class SearchResultPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleSelectedDestination = (selectedDestination) => {
    const {
      changeDestination: changeDestinationAction,
      changeResortOptions: changeResortOptionsAction,
      changeSearchResult: changeSearchResultAction,
      changeSearchInputValue: changeSearchInputValueAction,
      changeSelectedResorts: changeSelectedResortsAction,
      selectedResorts,
      searchResult,
      destination,
      resortOptions,
      popOverRef,
      changeCharterOnly: changeCharterOnlyFunc,
    } = this.props;

    // select country
    if (selectedDestination.type === 'country') {
      // selected country the same
      if (destination.id !== selectedDestination.id) {
        changeDestinationAction(selectedDestination);

        changeSelectedResortsAction([]);
        changeResortOptionsAction([]);

        changeCharterOnlyFunc(selectedDestination.id === TURKEY_COUNTRY_ID);
      }

      changeSearchInputValueAction(selectedDestination.name);
      changeSearchResultAction([]);

      // resort selection -> selected resort has already selected
    } else if (selectedResorts
      .filter((resort) => resort.id === selectedDestination.id).length !== 0) {
      changeSearchInputValueAction(destination.name);
      changeSearchResultAction([]);

      // resort seclectrion -> new resort
    } else {
      const parentArray = searchResult
        .filter((item) => item.id === selectedDestination.parentId);

      const parent = parentArray[0];

      // new resort selected -> parent is present in search result
      if (parent !== undefined) {
        // resort was selected from prevoiusely selected country
        if (destination.id === selectedDestination.parentId) {
          changeSelectedResortsAction(selectedResorts.concat([selectedDestination]));

          changeResortOptionsAction(resortOptions
            .filter((resort) => resort.id !== selectedDestination.id));

          changeSearchResultAction([]);
        } else { // new resort -> parent isn't in the search result
          changeDestinationAction(parent);
          changeSearchInputValueAction(parent.name);
          changeSelectedResortsAction([selectedDestination]);
          changeResortOptionsAction([]);
          changeSearchResultAction([]);

          changeCharterOnlyFunc(parent.id === TURKEY_COUNTRY_ID);
        }
      // new resort selected -> parent isn't present in search result
      } else {
        getCountryById(selectedDestination.parentId).then((result) => {
          // eslint-disable-next-line no-underscore-dangle
          const countriesArray = result.data.hits.hits.map((h) => h._source);
          const country = countriesArray[0];

          changeDestinationAction(country);
          changeSearchInputValueAction(country.name);
          changeSelectedResortsAction([selectedDestination]);
          changeResortOptionsAction([]);
          changeSearchResultAction([]);

          changeCharterOnlyFunc(country.id === TURKEY_COUNTRY_ID);
        });
      }
    }

    popOverRef.current.scrollTo(0, 0);
  };

  renderContent = (suggestions) => suggestions.map((item) => {
    const reference = createRef();
    const { destination, selectedResorts } = this.props;

    let isChecked = false;
    if (destination.id === item.id
      || selectedResorts.filter((resort) => resort.id === item.id).length > 0) {
      isChecked = true;
    }

    return (
      <div key={item.id}>
        <label htmlFor={item.id} className="checkbox_container">
          <input
            id={item.id}
            type="checkbox"
            value={item}
            onChange={() => this.handleSelectedDestination(item)}
            ref={reference}
            defaultChecked={isChecked}
            className="resort_option"
          />
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="checkmark"></span>
          {item.name}
          &nbsp;
          <span className="suggestion_country">{item.parentName}</span>
        </label>
      </div>
    );
  });

  render() {
    const { searchResult } = this.props;
    const countriesResult = searchResult.filter((item) => item.type === 'country');
    const resortsResult = searchResult.filter((item) => item.type === 'city');

    return (
      <div className="search_res_wrapper">
        <div className="resort_section">
          {this.renderContent(countriesResult)}
        </div>
        { resortsResult.length > 0 && <div className={`suggestion_section ${countriesResult.length > 0 ? 'suggestion_section_top_line' : ''}`}>Курорты</div>}
        <div className="resort_section">
          {this.renderContent(resortsResult)}
        </div>
      </div>
    );
  }
}

SearchResultPopup.propTypes = {
  searchResult: PropTypes.instanceOf(Array).isRequired,
  changeDestination: PropTypes.func.isRequired,
  changeResortOptions: PropTypes.func.isRequired,
  changeSearchResult: PropTypes.func.isRequired,
  changeSearchInputValue: PropTypes.func.isRequired,
  changeSelectedResorts: PropTypes.func.isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  resortOptions: PropTypes.instanceOf(Array).isRequired,
  popOverRef: PropTypes.instanceOf(Object).isRequired,
  changeCharterOnly: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changeDestination,
  changeResortOptions,
  changeSearchResult,
  changeSearchInputValue,
  changeSelectedResorts,
  changeCharterOnly,
})(SearchResultPopup);
