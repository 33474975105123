import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeDepatureCity } from '../../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

function shouldRenderSuggestions() {
  return true;
}

function renderSuggestion(suggestion) {
  return (
    suggestion.name
  );
}

class RenderAutosuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  getSuggestions = (value) => {
    const { options } = this.props;
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp(`^${escapedValue}`, 'i');

    return options.filter((language) => regex.test(language.name));
  };

  onChange = (event, { newValue }) => {
    const { changeDepatureCity: changeDepatureCityFunc } = this.props;
    if (newValue.name) {
      changeDepatureCityFunc(newValue);
    } else {
      this.setState({ value: newValue });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Введите название',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="cityFrom">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          ref={this.storeInputReference}
        />
      </div>
    );
  }
}

RenderAutosuggest.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  changeDepatureCity: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { changeDepatureCity })(RenderAutosuggest);
