/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import Shield from '../../images/shield.png';
import Human from '../../images/human.png';
import Euro from '../../images/euro.png';
import { ABOUT_TITLE, ABOUT_DESCRIPTION } from '../../Utils/Constants/SEO';
import './css/About.css';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{ABOUT_TITLE}</title>
          <meta name="description" content={ABOUT_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/about" />
          <meta property="og:title" content={ABOUT_TITLE} />
          <meta property="og:description" content={ABOUT_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/thr_logo.png" />

          <link rel="canonical" href="https://tourhunter.by/about" />
        </Helmet>
        <div className="ab_intro">
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="ab_intro__wave"></div>
        </div>
        <div className="container">
          <h3 className="blue__title">Почему tourhunter.by?</h3>
          <div className="ab_first_section_w">
            <div className="ab_first_section_item">
              Добро пожаловать в онлайн-магазин путешествий Tourhunter.by! 
              С нами подготовка к отпуску — сплошное удовольствие! 
              Мы собрали лучшие предложения отдыха на любой бюджет от ведущих туроператоров. 
            </div>
            <div className="ab_first_section_item">
              Вам больше не нужно обзванивать десятки турагентств 
              - мы предлагаем вам ознакомится с рейтингами отелей и отзывами других 
              путешественников и сделать информативный выбор!
            </div>
          </div>
        </div>
        <div className="ab_seconnd_section_w">
          <div className="container ab_advenages_w">
            <div className="ab_advenages_item_w">
              <img className="ab_euro" alt="" src={Euro} />
              <div className="ab_advenages_item_text_w">
                <div className="ab_advenages_item_title">Выгодно</div>
                <div className="ab_advenages_item_text">
                  У нас лучшие цены! А также мы любим радовать наших клиентов
                  специальными акциями и скидками.
                </div>
              </div>
            </div>
            <div className="ab_advenages_item_w">
              <img className="ab_shield" alt="" src={Shield} />
              <div className="ab_advenages_item_text_w">
                <div className="ab_advenages_item_title">Надежно</div>
                <div className="ab_advenages_item_text">
                  Оплачивать тур онлайн на сайте Tourhunter.by гораздо безопаснее, 
                  чем везти тысячи рублей наличными через весь город. 
                  Все платежи по карте проходят через защищенные порталы банка. 
                  После оплаты деньги не списываются с вашей карты сразу, 
                  а замораживаются до подтверждения бронирования туроператором.
                </div>
              </div>
            </div>
            <div className="ab_advenages_item_w">
              <img className="ab_human" alt="" src={Human} />
              <div className="ab_advenages_item_text_w">
                <div className="ab_advenages_item_title">Удобно</div>
                <div className="ab_advenages_item_text">
                  Покупка онлайн сэкономит ваши самые ценные ресурсы время и деньги: 
                  так как вы можете забронировать тур из любого места в любое время без 
                  посещения агентства и звонка по телефону.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <h3 className="blue__title"> Как купить тур?</h3>
          <div className="ab_how_to_buy_w">
            <div className="ab_how_to_buy_item buy_item_1">
              <div className="ab_how_title">Выбор тура</div>
              <div className="ab_how_text">Воспользуйтесь «умным» поиском, настроив параметры тура: даты, состав туристов, питание, цена и т.д.</div>
            </div>
            <div className="ab_how_to_buy_item buy_item_2">
              <div className="ab_how_title">Оформление online</div>
              <div className="ab_how_text">Никуда не нужно ездить — все оформляется через сайт. Вопреки различным стереотипам, заполнение документов и оплата через интернет — абсолютно безопасны (например, мы проводим оплату через защищенные порталы Альфа-Банка).</div>
            </div>
            <div className="ab_how_to_buy_item buy_item_3">
              <div className="ab_how_title">Виза и документы</div>
              <div className="ab_how_text">Если вы едете в страну, где нужна виза — расскажем, какие документы необходимы  и поможем с оформлением.</div>
            </div>
            <div className="ab_how_to_buy_item buy_item_4">
              <div className="ab_how_title">Процесс оформления</div>
              <div className="ab_how_text">Договор оферты заключается на сайте онлайн. Когда вы оплачиваете тур — деньги сразу не снимаются, они блокируются на карте и списываются только в случае окончательного подтверждения тура принимающей стороной. Затем в течение суток мы пришлем вам договор на email или вышлем почтой.</div>
            </div>
            <div className="ab_how_to_buy_item buy_item_5">
              <div className="ab_how_title">Все необходимое — на почту</div>
              <div className="ab_how_text">За 2 дня до поездки вы получите все необходимые документы на почту (электронные билеты, ваучер, медицинскую страховку и памятку по стране). Их нужно распечатать и взять с собой. Накануне вылета вам позвонит оператор и напомнит о времени рейса или сообщит, что время вылета изменилось.</div>
            </div>
            <div className="ab_how_to_buy_item buy_item_6">
              <div className="ab_how_title">Это еще не все!</div>
              <div className="ab_how_text">В любой ситуации вы можете позвонить нашему менеджеру по туризму, и он поможет решить все вопросы. Вы можете рассчитывать на нашу поддержку с самого начала – от оформления заявки на тур – и до возвращения из отпуска.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
