/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getResortsByCountryId, getAllCountries } from '../../../../Utils/ElasticUtils';
import {
  POPULAR, OTHER, ALL_RESORTS, SELECTED,
} from '../../../../Utils/Constants/SearchFormConstants';
import { TURKEY_COUNTRY_ID } from '../../../../Utils/Constants/GlobalConstants';
import {
  changeSelectedResorts, changeResortOptions, changeDestination, changeSearchInputValue,
  changeCharterOnly,
} from '../../../../DataStore/actions/searchFormActions';
import './css/ResortsPopup.css';

const mapStateToProps = (state) => ({ ...state });

class ResortsPopup extends React.Component {
  constructor(props) {
    super(props);

    const { selectedResorts } = this.props;
    this.state = {
      isAllResortsChecked: selectedResorts.length === 0,
      countryOptions: [],
      allCountries: [],
    };
  }

  componentDidMount() {
    const {
      destination, resortOptions,
    } = this.props;

    getAllCountries().then((result) => {
      const { hits } = result.data;
      const allCountries = hits.hits.map((h) => h._source);
      const countryOptions = allCountries.filter((e) => e.id !== destination.id);

      this.setState({ allCountries, countryOptions });
    });

    if (resortOptions.length === 0) {
      this.getResortsOptions(destination.id);
    }
  }

  getResortsOptions = (id) => {
    const {
      changeResortOptions: changeResortOptionsFunc, selectedResorts,
    } = this.props;

    getResortsByCountryId(id)
      .then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const { hits } = res.data;
        if (hits) {
          const results = hits.hits.map((h) => h._source);

          let options = results;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < selectedResorts.length; i++) {
            const optId = selectedResorts[i].id;
            options = options.filter((e) => e.id !== optId);
          }

          changeResortOptionsFunc(options);
        }
      });
  };

  renderSelectedCities = (selectedCities) => selectedCities.map((item, index) => {
    const reference = createRef();
    // this.allCheckboxesReferences.add(reference);
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`res-${index}`}>
        <label htmlFor={item.id} className="checkbox_container">
          <input
            id={item.id}
            type="checkbox"
            value={item}
            onChange={() => this.uncheck(item, reference)}
            ref={reference}
            className="resort_option"
            defaultChecked
          />
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="checkmark"></span>
          {item.name}
          &nbsp;
          <span className="suggestion_country">{item.parentName}</span>
        </label>
      </div>
    );
  });

  renderCities = (citySuggestions) => citySuggestions.map((item, index) => {
    const reference = createRef();
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`city-${index}`}>
        <label htmlFor={item.id} className="checkbox_container">
          <input
            id={item.id}
            type="checkbox"
            value={item}
            onChange={() => this.selectCheckbox(item, reference)}
            ref={reference}
            className="resort_option"
          />
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="checkmark"></span>
          {item.name}
          &nbsp;
          <span className="suggestion_country">{item.parentName}</span>
        </label>
      </div>
    );
  });

  renderCountries = (countries) => countries.map((item, index) => {
    const reference = createRef();
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`city-${index}`}>
        <label htmlFor={item.id} className="checkbox_container">
          <input
            id={item.id}
            type="checkbox"
            value={item}
            onChange={() => this.selectCountry(item, reference)}
            ref={reference}
            className="resort_option"
          />
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="checkmark"></span>
          {item.name}
        </label>
      </div>
    );
  });

  selectCountry = (item, reference) => {
    const {
      changeDestination: changeDestinationAction,
      changeSearchInputValue: changeSearchInputValueAction,
      changeSelectedResorts: changeSelectedResortsAction,
      changeCharterOnly: changeCharterOnlyFunc,
    } = this.props;

    const { allCountries } = this.state;

    reference.current.checked = false;

    const countryOptions = allCountries.filter((e) => e.id !== item.id);
    this.setState({ countryOptions, isAllResortsChecked: true });

    changeDestinationAction(item);
    changeSearchInputValueAction(item.name);
    changeSelectedResortsAction([]);

    this.getResortsOptions(item.id);

    changeCharterOnlyFunc(item.id === TURKEY_COUNTRY_ID);
  };

  selectCheckbox = (item, reference) => {
    const {
      changeSelectedResorts: changeSelectedResortsFunc,
      changeResortOptions: changeResortOptionsFunc, resortOptions, selectedResorts,
    } = this.props;

    this.setState({ isAllResortsChecked: false });
    const resorts = resortOptions.filter((e) => e.id !== item.id);
    changeResortOptionsFunc(resorts);
    reference.current.checked = false;
    changeSelectedResortsFunc(selectedResorts.concat([item]));
  };

  uncheck = (item, reference) => {
    const {
      changeSelectedResorts: changeSelectedResortsFunc,
      changeResortOptions: changeResortOptionsFunc, resortOptions, selectedResorts,
    } = this.props;

    changeResortOptionsFunc(resortOptions.concat([item]));

    reference.current.checked = true;
    const updatedSelection = selectedResorts.filter((e) => e.id !== item.id);

    if (updatedSelection.length === 0) {
      this.setState({ isAllResortsChecked: true });
    }

    changeSelectedResortsFunc(updatedSelection);
  };

  toggleAllResortsCheckbox = () => {
    const {
      changeSelectedResorts: changeSelectedResortsFunc,
      changeResortOptions: changeResortOptionsFunc, resortOptions, selectedResorts,
    } = this.props;

    this.setState({ isAllResortsChecked: true });

    changeResortOptionsFunc(resortOptions.concat(selectedResorts));
    changeSelectedResortsFunc([]);
  };

  compareName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  render() {
    const {
      destination, selectedResorts, resortOptions,
    } = this.props;

    const { countryOptions } = this.state;

    let filteredResortOptions;
    if (selectedResorts.length === 1) {
      filteredResortOptions = resortOptions.filter((item) => item.id !== selectedResorts[0].id);
    } else {
      filteredResortOptions = resortOptions;
    }

    const popularResorts = filteredResortOptions.filter((item) => item.isPopular === true)
      .sort(this.compareName);
    const otherResorts = filteredResortOptions.filter((item) => item.isPopular !== true)
      .sort(this.compareName);

    const { isAllResortsChecked } = this.state;
    return (
      <div>
        <div className="resort_section">
          {this.renderCountries(countryOptions)}
        </div>
        { countryOptions && countryOptions.length > 0 && <div className="suggestion_section">{SELECTED}</div>}
        <div className="resort_section">
          <label htmlFor="all-resorts" className="checkbox_container">
            <input
              id="all-resorts"
              checked={isAllResortsChecked}
              type="checkbox"
              onChange={() => this.toggleAllResortsCheckbox()}
            />
            {// eslint-disable-next-line react/self-closing-comp
              <span className="checkmark"></span>
            }
            {ALL_RESORTS}
            &nbsp;
            <span className="resort_country">{destination.name}</span>
          </label>
        </div>
        { selectedResorts.length > 0
          && (
            <div className="resort_section">
              {this.renderSelectedCities(selectedResorts)}
            </div>
          )}
        { popularResorts && popularResorts.length > 0 && <div className="suggestion_section">{POPULAR}</div>}
        <div className="resort_section">
          {this.renderCities(popularResorts)}
        </div>
        { otherResorts && otherResorts.length > 0 && <div className="suggestion_section">{OTHER}</div>}
        <div className="resort_section">
          {this.renderCities(otherResorts)}
        </div>
      </div>
    );
  }
}

ResortsPopup.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  resortOptions: PropTypes.instanceOf(Array).isRequired,
  changeSelectedResorts: PropTypes.func.isRequired,
  changeResortOptions: PropTypes.func.isRequired,
  changeDestination: PropTypes.func.isRequired,
  changeSearchInputValue: PropTypes.func.isRequired,
  changeCharterOnly: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changeSelectedResorts,
  changeResortOptions,
  changeDestination,
  changeSearchInputValue,
  changeCharterOnly,
})(ResortsPopup);
