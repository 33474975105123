// Adults

export const increaseAdults = () => (
  {
    type: 'increaseAdults',
  }
);

export const decreaseAdults = () => (
  {
    type: 'decreaseAdults',
  }
);

export const setAdults = (content) => (
  {
    type: 'setAdults',
    payload: content,
  }
);

export const setKids = (content) => (
  {
    type: 'setKids',
    payload: content,
  }
);

// Date
export const changeDeparturetDate = (content) => (
  {
    type: 'changeDeparturetDate',
    payload: content,
  }
);

// Destination
export const changeDestination = (content) => (
  {
    type: 'changeDestination',
    payload: content,
  }
);

export const changeSelectedResorts = (content) => (
  {
    type: 'changeSelectedResorts',
    payload: content,
  }
);

export const changeResortOptions = (content) => (
  {
    type: 'changeResortOptions',
    payload: content,
  }
);

export const changeSearchInputValue = (content) => (
  {
    type: 'changeSearchInputValue',
    payload: content,
  }
);

// Depature
export const changeDepatureCity = (content) => (
  {
    type: 'changeDepatureCity',
    payload: content,
  }
);

export const changeCityFrom = (content) => (
  {
    type: 'changeCityFrom',
    payload: content,
  }
);

// Search form popup  visibility
export const changePopupVisibility = (content) => (
  {
    type: 'changePopupVisibility',
    payload: content,
  }
);

// Nights
export const increaseNightsFrom = {
  type: 'increaseNightsFrom',
};

export const decreaseNightsFrom = {
  type: 'decreaseNightsFrom',
};

export const setNightsFrom = (content) => (
  {
    type: 'setNightsFrom',
    payload: content,
  }
);

export const increaseNightsTo = {
  type: 'increaseNightsTo',
};

export const decreaseNightsTo = {
  type: 'decreaseNightsTo',
};

export const setNightsTo = (content) => (
  {
    type: 'setNightsTo',
    payload: content,
  }
);

export const changeUserAuthStatus = (content) => (
  {
    type: 'changeUserAuthStatus',
    payload: content,
  }
);

// Search value
export const changeSearchResult = (content) => (
  {
    type: 'changeSearchResult',
    payload: content,
  }
);

export const changeAvailableDays = (content) => (
  {
    type: 'changeAvailableDays',
    payload: content,
  }
);

export const changeTabletFilterMenuVisibility = (content) => (
  {
    type: 'changeTabletFilterMenuVisibility',
    payload: content,
  }
);

export const changeTimeIsUpVisibility = (content) => (
  {
    type: 'changeTimeIsUpVisibility',
    payload: content,
  }
);

export const changeHotelDetailsFilterMenuVisibility = (content) => (
  {
    type: 'changeHotelDetailsFilterMenuVisibility',
    payload: content,
  }
);

export const changeCharterOnly = (content) => (
  {
    type: 'changeCharterOnly',
    payload: content,
  }
);
