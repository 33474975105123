import React from 'react';
import PropTypes from 'prop-types';
import './css/Input.css';
import { connect } from 'react-redux';
import InputValue from './InputValue/InputValue';
import Popover from '../Popover/Popover';
import DestinationSearch from './DestinationSearch/DestinationSearch';
import { changePopupVisibility, changeDeparturetDate } from '../../../DataStore/actions/searchFormActions';
import {
  TOURISTS, COUNTRYTO, HOTEL,
} from '../../../Utils/Constants/HomePageConstants';

const mapStateToProps = (state) => ({ ...state });

class Input extends React.Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false,
    };
  }

  handleClick(e) {
    let elementClass;
    if (e) {
      elementClass = e.target.getAttribute('class');
      if (elementClass !== 'cross__ico' && elementClass !== 'input_search_destination') {
        this.processOutsideListener();
      }
    } else {
      this.processOutsideListener();
    }
  }

  handleOutsideClick(e) {
    const elementClasses = e.target.getAttribute('class');

    if ((this.node && (this.node.contains(e.target)))
      || elementClasses === 'resort_option'
      || elementClasses === 'destination_input_w'
      || elementClasses === 'form_tourist_remove_kid') {
      return;
    }

    this.handleClick();
  }

  processOutsideListener() {
    const { popupVisible } = this.state;
    const { changeDeparturetDate: changeDeparturetDateFunc, departureDate } = this.props;

    if (!popupVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);

      if (!departureDate.to) {
        changeDeparturetDateFunc({ from: departureDate.from, to: departureDate.from });
      }
    }

    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  render() {
    const {
      id, label, ico, value,
    } = this.props;
    const { popupVisible } = this.state;

    let inputValue;
    if (id === COUNTRYTO && popupVisible) {
      inputValue = <DestinationSearch />;
    } else {
      inputValue = <InputValue id={id} value={value} />;
    }

    let inputStyle = '';
    if (id === COUNTRYTO || id === HOTEL) {
      inputStyle = 'destination_wrapper_input';
    } else if (id === TOURISTS) {
      inputStyle = 'tourists_wrapper_input';
    }

    return (
      <div className={`sf__input__item ${inputStyle}`} ref={(node) => { this.node = node; }}>
        <button disabled={id === HOTEL} type="button" className={`sf__input__item__wrapper ${popupVisible ? 'active__input' : ''}`} onClick={this.handleClick}>
          <img className="inputIco" src={ico} alt="" />
          <div>
            <div className="sf__input__item__label">{label}</div>
            <div className="sf__input__item__text">
              {inputValue}
            </div>
          </div>
        </button>
        {popupVisible && (
          <Popover id={id} />
        )}
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  ico: PropTypes.string.isRequired,
  departureDate: PropTypes.instanceOf(Object).isRequired,
  changeDeparturetDate: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: '',
};

export default connect(mapStateToProps, { changePopupVisibility, changeDeparturetDate })(Input);
