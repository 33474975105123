/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import './css/DestinationSearch.css';
import { connect } from 'react-redux';
import axios from 'axios';
// import HttpsAgent from 'https-agent';
import crossIco from '../../../../images/input-cross.svg';
import {
  changeSearchResult,
  changeSearchInputValue,
  changeDestination,
  changeResortOptions,
  changeSelectedResorts,
} from '../../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

class DestinationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      destination,
      changeSearchInputValue: changeSearchInputValueFunc,
    } = this.props;

    changeSearchInputValueFunc(destination.name);
    this.input.focus();
  }

  handleChange = (e) => {
    const {
      changeSearchResult: changeSearchResultFunc,
      changeSearchInputValue: changeSearchInputValueFunc,
    } = this.props;

    let isAllCities = false;
    const inputValue = e.target.value;

    changeSearchInputValueFunc(inputValue);

    const qParam = `*${inputValue}*`;
    let countryQuery;

    if (inputValue.length >= 1) {
      countryQuery = { from: 0, size: 1000, query: { bool: { must: [{ query_string: { query: qParam, default_field: 'name' } }, { match: { type: 'country' } }] } } };
      let countriesResult;

      axios
        .post(
          '/th/_search',
          countryQuery,
          {
            auth: {
              username: process.env.REACT_APP_ES_USER,
              password: process.env.REACT_APP_ES_PASSWORD,
            },
          },
        )
        .then((countryRes) => {
        // eslint-disable-next-line no-underscore-dangle
          countriesResult = countryRes.data.hits.hits.map((h) => h._source);

          countriesResult.sort(this.compareRankFn);

          if (countriesResult.length > 5) {
            changeSearchResultFunc(countriesResult);
          } else {
            let cityQuery;
            if (countriesResult.length === 0) {
              isAllCities = true;
              cityQuery = { from: 0, size: 1000, query: { bool: { must: [{ query_string: { query: qParam, default_field: 'name' } }, { match: { type: 'city' } }] } } };
            } else {
              cityQuery = { from: 0, size: 1000, query: { bool: { must: [{ match: { type: 'city' } }, { match: { parentId: countriesResult[0].id } }] } } };
            }

            axios
              .post(
                '/th/_search',
                cityQuery,
                {
                  auth: {
                    username: process.env.REACT_APP_ES_USER,
                    password: process.env.REACT_APP_ES_PASSWORD,
                  },
                },
              )
              .then((cityRes) => {
              // eslint-disable-next-line no-underscore-dangle
                const citiesResult = cityRes.data.hits.hits.map((h) => h._source);
                citiesResult.sort(this.compareName);

                let cities;
                if (!isAllCities) {
                  cities = citiesResult.filter((cityItem) => cityItem.isPopular === true);
                } else {
                  cities = citiesResult;
                }

                let searchResult;
                if (cities.length !== 0) {
                  searchResult = countriesResult.concat(cities);
                } else {
                  searchResult = countriesResult;
                }

                changeSearchResultFunc(searchResult);
              });
          }
        });
    }
  };

  compareRankFn = (a, b) => {
    if (a.rank > b.rank) {
      return 1;
    }
    if (a.rank < b.rank) {
      return -1;
    }
    return 0;
  };

  compareName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  handleClick = () => {
    const {
      changeSearchInputValue: changeSearchInputValueFunc,
    } = this.props;

    changeSearchInputValueFunc('');
    this.input.focus();
  };

  render() {
    const { searchInputValue } = this.props;
    return (
      <div>
        <input
          value={searchInputValue}
          className="input_search_destination"
          ref={(e) => { this.input = e; }}
          onChange={this.handleChange}
        />
        <div onClick={(this.handleClick)} onKeyDown={() => {}} role="button" tabIndex={0}>
          <img className="cross__ico" src={crossIco} alt="" />
        </div>
      </div>
    );
  }
}

DestinationSearch.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  changeSearchResult: PropTypes.func.isRequired,
  searchInputValue: PropTypes.string.isRequired,
  changeSearchInputValue: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changeSearchResult,
  changeSearchInputValue,
  changeDestination,
  changeResortOptions,
  changeSelectedResorts,
})(DestinationSearch);
