/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './css/TouristsPopover.css';
import {
  ADULTS, KIDS_FROM_0_17, ADD_KID,
  BEFORE_YEAR, ONE_YEAR, TWO_YEARS, THREE_YEARS, FOUR_YEARS, FIVE_YEARS, SIX_YEARS,
  SEVEN_YEARS, EIGHT_YEARS, NINE_YEARS, TEN_YEARS, ELEVEN_YEARS, TWELVE_YEARS, THIRTEEN_YEARS,
  FOURTEEN_YEARS, FIFTEEN_YEARS, SIXTEEN_YEARS, SEVENTEEN_YEARS, KIDS_AGE_NOTE,
} from '../../../Utils/Constants/HomePageConstants';
import { getKidsText } from '../../../Utils/CommonUtils';
import {
  increaseAdults, decreaseAdults, setKids,
} from '../../../DataStore/actions/searchFormActions';

import { ReactComponent as AdultIco } from '../../../images/search-form-adult.svg';
import { ReactComponent as KidIco } from '../../../images/search-form-kid.svg';
import { ReactComponent as InfantIco } from '../../../images/search-form-infant.svg';
import { ReactComponent as PlusIco } from '../../../images/search-form-plus.svg';
import { ReactComponent as MinusIco } from '../../../images/search-form-minus.svg';

const mapStateToProps = (state) => ({ ...state });

class TouristsPopover extends React.Component {
  constructor(props) {
    const { kids } = props;
    super();
    this.state = {
      kidsAgeSelectorVisible: false,
      isAddKidsButtonVisible: kids.length < 3,
    };
  }

  changeKidsAgeSelectorVisibility = () => {
    const { kidsAgeSelectorVisible } = this.state;

    if (kidsAgeSelectorVisible) {
      document.removeEventListener('click', this.handleOutsideClick, false);
    } else {
      document.addEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ kidsAgeSelectorVisible: !kidsAgeSelectorVisible });
  };

  handleOutsideClick = (e) => {
    const elementClasses = e.target.getAttribute('class');

    if ((this.node && (this.node.contains(e.target)))
      || elementClasses === 'btn__blue form_tourist_button') {
      // eslint-disable-next-line no-useless-return
      return;
    // eslint-disable-next-line no-else-return
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
      this.setState({ kidsAgeSelectorVisible: false });
    }
  };

  removeKid = (kid) => {
    const { kids, setKids: setKidsAction } = this.props;

    const res = kids.filter((kidItem) => kidItem.index !== kid.index);

    this.setState({ isAddKidsButtonVisible: true });

    setKidsAction(res);
  };

  renderKids = (kids) => kids.map((kid, index) => {
    // eslint-disable-next-line no-param-reassign

    return (
      <div key={`form_kid_item-${index}`} className="form_kid_item">
        <div className="form_collapse__text">
          <div className="form_collapse__text_ico">
            {this.getKidIco(kid.age, kid.index)}
          </div>
          <div className="form__tourists__selector__item__left__text">
            <div className="form__tourists__selector__item__left__text__bold">
              {kid.text}
            </div>
            <button onClick={() => this.removeKid(kid)} className="form_tourist_remove_kid" type="button">
              &#x2715;
            </button>
          </div>
        </div>
      </div>
    );
  });

  hadleKidClick = (age) => {
    const { kids, setKids: setKidsAction } = this.props;

    const kid = { age, text: getKidsText(age), index: kids.length };

    const result = kids.concat([kid]);
    setKidsAction(result);

    if (result.length === 3) {
      this.setState({ isAddKidsButtonVisible: false, kidsAgeSelectorVisible: false });
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  };

  getKidIco = (age, index) => {
    const content = [];

    if (age < 3) {
      content.push(<InfantIco key={`kid-ico-${index}`} />);
    } else if (age < 13) {
      content.push(<KidIco key={`kid-ico-${index}`} />);
    } else {
      content.push(<AdultIco key={`kid-ico-${index}`} />);
    }

    return content;
  };

  render() {
    const {
      adults, increaseAdults: increaseAdultsAction, decreaseAdults: decreaseAdultsAction, kids,
    } = this.props;

    const { kidsAgeSelectorVisible, isAddKidsButtonVisible } = this.state;

    return (
      <div className="sf__tourists__popover__wrapper">
        <div className="form_collapse__item">
          <div className="form_collapse__text">
            <div className="form_collapse__text_ico">
              <AdultIco />
            </div>
            <div className="form__tourists__selector__item__left__text">
              <div className="form__tourists__selector__item__left__text__bold">
                {ADULTS}
              </div>
            </div>
          </div>
          <div className="form__collapse__selector">
            <button className="form_tourists_select_button" type="button" onClick={() => { decreaseAdultsAction(); }}>
              <MinusIco />
            </button>
            <div className="form__collapse__selector_quantity">{adults}</div>
            <button className="form_tourists_select_button" type="button" onClick={() => { increaseAdultsAction(); }}>
              <PlusIco />
            </button>
          </div>
        </div>
        <div className="form_tourist_kids_section">
          <div className="form_tourist_kids_section_line"></div>
          <div className="form_tourist_kids_section_title">{KIDS_FROM_0_17}</div>
          <div className="form_tourist_kids_section_line"></div>
        </div>
        <div>{this.renderKids(kids)}</div>
        {isAddKidsButtonVisible && (
          <div className="form_tourist_button_w">
            <button type="button" className="btn__blue form_tourist_button" onClick={() => this.changeKidsAgeSelectorVisibility()}>
              {ADD_KID}
            </button>
          </div>
        )}
        <div ref={(node) => { this.node = node; }} className={`form_kinds_options_wrapper ${kidsAgeSelectorVisible ? '' : 'form_kids_opt_inv'}`}>
          <div className="form_kinds_options_w">
            <div className="form_kids_age_note">{KIDS_AGE_NOTE}</div>
            <div className="form_kinds_options">
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(0); }}>{BEFORE_YEAR}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(1); }}>{ONE_YEAR}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(2); }}>{TWO_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(3); }}>{THREE_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(4); }}>{FOUR_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(5); }}>{FIVE_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(6); }}>{SIX_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(7); }}>{SEVEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(8); }}>{EIGHT_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(9); }}>{NINE_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(10); }}>{TEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(11); }}>{ELEVEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(12); }}>{TWELVE_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(13); }}>{THIRTEEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(14); }}>{FOURTEEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(15); }}>{FIFTEEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(16); }}>{SIXTEEN_YEARS}</button>
              <button type="button" className="form_t_kids_item" onClick={() => { this.hadleKidClick(17); }}>{SEVENTEEN_YEARS}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TouristsPopover.propTypes = {
  adults: PropTypes.number.isRequired,
  kids: PropTypes.instanceOf(Array).isRequired,
  setKids: PropTypes.func.isRequired,
  increaseAdults: PropTypes.func.isRequired,
  decreaseAdults: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    setKids, increaseAdults, decreaseAdults,
  },
)(TouristsPopover);
