/* eslint-disable class-methods-use-this */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import ImageGallery from 'react-image-gallery';
import './css/HotelDescription.css';
import whiteLike from '../../images/white-like.svg';
import beachLineIco from '../../images/beach-line-40x40.png';
import acIco from '../../images/ac-40x40.svg';
import sendIco from '../../images/send-40x40.svg';
import wifiIco from '../../images/wifi-40x40.svg';
import starIco from '../../images/star.svg';
// import defaultImage from '../../images/default.webp';

class HotelDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPhotos = (hotel, hotelImagesDesctop, hotelImagesTablet) => {
    if (hotel.photoCount) {
    // eslint-disable-next-line no-plusplus
      for (let i = 0; i < hotel.photoCount; i++) {
        hotelImagesDesctop.push({
          original: `/i/im/${hotel.hotelId}_${i}_1200_800_0.jpg`,
          thumbnail: `/i/p/${hotel.hotelId}_${i}_100_100_1.jpg`,
        });

        hotelImagesTablet.push({
          original: `/i/im/${hotel.hotelId}_${i}_1200_800_0.jpg`,
          thumbnail: `/i/p/${hotel.hotelId}_${i}_120_220_1.jpg`,
        });
      }
    }
  };

  renderHotel = (hotel) => {
    const hotelImagesDesctop = [];
    const hotelImagesTablet = [];

    this.getPhotos(hotel, hotelImagesDesctop, hotelImagesTablet);

    let beachLine = 0;
    if (hotel.facilities && hotel.facilities[6]) {
      if (hotel.facilities[6] == 110) {
        beachLine = 1;
      } else if (hotel.facilities[6] == 111) {
        beachLine = 2;
      } else if (hotel.facilities[6] == 122) {
        beachLine = 3;
      }
    }

    const beachType = [];
    if (hotel.facilities && hotel.facilities[20]) {
      hotel.facilities[20].forEach((e) => {
        if (e == 147) {
          beachType.push('Песок мелкий');
        } else if (e == 148) {
          beachType.push('Песок крупный');
        } else if (e == 149) {
          beachType.push('Песок вулканический');
        } else if (e == 150) {
          beachType.push('Песок жёлтый');
        } else if (e == 151) {
          beachType.push('Песок белый');
        } else if (e == 152) {
          beachType.push('Песок красный');
        } else if (e == 153) {
          beachType.push('Песок серый');
        } else if (e == 154) {
          beachType.push('Песок чёрный');
        } else if (e == 155) {
          beachType.push('Галька');
        } else if (e == 156) {
          beachType.push('Песчано-ракушечный');
        } else if (e == 157) {
          beachType.push('Каменистый');
        } else if (e == 158) {
          beachType.push('Террасный');
        } else if (e == 159) {
          beachType.push('Бетонный');
        }
      });
    }

    return (
      <div className="hotel_description_w">
        <div className="hd_description_left_w">
          <div className="hotel_image_carusel_desctop">
            <ImageGallery
              items={hotelImagesDesctop}
              showPlayButton={false}
              autoPlay
              // onErrorImageURL={defaultImage}
            />
          </div>
          <div className="hotel_image_carusel_tablet">
            <ImageGallery
              items={hotelImagesTablet}
              showPlayButton={false}
              thumbnailPosition="right"
              autoPlay
              // onErrorImageURL={defaultImage}
            />
          </div>
        </div>
        <div className="hd_description_right_w">
          <div className="hd_description_w">
            <div>
              <div className="hd_description_row1">
                <div>
                  <div>{this.renderHotelCategory(hotel.hotelCategory)}</div>
                  <h3 className="hd_hotel_name">{hotel.hotelName}</h3>
                  <div className="hd_location">
                    {`${hotel.city}, ${hotel.country}`}
                  </div>
                </div>
                <div className="hd_raiting_w">
                  { hotel.tripAdvisorRating > 0 && (
                    <div className="hd_raiting_like_w">
                      <img src={whiteLike} alt="" />
                      <div className="hd_raiting_like">{hotel.tripAdvisorRating}</div>
                    </div>
                  )}
                  {hotel.tripAdvisorReviewsCount > 0 && (
                    <div className="hd_raiting_desc">
                      <div className="hd_raiting_name_desc">
                        {hotel.tripAdvisorReviewsCount}
                        &nbsp;
                        отзывов
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="hd_short_description">
                {hotel.hotelDescription}
              </div>
            </div>
            {(hotel.facilities && !(!hotel.facilities[1] && !hotel.facilities[8]
            && !hotel.facilities[6] && beachType.length === 0)) && (
              <div className="hd_description_facilities_w">
                { (hotel.facilities && hotel.facilities[6])
                    && (
                    <div className="hd_description_facility_w">
                      <img src={beachLineIco} alt="" />
                      <div className="hd_description_facility">
                        {beachLine}
                        -ая линия
                      </div>
                    </div>
                    )}

                { (hotel.facilities
                  && hotel.facilities[8] && hotel.facilities[8].filter((e) => e === 24).length > 0)
                  && (
                  <div className="hd_description_facility_w">
                    <img src={acIco} alt="" />
                    <div className="hd_description_facility">
                      Кондиционер
                    </div>
                  </div>
                  )}

                { beachType.length > 0
                    && (
                    <div className="hd_description_facility_w">
                      <img src={sendIco} alt="" />
                      <div className="hd_description_facility">
                        {beachType.map((e, i) => <div key={`hd-beachType-${i}`}>{e}</div>)}
                      </div>
                    </div>
                    )}
                { hotel.facilities && hotel.facilities[1]
                      && (
                      <div className="hd_description_facility_w">
                        <img src={wifiIco} alt="" />
                        <div className="hd_description_facility">Wi-Fi</div>
                      </div>
                      )}
              </div>
            )}
          </div>
          <div className="hd_description_price_w">
            <div className="hd_description_price">
              <div className="tr_price">от&nbsp;</div>
              <div>
                <div className="hd_desc_tour_price">{`${hotel.lowestPrice} ${hotel.currency}`}</div>
                {hotel.bynPrice > 0 && <div className="hd_desc_tour_price_byn">{`${hotel.bynPrice} BYN`}</div>}
              </div>
            </div>
            <div className="hd_description_tourists_w">
              <div className="hd_description_tourists">
                <div>{this.getTouristsText(hotel)}</div>
                <div>{this.getDateText(hotel)}</div>
              </div>
            </div>
            <div className="hd_description_price_empty"></div>
          </div>
        </div>
      </div>
    );
  };

  renderHotelCategory = (starsCount) => {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < starsCount; i++) {
      content.push(<img alt="" key={`hd-stars-${i}`} className="tr_category_star" src={starIco} />);
    }
    return content;
  };

  getTouristsText = (tour) => {
    let result = '';

    if (tour.adultsCount === 1) {
      result = '1 взрослый';
    } else {
      result = `${tour.adultsCount} взрослых`;
    }

    if (tour.kidsCount === 1) {
      result += ', 1 ребенок';
    } else if (tour.kidsCount > 1) {
      result += `, ${tour.kidsCount} детей`;
    }

    return result;
  };

  getDateText = (tour) => {
    let result = `с ${tour.departureDate} на`;

    if (tour.nightsCount === 1) {
      result = `${result} 1 ночь`;
    } else if (tour.nightsCount < 5) {
      result = `${result} ${tour.nightsCount} ночи`;
    } else {
      result = `${result} ${tour.nightsCount} ночей`;
    }

    return result;
  };

  render() {
    const { hotel } = this.props;

    return (
      <div>
        {this.renderHotel(hotel)}
      </div>
    );
  }
}

HotelDescription.propTypes = {
  hotel: PropTypes.instanceOf(Object).isRequired,
};

export default HotelDescription;
