import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({ ...state });

class NightsInputValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { nightsFrom, nightsTo } = this.props;
    let value;
    if (nightsFrom === nightsTo) {
      value = `${nightsFrom} ночей`;
    } else {
      value = `${nightsFrom} - ${nightsTo} ночей`;
    }
    return (
      <div>
        <span className="input_value_diss_part">на&nbsp;</span>
        {value}
      </div>
    );
  }
}

NightsInputValue.propTypes = {
  nightsFrom: PropTypes.number.isRequired,
  nightsTo: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(NightsInputValue);
