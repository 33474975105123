import React from 'react';
import ADV1 from '../../../images/advantage1.svg';
import ADV2 from '../../../images/advantage2.svg';
import ADV3 from '../../../images/advantage3.svg';
import './css/Advantages.css';
import {
  ADV1_TITLE, ADV1_TEXT, ADV2_TITLE, ADV2_TEXT, ADV3_TITLE, ADV3_TEXT,
} from '../../../Utils/Constants/HomePageConstants';
import Check from '../../../images/check-ico.svg';

function Advantages() {
  return (
    <div className="container">
      <div className="advantages">
        <h3 className="blue__title">Наши преимущества</h3>
        <div className="advantages__item__wrapper">
          <div className="advantages__item">
            <img className="advantages__icon" src={ADV1} alt="" />
            <h4 className="advantages__subtitle">{ADV1_TITLE}</h4>
            <div className="advantages__text">
              <img className="adv-check-ico" alt="" src={Check} />
              {ADV1_TEXT}
            </div>
          </div>
          <div className="advantages__item">
            <img className="advantages__icon" src={ADV2} alt="" />
            <h4 className="advantages__subtitle">{ADV2_TITLE}</h4>
            <div className="advantages__text">
              <img className="adv-check-ico" alt="" src={Check} />
              {ADV2_TEXT}
            </div>
          </div>
          <div className="advantages__item">
            <img className="advantages__icon" src={ADV3} alt="" />
            <h4 className="advantages__subtitle">{ADV3_TITLE}</h4>
            <div className="advantages__text">
              <img className="adv-check-ico" alt="" src={Check} />
              {ADV3_TEXT}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;
