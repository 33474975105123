/* eslint-disable class-methods-use-this */
import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { getHotels } from '../../../Utils/RestUtils';
import { NOT_AVAILABLE } from '../../../Utils/Constants/GlobalConstants';
import './css/HotelsFilter.css';

const mapStateToProps = (state) => ({ ...state });

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

function shouldRenderSuggestions() {
  return true;
}

function renderSuggestion(suggestion) {
  return (
    suggestion.name
  );
}

class HotelsFilter extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      options: [],
      isSpinnerVisible: false,
      isError: false,
      currentCountry: '',
    };
  }

  componentDidMount() {
    const {
      addHotelFilter, location,
    } = this.props;

    const queryParameters = new URLSearchParams(location.search);

    if (!location.state) {
      if (queryParameters.has('to') && queryParameters.get('to') !== ''
        && queryParameters.has('hotels') && queryParameters.get('hotels') !== '') {
        const to = queryParameters.get('to');

        this.setState({ currentCountry: to });

        getHotels(to)
          .then((result) => {
            const data = result.data.GetHotelsResult.Data;

            const options = [];
            data.forEach((e) => {
              options.push({ name: e.Name, key: `${e.Id}-hotel`, id: e.Id });
            });

            this.setState({ options });

            const urlHotelIds = queryParameters.get('hotels').split(',');

            const selectedHotels = [];
            urlHotelIds.forEach((hotelId) => {
              // eslint-disable-next-line eqeqeq
              const filteredIds = data.filter((i) => i.Id == hotelId);

              if (filteredIds.length > 0) {
                const hotelFromUrl = filteredIds[0];
                selectedHotels.push({ name: hotelFromUrl.Name, key: `${hotelFromUrl.Id}-hotel`, id: hotelFromUrl.Id });
              }
            });

            if (selectedHotels.length > 0) {
              addHotelFilter(selectedHotels);
            }
          });
      }
    }
  }

  componentDidUpdate() {
    const { isDisabled } = this.props;

    const elements = document.getElementsByClassName('react-autosuggest__input');

    if (elements.length > 0) {
      if (isDisabled) {
        elements[0].disabled = true;
      } else {
        elements[0].disabled = false;
      }
    }
  }

  onChange = (event, { newValue }) => {
    const { selectedHotels, addHotelFilter } = this.props;

    if (newValue.name) {
      if (selectedHotels.filter((e) => e.id === newValue.id).length === 0) {
        addHotelFilter([newValue]);
      }
      this.setState({ value: '' });
    } else {
      this.setState({ value: newValue });
    }
  };

  async onSuggestionsFetchRequested({ value }) {
    const { options, currentCountry } = this.state;
    const { destination } = this.props;

    let suggestions = [];

    if (options.length === 0 || currentCountry !== destination.id) {
      const hotelsResult = await this.getAllHotels();

      const optionsResult = [];
      hotelsResult.forEach((e) => {
        optionsResult.push({ name: e.Name, key: `${e.Id}-hotel`, id: e.Id });
      });

      this.setState({ options: optionsResult, currentCountry: destination.id });
      suggestions = this.getSuggestions(value, optionsResult);
    } else {
      suggestions = this.getSuggestions(value, options);
    }

    this.setState({
      suggestions,
    });
  }

  getSuggestions = (value, options) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp(`^${escapedValue}`, 'i');

    return options.filter((e) => regex.test(e.name));
  };

  async getAllHotels() {
    this.setState({ isSpinnerVisible: true, isError: false });
    const { destination, selectedResorts } = this.props;
    let hotels = [];

    const data = await getHotels(destination.id, selectedResorts.map((item) => item.id))
      .catch(() => {
        this.setState({ isSpinnerVisible: false, isError: true });
      });

    if (data && data.data) {
      hotels = data.data.GetHotelsResult.Data;
    }

    this.setState({ isSpinnerVisible: false });
    return hotels;
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
    this.input.blur();
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  removeHotelFilter = (id) => {
    const { selectedHotels, removeHotelFilter } = this.props;

    if (selectedHotels.filter((e) => e.id === id).length > 0) {
      removeHotelFilter(id);
    }
  };

  // eslint-disable-next-line class-methods-use-this, arrow-body-style
  renderSelectedHotels = (hotels) => hotels.map((hotel) => {
    const { isDisabled } = this.props;
    return (
      <div className="selectedHotel" key={hotel.key}>
        <div>
          {hotel.name}
        </div>
        <div>
          {!isDisabled && (
            <button onClick={() => this.removeHotelFilter(hotel.id)} className="remove_hotel_filter" type="button">
              &#x2715;
            </button>
          )}
        </div>
      </div>
    );
  });

  render() {
    const { selectedHotels } = this.props;
    const {
      value, suggestions, isSpinnerVisible, isError,
    } = this.state;
    const inputProps = {
      placeholder: 'Название отеля',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="filter_item">
        <div className="hotel_class_title">Поиск по названию отеля</div>
        <div className="hotelsFilter">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={(e) => this.onSuggestionsFetchRequested(e)}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={shouldRenderSuggestions}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            ref={this.storeInputReference}
          />

          <ThreeDots
            visible={isSpinnerVisible}
            height="30"
            width="30"
            color="#00BFFF"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass="hotel_filter_spinner"
          />

          {isError && (
            <div className="hotels_error">{NOT_AVAILABLE}</div>
          )}

          {this.renderSelectedHotels(selectedHotels)}
        </div>
      </div>
    );
  }
}

HotelsFilter.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  selectedHotels: PropTypes.instanceOf(Array).isRequired,
  addHotelFilter: PropTypes.func.isRequired,
  removeHotelFilter: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool,
};

HotelsFilter.defaultProps = {
  isDisabled: false,
};

export default connect(mapStateToProps, {})(HotelsFilter);
