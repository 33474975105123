import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminOrder from './AdminOrder';

function AdminOrderFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AdminOrder location={location} navigate={navigate} />
  );
}

export default AdminOrderFunc;
