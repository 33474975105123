export function onlyRusLetters(value) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[а-яА-ЯёЁ\s]*$/i;

  return regex.test(value);
}

export function onlyNumbers(value) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[0-9]*$/i;

  return regex.test(value);
}

export function onlyLatLetters(value) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[a-zA-Z\s]*$/i;

  return regex.test(value);
}

export function passportSeriaAndNumber(value) {
  // eslint-disable-next-line no-useless-escape
  const seria = /^[0-9a-zA-Z]*$/i;

  return seria.test(value);
}

export function cardNumberValidation(value) {
  const regex = /^[0-9\s]*$/i;

  return regex.test(value);
}

export function exchangeRateValidation(value) {
  const regex = /^[0-9.]*$/i;

  return regex.test(value);
}
