import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeLoginModalVisibility } from '../../DataStore/actions/modalWindowActions';
import LoginForm from './LoginForm/LoginForm';
import RegisterForm from './RegisterForm/RegisterForm';
import './css/LoginModal.css';

const mapStateToProps = (state) => ({ ...state });

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegisterForm: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    const { isLoginModalVisible } = this.props;
    if (isLoginModalVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if ((this.node.contains(e.target) && this.closeNode !== e.target)
      || e.target.className === 'showReg'
      || e.target.className === 'login__btn') {
      return;
    }

    this.close();
  }

  showRegisterForm = () => {
    this.setState({ isRegisterForm: true });
  };

  showLoginForm = () => {
    this.setState({ isRegisterForm: false });
  };

  close = () => {
    const { changeLoginModalVisibility: changeLoginModalVisibilityAction } = this.props;

    document.body.style.overflow = 'unset';
    this.setState({ isRegisterForm: false });

    changeLoginModalVisibilityAction(false);

    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  render() {
    const { isLoginModalVisible, location } = this.props;
    const { isRegisterForm } = this.state;

    if (!isLoginModalVisible) {
      return null;
    }

    document.body.style.overflow = 'hidden';
    this.handleClick();

    return (
      <div className="modal-back">
        <div className="modal" ref={(node) => { this.node = node; }}>
          <div className="closeWrapper">
            <div className="closeButton">
              <button type="button" ref={(closeNode) => { this.closeNode = closeNode; }}>
                &#x2715;
              </button>
            </div>
          </div>
          {
          isRegisterForm
            ? (
              <RegisterForm
                closeModal={this.close}
                location={location}
                showLoginForm={this.showLoginForm}
              />
            )
            : (
              <LoginForm
                closeModal={this.close}
                location={location}
                showRegForm={this.showRegisterForm}
              />
            )
          }
        </div>
      </div>
    );
  }
}

LoginModal.propTypes = {
  changeLoginModalVisibility: PropTypes.func.isRequired,
  isLoginModalVisible: PropTypes.bool.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, { changeLoginModalVisibility })(LoginModal);
