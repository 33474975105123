// Intro
export const INTRO_TITLE_LINE1 = 'Выбирай и покупай';
export const INTRO_TITLE_LINE2 = 'туры онлайн';
export const INTRO_SUBTITLE_LINE1 = 'по ценам туроператоров или ниже';
export const INTRO_SUBTITLE_LINE2 = 'с поддержкой до и после покупки';
export const INTRO_WARRANTY = '100% гарантия честных цен';

// Popular Directions
export const POPULAR_TITLE = 'Популярные направления';
export const CUPRUS = 'Кипр';
export const VISA_ONLINE = 'Онлайн виза';
export const VISA_WITHOUT = 'Без визы';
export const CUPRUS_CITIES = 'Пафос, Протарас, Лимассол, Ларнака, Айя-Напа.';
export const CUPRUS_PRICE = 'от 700 руб./чел.';
export const TURKEY = 'Турция';
export const TURKEY_PRICE = 'от 450 руб./чел.';
export const TAILAND = 'Таиланд';
export const TAILAND_CITY = 'Паттайя, Пхукет, СамуиКо Чанг, Краби.';
export const TAILAND_PRICE = 'от 750 руб./чел.';
export const ALL_DIRECTIONS = 'Посмотреть все направления';
// Advantages
export const ADV1_TITLE = 'Покупайте туры онлайн пока они не закончились';
export const ADV1_TEXT = 'Так вы забронируете тур по самой низкой цене и сэкономите время. Туры быстро заканчиваются. Забронируйте тур мечты онлайн — успейте поймать самую низкую цену!';
export const ADV2_TITLE = 'Гарантируем самые честные цены';
export const ADV2_TEXT = 'Цены в нашем интернет-магазине такие же, как у туроператоров, или ниже — вы никогда не переплачиваете.';
export const ADV3_TITLE = 'Круглосуточная поддержка до и после покупки тура';
export const ADV3_TEXT = 'Всегда на связи по телефону и в мессенджерах.';

// Description
export const DESC_TITLE = 'TourHunter - Поиск туров по всем туроператорам онлайн.';
export const DESC1 = 'Нашими партнерами являются самые крупные и надежные туроператоры, поэтому вы можете быть уверены в качестве приобретенного тура. Кроме того, на  нашем сайте можно купить тур по самым лучшим ценам – мы предоставляем вам возможность отправиться в путешествие с лучшим соотношением цена/качество.';
export const DESC2 = 'Выбирая отдых с TourHunter.by, вы гарантированно получаете огромный выбор путешествий для отдыха, поэтому купить путевку у нас не сложнее, чем совершать покупки в любом онлайн магазине. Лучшие цены и первоклассное профессиональное обслуживание – гарантия вашего качественного отдыха.';

// Search form
export const TOURISTS_LABEL = 'Туристы';
export const NIGHTS_LABEL = 'Кол-во ночей';
export const DATE_LABEL = 'Интервал дат вылета';
export const COUNTRY_TO_LABEL = 'Страна или курорт';
export const HOTEL_LABEL = 'Отель';
export const CITY_FROM = 'Город вылета:';
export const ADULTS = 'Взрослые';
export const KIDS_FROM_0_17 = 'ДЕТИ ОТ 0 ДО 17';
export const KIDS = 'Дети';
export const KIDS_AGE_RANGE = 'от 2 до 11 лет';
export const ADD_KID = 'Добавить ребенка';

export const BEFORE_YEAR = 'до 1 года';
export const ONE_YEAR = '1 год';
export const TWO_YEARS = '2 года';
export const THREE_YEARS = '3 года';
export const FOUR_YEARS = '4 года';
export const FIVE_YEARS = '5 лет';
export const SIX_YEARS = '6 лет';
export const SEVEN_YEARS = '7 лет';
export const EIGHT_YEARS = '8 лет';
export const NINE_YEARS = '9 лет';
export const TEN_YEARS = '10 лет';
export const ELEVEN_YEARS = '11 лет';
export const TWELVE_YEARS = '12 лет';
export const THIRTEEN_YEARS = '13 лет';
export const FOURTEEN_YEARS = '14 лет';
export const FIFTEEN_YEARS = '15 лет';
export const SIXTEEN_YEARS = '16 лет';
export const SEVENTEEN_YEARS = '17 лет';
export const KIDS_AGE_NOTE = 'Возраст на начало поездки';

export const TOURISTS = 'TOURISTS';
export const NIGHTS = 'NIGHTS';
export const DATE = 'DATE';
export const COUNTRYTO = 'COUNTRYTO';
export const HOTEL = 'HOTEL';
export const SEARCH = 'ИСКАТЬ';
export const TOUR = 'ТУР';

// Search form nights popover
export const SELECTED_DAYS = 'Выбраны туры';
export const SELECTED_NA = 'на';
export const SELECTED_NIGHTS = 'ночей';
