import React from 'react';
import { Link } from 'react-router-dom';
import './css/NotFound.css';

function NotFound() {
  return (
    <div className="content">
      <div className="container">
        <h2>Страница не найдена</h2>
        <Link to="/" className="not_found_link">На главную</Link>
      </div>
    </div>
  );
}

export default NotFound;
