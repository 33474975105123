/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import TouristsInputValue from '../TouristsInputValue/TouristsInputValue';
import NightsInputValue from '../NightsInputValue/NightsInputValue';
import DayPickerInputValue from '../DayPickerInputValue/DayPickerInputValue';
import DestinationInputValue from '../DestinationInputValue/DestinationInputValue';
import HotelInputValue from '../HotelInputValue/HotelInputValue';

class InputValue extends React.Component {
  selectInputValue = (id) => {
    const { value } = this.props;

    switch (id) {
      case 'HOTEL':
        return <HotelInputValue value={value} />;
      case 'TOURISTS':
        return <TouristsInputValue />;
      case 'NIGHTS':
        return <NightsInputValue />;
      case 'DATE':
        return <DayPickerInputValue />;
      case 'COUNTRYTO':
        return <DestinationInputValue />;
      default:
        return null;
    }
  };

  render() {
    const { id } = this.props;
    const value = this.selectInputValue(id);
    return (
      <div>{value}</div>
    );
  }
}

InputValue.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InputValue.defaultProps = {
  value: '',
};

export default InputValue;
