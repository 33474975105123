import React from 'react';
import './css/Operators.css';
import Tez from '../../../images/teztour-logo.png';
import FS from '../../../images/fs-logo.svg';
import Anex from '../../../images/anex-tour-logo.svg';
import TimeV from '../../../images/time-v-logo.png';
import AeroTravel from '../../../images/aero-travel.jpeg';
import Pegas from '../../../images/pegas-touristik-logo.png';
import VoyageT from '../../../images/voyage-tour-logo.svg';
import BiblioG from '../../../images/biblio-g.png';

function Operators() {
  return (
    <div className="operators">
      <div className="container">
        <div className="operators__wrapper">
          <div className="operators__item">
            <img className="op-tex-logo" src={Tez} alt="" />
          </div>
          <div className="operators__item">
            <img src={FS} alt="" />
          </div>
          <div className="operators__item">
            <img className="voyage-tour-logo" src={VoyageT} alt="" />
          </div>
          <div className="operators__item">
            <img src={Anex} alt="" />
          </div>
          <div className="operators__item pegas__operator">
            <img src={Pegas} alt="" />
          </div>
          <div className="operators__item aero-travel-operator">
            <img className="aero-travel-logo" src={AeroTravel} alt="" />
          </div>
          <div className="operators__item op-tv-operator">
            <img className="op-tv-logo" src={TimeV} alt="" />
          </div>
          <div className="operators__item">
            <img className="op-biblio-g-logo" src={BiblioG} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operators;
