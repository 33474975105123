import React from 'react';
// import { Link } from 'react-router-dom';
import './css/PopularDirections.css';
import {
  POPULAR_TITLE, VISA_WITHOUT,
  TURKEY, /* ALL_DIRECTIONS, */
} from '../../../Utils/Constants/HomePageConstants';
import { HOME_PAGE_IMAGE_ALT } from '../../../Utils/Constants/SEO';

function PopularDirections() {
  return (
    <div className="directions">
      <div className="container">
        <h3 className="blue__title">{POPULAR_TITLE}</h3>
        <div className="directions__wrapper">
          <div className="directions__item">
            <div className="directions__oae__img" />
            <div className="directions__description">
              <div className="directions__description__firstline">
                <div className="directions__description__name">ОАЭ</div>
                <div className="blue__label">{VISA_WITHOUT}</div>
              </div>
              <div className="directions__description__city">Дубай</div>
              <div className="directions__description__price">от 729 usd/чел</div>
            </div>
          </div>
          <div className="directions__item">
            <img className="directions__turkey__img" src="https://tourhunter.by/file/turkey.jpg" alt={HOME_PAGE_IMAGE_ALT} />
            <div className="directions__description">
              <div className="directions__description__firstline">
                <div className="directions__description__name">{TURKEY}</div>
                <div className="blue__label">{VISA_WITHOUT}</div>
              </div>
              <div className="directions__description__city">Сиде</div>
              <div className="directions__description__price">576 eur/чел</div>
            </div>
          </div>
          <div className="directions__item tailand__direction">
            <div className="directions__egypt__img" />
            <div className="directions__description">
              <div className="directions__description__firstline">
                <div className="directions__description__name">Египет</div>
                <div className="blue__label">{VISA_WITHOUT}</div>
              </div>
              <div className="directions__description__city">Шарм-Эль-Шейх</div>
              <div className="directions__description__price">757 eur/чел</div>
            </div>
          </div>
        </div>
        {/* <Link to="/" className="directions__all">{ALL_DIRECTIONS}</Link> */}
      </div>
    </div>
  );
}

export default PopularDirections;
