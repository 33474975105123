/* eslint-disable class-methods-use-this */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import ImageGallery from 'react-image-gallery';
import './css/TourDescription.css';
import whiteLike from '../../images/white-like.svg';
import starIco from '../../images/star.svg';

class HotelDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPhotos = (hotelId, priceData, hotelImagesDesctop, hotelImagesTablet) => {
    if (priceData.photoCount) {
    // eslint-disable-next-line no-plusplus
      for (let i = 0; i < priceData.photoCount; i++) {
        hotelImagesDesctop.push({
          original: `/i/im/${hotelId}_${i}_1200_800_0.jpg`,
          thumbnail: `/i/p/${hotelId}_${i}_100_100_1.jpg`,
        });

        hotelImagesTablet.push({
          original: `/i/im/${hotelId}_${i}_1200_800_0.jpg`,
          thumbnail: `/i/p/${hotelId}_${i}_120_220_1.jpg`,
        });
      }
    }
  };

  renderHotel = (hotelId, priceData, adults, kids, taRaiting, taReviews) => {
    const hotelImagesDesctop = [];
    const hotelImagesTablet = [];

    this.getPhotos(hotelId, priceData, hotelImagesDesctop, hotelImagesTablet);

    return (
      <div className="td_hotel_description_w">
        <div className="td_description_left_w">
          <div className="hotel_image_carusel_desctop">
            <ImageGallery
              items={hotelImagesDesctop}
              showPlayButton={false}
            />
          </div>
          <div className="hotel_image_carusel_tablet">
            <ImageGallery
              items={hotelImagesTablet}
              showPlayButton={false}
              thumbnailPosition="right"
            />
          </div>
        </div>
        <div className="td_description_right_w">
          <div className="td_description_w">
            <div>
              <div className="td_description_row1">
                <div>
                  <div>{this.renderHotelCategory(priceData.hotelCategory)}</div>
                  <h3 className="td_hotel_name">{priceData.hotelName}</h3>
                  <div className="td_location">
                    {`${priceData.resort}, ${priceData.countryTo}`}
                  </div>
                </div>
                <div className="td_raiting_w">
                  {(taRaiting && taRaiting != 0) && (
                    <div className="td_raiting_like_w">
                      <img src={whiteLike} alt="" />
                      <div className="td_raiting_like">{taRaiting}</div>
                    </div>
                  )}
                  {(taReviews && taReviews > 0) && (
                    <div className="td_raiting_desc">
                      <div className="td_raiting_name_desc">
                        {taReviews}
                        &nbsp;
                        отзывов
                      </div>
                    </div>
                  )}
                </div>
              </div>
              { priceData.program && (
                <div className="td_details">
                  <div className="td_details_title">Артикул тура</div>
                  <div>{priceData.program}</div>
                </div>
              )}
              <div className="td_details">
                <div className="td_details_title">Номер</div>
                <div>
                  { priceData.roomType ? priceData.roomType : ''}
                  {priceData.hotelPlaceName && ` (${priceData.hotelPlaceName})` }
                  {priceData.settlementType && ` - ${priceData.settlementType}`}
                </div>
                <div className="td_details_desc">{priceData.hotelPlaceDesc ? priceData.hotelPlaceDesc : ''}</div>
              </div>
              <div className="td_details">
                <div className="td_details_title">Питание</div>
                <div>{priceData.meal ? priceData.meal : ''}</div>
              </div>
            </div>
          </div>
          <div className="td_description_price_w">
            <div className="td_description_price">
              {priceData.originalPrice}
              &nbsp;
              {priceData.currency}
            </div>
            <div className="td_description_tourists_w">
              <div className="td_description_tourists">
                <div>{this.getTouristsText(adults, kids)}</div>
                <div>{this.getDateText(priceData)}</div>
              </div>
              <div className="td_description_details">
                <div className="td_description_item">
                  <div className="td_description_det_title">Оператор</div>
                  <div>{priceData.operator}</div>
                </div>
                <div className="td_description_item">
                  <div className="td_description_det_title">Входит в стоимость</div>
                  {priceData.flightIncluded && <div>Авиаперелет</div>}
                  {priceData.mealIncluded && <div>Питание</div>}
                  {priceData.apartmentIncluded && <div>Проживание</div>}
                  {priceData.transferIncluded && <div>Трансфер</div>}
                  {priceData.insuranceIncluded && <div>Медстраховка</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHotelCategory = (starsCount) => {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < starsCount; i++) {
      content.push(<img alt="" key={`hd-stars-${i}`} className="tr_category_star" src={starIco} />);
    }
    return content;
  };

  getTouristsText = (adults, kids) => {
    let result = '';

    if (adults) {
      if (adults == 1) {
        result = '1 взрослый';
      } else {
        result = `${adults} взрослых`;
      }
    }

    if (kids) {
      if (kids == 1) {
        result += ', 1 ребенок';
      } else if (kids > 1) {
        result += `, ${kids} детей`;
      }
    }

    return result;
  };

  getDateText = (priceData) => {
    let result = `с ${priceData.departureDate} на`;

    if (priceData.nightsCount === 1) {
      result = `${result} 1 ночь`;
    } else if (priceData.nightsCount < 5) {
      result = `${result} ${priceData.nightsCount} ночи`;
    } else {
      result = `${result} ${priceData.nightsCount} ночей`;
    }

    return result;
  };

  render() {
    const {
      hotelId, priceData, adults, kids, taRaiting, taReviews,
    } = this.props;

    return (
      <div>
        {this.renderHotel(hotelId, priceData, adults, kids, taRaiting, taReviews)}
      </div>
    );
  }
}

HotelDescription.propTypes = {
  hotelId: PropTypes.string.isRequired,
  priceData: PropTypes.instanceOf(Object).isRequired,
  adults: PropTypes.string.isRequired,
  kids: PropTypes.string.isRequired,
  taRaiting: PropTypes.string.isRequired,
  taReviews: PropTypes.string.isRequired,
};

export default HotelDescription;
