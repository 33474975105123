// eslint-disable-next-line import/prefer-default-export
export const STAR_FILTERS = [
  {
    id: '5stars', name: '5 звезд', value: '404', checked: false,
  },
  {
    id: '4stars', name: '4 звезды', value: '403', checked: false,
  },
  {
    id: '3stars', name: '3 звезды', value: '402', checked: false,
  },
  {
    id: '1-2stars', name: '1-2 звезды', value: '401', checked: false,
  },
];

export const MEAL_FILTERS = [
  {
    id: '112', name: 'FB - Завтрак, обед, ужин', value: '112', checked: false, count: 0, visible: false,
  },
  {
    id: '113', name: 'HB - Завтрак, ужин', value: '113', checked: false, count: 0, visible: false,
  },
  {
    id: '114', name: 'BB - Завтрак', value: '114', checked: false, count: 0, visible: false,
  },
  {
    id: '115', name: 'AI - Всё включено', value: '115', checked: false, count: 0, visible: false,
  },
  {
    id: '116', name: 'UAI - Всё включено', value: '116', checked: false, count: 0, visible: false,
  },
  {
    id: '117', name: 'RO - Без питания', value: '117', checked: false, count: 0, visible: false,
  },
  {
    id: '121', name: 'FB+', value: '121', checked: false, count: 0, visible: false,
  },
  {
    id: '122', name: 'HB+', value: '122', checked: false, count: 0, visible: false,
  },
  {
    id: '129', name: 'SC', value: '129', checked: false, count: 0, visible: false,
  },
];

export const SORT_PRICE = 'price';
export const SORT_POPULARITY = 'popularity';
export const SORT = [SORT_PRICE, SORT_POPULARITY];
export const TOURS_NOT_FOUND = 'По данному запросу туров не найдено';
export const ERROR_HAPPEND = 'Произошла ошибка';
