import React from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import { compareAsc } from 'date-fns';
import { ru } from 'date-fns/locale';
import 'react-day-picker/src/style.css';
import './css/DayPickerComponent.css';
import { connect } from 'react-redux';
import { changeDeparturetDate } from '../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({
  ...state,
});

class DayPickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {};
  }

  handleDayClick(day) {
    const { changeDeparturetDate: changeDeparturetDateFunc, departureDate } = this.props;

    if (departureDate.from && departureDate.to) {
      changeDeparturetDateFunc({ from: day, to: null });
    } else if (departureDate.from && !departureDate.to) {
      if (compareAsc(departureDate.from, day) === 1) {
        changeDeparturetDateFunc({ from: day, to: departureDate.from });
      } else {
        changeDeparturetDateFunc({ from: departureDate.from, to: day });
      }
    }
  }

  render() {
    const { departureDate } = this.props;

    return (
      <DayPicker
        mode="range"
        numberOfMonths={2}
        locale={ru}
        onDayClick={this.handleDayClick}
        disabled={[{ before: new Date() }]}
        selected={departureDate}
      />
    );
  }
}

DayPickerComponent.propTypes = {
  changeDeparturetDate: PropTypes.func.isRequired,
  departureDate: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, { changeDeparturetDate })(DayPickerComponent);
