export const FIRST_NAME = 'ИМЯ';
export const LAST_NAME = 'ФАМИЛИЯ';
export const AFTER_NANE = 'ОТЧЕСТВО';
export const DATE_OF_BIRTH = 'ДАТА РОЖДЕНИЯ';
export const CITIZENSHIP = 'ГРАЖДАНСТВО';
export const PASSPORT = 'СЕРИЯ И НОМЕР ПАСПОРТА';
export const PASSPORT_DATE_START = 'ДАТА ВЫДАЧИ';
export const PASSPORT_DATE_END = 'ДАТА ОКОНЧАНИЯ';
export const PASSPORT_IDENTIFICATION = 'ИДЕНТИФИКАЦИОННЫЙ НОМЕР';
export const EMAIL = 'EMAIL';
export const PHONE = 'ТЕЛЕФОН';
export const FIRST_NAME_PH = 'Иванов';
export const LAST_NAME_PH = 'Иван';
export const AFTER_NAME_PH = 'Иванович';
export const DATE_PH = 'dd/MM/yyyy';
export const PASSPORT_PH = 'MP1234567';
export const RUS_LETTERS_ERROR = 'Используйте только русские символы.';
export const FIRST_NAME_LAT = 'IVANOV';
export const LAST_NAME_LAT = 'IVAN';
export const LAT_LETTERS_ERROR = 'Используйте только латинские символы.';
export const CITIZENSHIP_PH = 'Беларусь';
export const MALE_SEX = 'МУЖ';
export const FEMALE_SEX = 'ЖЕН';
export const SEX = 'ПОЛ';
export const CARD_NUMBER = 'НОМЕР КАРТЫ';
export const CARD_NUMBER_PH = '0000 0000 0000 0000';
export const ONLY_NUMBERS_ERROR = 'Используйте только цифры';
export const CARD_TERM = 'СРОК ДЕЙСТВИЯ';
export const CARD_TERM_MONTH_PH = 'MM';
export const CARD_TERM_YEAR_PH = 'YY';
export const CARD_HOLDER_NAME = 'ИМЯ ВЛАДЕЛЬЦА';
export const CARD_HOLDER_NAME_PH = 'IVAN IVANOV';
export const CARD_CVV = 'CVV/CVC';
export const PRICE_ACTUALIZATION = 'Цена на тур может измениться. Актуализируем данные по туру через';
