/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Order from '../Order/Order';
import { changeUserAuthStatus } from '../../DataStore/actions/searchFormActions';
import './css/Profile.css';

const mapStateToProps = (state) => ({ ...state });

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      email: '',
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const token = window.localStorage.getItem('accessToken');
    const { changeUserAuthStatus: changeUserAuthStatusAction } = this.props;
    axios
      .get('/api/user/data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((userResult) => {
        const { data } = userResult;

        this.setState({ orders: data.orders, email: data.email, isLoggedIn: true });
      }).catch(() => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('email');
        window.localStorage.setItem('isUserLoggedIn', false);
        changeUserAuthStatusAction(false);
      });
  }

  renderOrders = (orders) => orders.map((order, index) => {
    return (
      <div key={`order-${index}`}>
        <Order order={order} />
      </div>
    );
  });

  render() {
    const { orders, email, isLoggedIn } = this.state;

    return (
      <div className="content">
        <div className="container">
          {isLoggedIn ? (
            <div>
              <div className="pr_section_w">
                <div className="pr_section_title">Личная информация</div>
                <div>{`Email: ${email}`}</div>
              </div>

              <div className="pr_section_w">
                <div className="pr_section_title">Заказы</div>
                {(orders && orders.length > 0)
                  ? (
                    <div>{this.renderOrders(orders)}</div>
                  )
                  : <div className="pr_orders_not_found">У вас пока нет заказов</div>}
              </div>
            </div>
          ) : (<div>Необходима авторизация</div>)}
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  changeUserAuthStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { changeUserAuthStatus })(Profile);
