import React from 'react';
import './css/DestinationPopover.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResortsPopup from './ResortsPopup/ResortsPopup';
import SearchResultPopup from './SearchResultPopup/SearchResultPopup';
import { getResortsByCountryId } from '../../../Utils/ElasticUtils';
import { changeSearchResult, changeResortOptions } from '../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

class DestinationPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.windowRef = React.createRef();
  }

  componentDidMount() {
    const {
      destination, changeResortOptions: changeResortOptionsFunc,
      changeSearchResult: changeSearchResultFunc, resortOptions,
    } = this.props;

    if (resortOptions.length === 0) {
      getResortsByCountryId(destination.id)
        .then((res) => {
          const { hits } = res.data;
          if (hits) {
            // eslint-disable-next-line no-underscore-dangle
            const results = hits.hits.map((h) => h._source);
            changeResortOptionsFunc(results);
          }
        });
    }
    changeSearchResultFunc([]);
  }

  render() {
    const { searchResult } = this.props;
    return (
      <div className="destination__popover__wrapper" ref={this.windowRef}>
        <div className="destination__popover">
          <div className="suggestions">
            {searchResult.length === 0
              ? <ResortsPopup popOverRef={this.windowRef} />
              : <SearchResultPopup popOverRef={this.windowRef} />}
          </div>
        </div>
      </div>
    );
  }
}

DestinationPopover.propTypes = {
  searchResult: PropTypes.instanceOf(Array).isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  changeSearchResult: PropTypes.func.isRequired,
  resortOptions: PropTypes.instanceOf(Array).isRequired,
  changeResortOptions: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { changeSearchResult, changeResortOptions },
)(DestinationPopover);
