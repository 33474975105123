export const WHO = 'Кто едет';
export const WHEN = 'Когда';
export const APPLY = 'Применить';
export const WHERE = 'Куда';
export const RESORT = 'Курорт';
export const NIGHTS_QUANTITY = 'Колличество ночей (от - до)';
export const CITIES = 'Города';
export const HOTELS = 'Отели';
export const ALL_RESORTS = 'Все курорты';
export const POPULAR = 'ПОПУЛЯРНЫЕ КУРОРТЫ';
export const OTHER = 'ОСТАЛЬНЫЕ';
export const SELECT = 'Выбрать';
export const SELECTED = 'ВЫБРАННО';
