import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  changeTabletFilterMenuVisibility,
  changeTimeIsUpVisibility,
  changeHotelDetailsFilterMenuVisibility,
} from '../../DataStore/actions/searchFormActions';
import './css/BlackBackground.css';

const mapStateToProps = (state) => ({ ...state });

class BlackBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    const { tabletFilterMenuVisibility, hotelDetailsFilterMenuVisibility } = this.props;

    if (tabletFilterMenuVisibility || hotelDetailsFilterMenuVisibility) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node === e.target) {
      this.close();
    }
  }

  close = () => {
    const {
      changeTabletFilterMenuVisibility: changeTabletFilterMenuVisibilityAction,
      changeTimeIsUpVisibility: changeTimeIsUpVisibilityAction,
      changeHotelDetailsFilterMenuVisibility: changeHotelDetailsFilterMenuVisibilityAction,
    } = this.props;

    changeTabletFilterMenuVisibilityAction(false);
    changeTimeIsUpVisibilityAction(false);
    changeHotelDetailsFilterMenuVisibilityAction(false);

    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  render() {
    const {
      tabletFilterMenuVisibility, timeIsUpModalVisible,
      hotelDetailsFilterMenuVisibility,
    } = this.props;

    if (!tabletFilterMenuVisibility && !timeIsUpModalVisible && !hotelDetailsFilterMenuVisibility) {
      return null;
    }

    // document.body.style.overflow = 'hidden';
    this.handleClick();

    return (
      // eslint-disable-next-line react/self-closing-comp
      <div className="back-background" ref={(node) => { this.node = node; }}>
      </div>
    );
  }
}

BlackBackground.propTypes = {
  changeTabletFilterMenuVisibility: PropTypes.func.isRequired,
  changeTimeIsUpVisibility: PropTypes.func.isRequired,
  tabletFilterMenuVisibility: PropTypes.bool.isRequired,
  timeIsUpModalVisible: PropTypes.bool.isRequired,
  hotelDetailsFilterMenuVisibility: PropTypes.bool.isRequired,
  changeHotelDetailsFilterMenuVisibility: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changeTabletFilterMenuVisibility,
  changeTimeIsUpVisibility,
  changeHotelDetailsFilterMenuVisibility,
})(BlackBackground);
