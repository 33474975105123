/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import airplainForward from '../../images/gray-airplain-forward.svg';
import airplainBack from '../../images/gray-airplain-back.svg';
import whiteCheck from '../../images/white-check.svg';
import './css/FlightRoundTrip.css';

function FlightRoundTrip(props) {
  const {
    priceData, trip, id, selectedRoundTripId, handleSelect, scrollToPayment,
    isSingleRoadTripOption,
  } = props;

  const { toFlight, backFlight } = trip;

  function renderLaggage(palces, weight) {
    const content = [];

    if (weight > 0) {
      for (let i = 0; i < palces; i++) {
        content.push(<div className={`frt_flight_laggage ${weight > 9 ? 'frt_hard_laggage' : ''}`} key={`laggage-${i}`}>{weight}</div>);
      }
    }

    return content;
  }

  function renderBaggage(palces, weight) {
    const content = [];

    if (weight > 0) {
      for (let i = 0; i < palces; i++) {
        content.push(<div className="frt_flight_baggage" key={`baggage-${i}`}>{weight}</div>);
      }
    }

    return content;
  }

  function renderFlights(flights, direction) {
    const content = [];

    for (let i = 0; i < flights.length; i++) {
      const flight = flights[i];

      content.push(
        <div key={`flight-item-${direction}-${i}`}>
          <div className="frt_flight_item_w">
            <div className="frt_flight_airline">{flight.airline ? flight.airline : ''}</div>
            <div>{`Рейс № ${flight.flightId}`}</div>
            <div className="frt_flight_class">{flight.flightClass ? flight.flightClass : ''}</div>
          </div>
          <div className="frt_fligh_baggege_w">
            {flight.luggageIncluded
          && renderLaggage(flight.luggagePlaces, flight.luggageWeight)}
            {flight.baggageIncluded
          && renderBaggage(flight.baggagePlaces, flight.baggageWeight)}
          </div>
        </div>,
      );
    }

    return content;
  }

  return (
    <div className="frt_raund_trip_w">
      <div key={`flight-check-${id}`} className={`frt_flicht_checked ${id === selectedRoundTripId ? '' : 'frt_invisible'}`}>
        <img key={`flight-check-img-${id}`} className="" src={whiteCheck} alt="" />
      </div>
      { toFlight && (
        <div className="frt_fligh_depart_wrapper">
          <div className="frt_fligh_ico_w">
            <img className="frt_fligh_ico" src={airplainForward} alt="" />
          </div>
          <div>
            <div className="frt_fligh_time">{toFlight.startTime ? toFlight.startTime : '--/--'}</div>
            <div className="frt_fligh_info">
              <div>{toFlight.startFormattedDate ? toFlight.startFormattedDate : ''}</div>
              <div>{priceData.departureCity ? priceData.departureCity : ''}</div>
            </div>
          </div>
          <div>
            <div className="frt_flight_perifrt_w">
              <div className="frt_airport">{toFlight.startAirport}</div>
              <div className="frt_flight_period">
                <div className="frt_airport_dot dot_left" />
                <span>{(toFlight.flightDuration && toFlight.flightDuration !== 0) ? toFlight.flightDuration : ''}</span>
                <div className="frt_airport_dot dot_right" />
              </div>
              <div className="frt_airport">{toFlight.finishAirport}</div>
            </div>
            {toFlight.flightChange > 0 && <div className="frt_air_change">{`${toFlight.flightChange} пересадка`}</div>}
          </div>
          <div>
            <div className="frt_fligh_time">{toFlight.finishTime ? toFlight.finishTime : '--/--'}</div>
            <div className="frt_fligh_info">
              <div>{toFlight.finishFormattedDate ? toFlight.finishFormattedDate : ''}</div>
              <div>{priceData.resort ? priceData.resort : ''}</div>
            </div>
          </div>
          <div className="frt_flight_info_w">
            {renderFlights(toFlight.flights, 'toFlight')}
          </div>
        </div>
      )}

      {/* flight back */}
      { backFlight && (
        <div className="frt_fligh_return_wrapper">
          <div className="frt_fligh_ico_w">
            <img className="frt_fligh_ico" src={airplainBack} alt="" />
          </div>
          <div>
            <div className="frt_fligh_time">{backFlight.startTime ? backFlight.startTime : '--/--'}</div>
            <div className="frt_fligh_info">
              <div>{backFlight.startFormattedDate ? backFlight.startFormattedDate : ''}</div>
              <div>{priceData.resort ? priceData.resort : ''}</div>
            </div>
          </div>
          <div>
            <div className="frt_flight_perifrt_w">
              <div className="frt_airport">{backFlight.startAirport}</div>
              <div className="frt_flight_period">
                <div className="frt_airport_dot dot_left" />
                <span>{backFlight.flightDuration ? backFlight.flightDuration : ''}</span>
                <div className="frt_airport_dot dot_right" />
              </div>
              <div className="frt_airport">{backFlight.finishAirport}</div>
            </div>
            {toFlight.flightChange > 0 && <div className="frt_air_change">{`${toFlight.flightChange} пересадка`}</div>}
          </div>
          <div>
            <div className="frt_fligh_time">{backFlight.finishTime ? backFlight.finishTime : '--/--'}</div>
            <div className="frt_fligh_info">
              <div>{backFlight.finishFormattedDate ? backFlight.finishFormattedDate : ''}</div>
              <div>{priceData.departureCity ? priceData.departureCity : ''}</div>
            </div>
          </div>
          <div className="frt_flight_info_w">
            {renderFlights(backFlight.flights, 'backFlight')}
          </div>
        </div>
      )}
      {(!isSingleRoadTripOption && id !== selectedRoundTripId)
         && (
         <button
           type="button"
           className="btn btn_yellow_frame frt_select_flight"
           onClick={() => handleSelect(id)}
         >
           Выбрать перелет
         </button>
         )}

      {(!isSingleRoadTripOption && id === selectedRoundTripId) && (
      <button
        type="button"
        className="btn btn__yellow frt_select_flight frt_order_scroll_btn"
        onClick={() => scrollToPayment()}
      >
        Забронировать
      </button>
      )}

    </div>
  );
}

FlightRoundTrip.propTypes = {
  priceData: PropTypes.instanceOf(Object).isRequired,
  trip: PropTypes.instanceOf(Object).isRequired,
  toFlight: PropTypes.instanceOf(Object),
  backFlight: PropTypes.instanceOf(Object),
  id: PropTypes.node,
  selectedRoundTripId: PropTypes.node,
  handleSelect: PropTypes.func,
  scrollToPayment: PropTypes.func,
  isSingleRoadTripOption: PropTypes.bool,
};

FlightRoundTrip.defaultProps = {
  toFlight: null,
  backFlight: null,
  isSingleRoadTripOption: true,
  scrollToPayment: null,
  handleSelect: null,
  id: 0,
  selectedRoundTripId: 1,
};

export default FlightRoundTrip;
