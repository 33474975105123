import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import About from '../About/About';
import Home from '../Home/Home';
import Profile from '../Profile/Profile';
import LoginModalFunc from '../LoginModal/LoginModalFunc';
import ToursResultFunc from '../ToursResult/ToursResultFunc';
import BlackBackground from '../BlackBackground/BlackBackground';
import HotelDetailsFunc from '../HotelDetails/HotelDetailsFunc';
import OrderDetailsFunc from '../OrderDetails/OrderDetailsFunc';
import CheckoutFunc from '../Checkout/CheckoutFunc';
import AdminPanel from '../AdminPanel/AdminPanel';
import AdminOrderFunc from '../AdminPanel/AdminOrder/AdminOrderFunc';
import Contacts from '../Contacts/Contacts';
import FAQ from '../FAQ/FAQ';
import VerifyFunc from '../Verify/VerifyFunc';
import TuryIzMinska from '../TuryIzMinska/TuryIzMinska';
// import Podbor from '../Podbor/Podbor';
import Turkey from '../Landings/Turkey/Turkey';
import Egypt from '../Landings/Egypt/Egypt';
import Georgia from '../Georgia/Georgia';
import UAE from '../Landings/UAE/UAE';
import NotFound from '../NotFound/NotFound';

class RouterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/ap" element={<AdminPanel />} />
          <Route path="/ap/order" element={<AdminOrderFunc />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/verify" element={<VerifyFunc />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/search" element={<ToursResultFunc />} />
          <Route path="/hotel" element={<HotelDetailsFunc />} />
          <Route path="/order" element={<OrderDetailsFunc />} />
          <Route path="/checkout" element={<CheckoutFunc />} />
          <Route path="/tury-iz-minska" element={<TuryIzMinska />} />
          {/* <Route path="/podbor-tura" element={<Podbor />} /> */}
          <Route path="/turkey" element={<Turkey />} />
          <Route path="/egypt" element={<Egypt />} />
          <Route path="/georgia" element={<Georgia />} />
          <Route path="/uae" element={<UAE />} />
          <Route path="/" element={<Home />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <LoginModalFunc />
        <BlackBackground />
      </Router>
    );
  }
}

export default RouterComponent;
