import React from 'react';
import './css/DestinationInputValue.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeAvailableDays } from '../../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

class DestinationInputValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  calculateInputValue = () => {
    let result;
    const { destination, selectedResorts } = this.props;

    if (selectedResorts.length === 0) {
      result = destination.name;
    } else if (selectedResorts.length === 1) {
      result = `${selectedResorts[0].name}, ${destination.name}`;
    } else if (selectedResorts.length < 5) {
      result = `${selectedResorts.length} курорта, ${destination.name}`;
    } else if (selectedResorts.length >= 5) {
      result = `${selectedResorts.length} курортов, ${destination.name}`;
    }

    return result;
  };

  render() {
    return (
      <div className="destination_input_w">
        {this.calculateInputValue()}
      </div>
    );
  }
}

DestinationInputValue.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, { changeAvailableDays })(DestinationInputValue);
