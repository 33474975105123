export function getCurrentYear() {
  const d = new Date();
  return d.getFullYear();
}

export const getNightWordByQuantity = (nights) => {
  let result;

  if (nights === 1) {
    result = 'ночь';
  } else if (nights <= 4) {
    result = 'ночи';
  } else {
    result = 'ночей';
  }

  return result;
};

export const getMealFullName = (code) => {
  let result = '';

  if (code === 'FB') {
    result = 'Завтрак, обед, ужин';
  } else if (code === 'HB') {
    result = 'Завтрак, ужин';
  } else if (code === 'BB') {
    result = 'Завтрак';
  } else if (code === 'AI') {
    result = 'Всё включено';
  } else if (code === 'UAI') {
    result = 'Всё включено ультра';
  } else if (code === 'RO') {
    result = 'Без питания';
  } else if (code === 'FB+') {
    result = 'Завтрак, обед, ужин ультра';
  } else if (code === 'HB+') {
    result = 'Завтрак, ужин ультра';
  } else if (code === 'SC') {
    result = 'Самообслуживание';
  }

  return result;
};

export const getAdultsDescription = (count) => {
  let result = '';

  if (count === 1) {
    result = 'взрослый';
  } else {
    result = 'взрослых';
  }

  return result;
};

export const getKidsDescription = (count) => {
  let result = '';

  if (count === 1) {
    result = 'ребенок';
  } else if (count <= 4) {
    result = 'ребенка';
  } else {
    result = 'детей';
  }

  return result;
};
