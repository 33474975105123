/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { CONTACTS_TITLE, CONTACTS_DESCRIPTION } from '../../Utils/Constants/SEO';
import './css/Contacts.css';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{CONTACTS_TITLE}</title>
          <meta name="description" content={CONTACTS_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/contacts" />
          <meta property="og:title" content={CONTACTS_TITLE} />
          <meta property="og:description" content={CONTACTS_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/thr_logo.png" />

          <link rel="canonical" href="https://tourhunter.by/contacts" />
        </Helmet>
        <div className="con_intro">
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="con_intro__wave"></div>
        </div>
        <div className="container">
          <div className="cont_wr">
            <p className="cont_info_text">ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ НОВИК НАТАЛЬЯ СТАНИСЛАВОВНА</p>
            <p className="cont_info_text">УНП 692232544</p>
            <p className="cont_info_text">220081, Республика Беларусь, д. Копище, ул. Братьев Райт 7, оф. 92</p>
            <p className="cont_info_text">
              Свидетельство о государственной регистрации №692232544, 
              выдано Минским райисполкомом 23.10.2023 г.
            </p>
            {/* <p className="cont_info_text">
              Интернет-магазин включен в Торговый реестр Республики Беларусь 01.01.2001 за №111111
            </p> */}
            <p className="cont_info_text">+375 (29) 156 99 38</p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
