/* eslint-disable max-len */
import React from 'react';
import './css/Description.css';
import {
  DESC_TITLE, DESC1, DESC2,
} from '../../../Utils/Constants/HomePageConstants';

function Description() {
  return (
    <div className="container">
      <div className="description">
        <div className="blue__title">{DESC_TITLE}</div>
        <div className="description__item__wrapper">
          <div className="description__item">
            <p>{DESC1}</p>
          </div>
          <div className="description__item">
            <p>{DESC2}</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content_block">
          <h1 className="text_h1">Подбор тура по всем туроператорам онлайн: как сделать идеальный выбор</h1>
          <p className="text_p">
            Современные технологии значительно изменили способ планирования путешествий. Сегодня, благодаря нашему сервису, подбор тура стал проще и быстрее, чем когда-либо. С помощью tourhunter.by  вы сможете сравнить предложения от разных туроператоров, выбрать оптимальный вариант и сэкономить время и деньги. В этой статье мы рассмотрим, как правильно подбирать тур, чтобы ваш отдых стал незабываемым.
          </p>
        </div>
        <div className="content_block">
          <h2 className="text_h2">Зачем использовать онлайн-сервисы для подбора тура?</h2>
          <p className="text_p">Онлайн-сервис tourhunter.by агрегируетт информацию о турах от множества туроператоров, что позволяет вам увидеть разнообразие предложений — от бюджетных до элитных вариантов.</p>
          <p className="text_p">Визуально сравнив цену на один и тот же тур у разных операторов, вы с легкостью найдете лучшее предложение и потенциально сэкономите значительную сумму.</p>
          <p className="text_p">У Вас есть возможность планировать свой отпуск в любое время и в любом месте, не выходя из дома! Более того, можно легко проверять наличие мест и условия проживания.</p>
          <p className="text_p">Изучайте рейтинги и отзывы на отели на TripAdvisor и Booking.</p>
          <p className="text_p">Если вы можете немного изменить даты своего путешествия, это часто позволяет найти более выгодные предложения.</p>
          <p className="text_p">Раннее бронирование дает возможность получить больший выбор отелей и  лучшие цены. Не забывайте о страховании от невылета - отличный способ обезопасить себя в случае непредвиденных ситуаций. Мы всегда рекомендуем туристам ее оформлять.</p>
          <p className="text_p">Таким образом, сравнивая цены, читая отзывы и изучая предложения, вы сможете сделать осознанный выбор и насладиться долгожданным путешествием. Не упустите возможность воспользоваться нашим сервисом, чтобы ваш отпуск стал поистине незабываемым!</p>
        </div>
      </div>
    </div>
  );
}

export default Description;
