import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import Intro from './Intro/Intro';
import Operators from './Operators/Operators';
import PopularDirections from './PopularDirections/PopularDirections';
import Advantages from './Advantages/Advantages';
import Description from './Description/Description';
import { HOME_PAGE_TITLE, HOME_PAGE_META_DESCRIPTION } from '../../Utils/Constants/SEO';
import './css/Home.css';

function Home() {
  return (
    <div className="content">
      <Helmet>
        <title>{HOME_PAGE_TITLE}</title>
        <meta name="description" content={HOME_PAGE_META_DESCRIPTION} />

        <meta property="og:site_name" content="TourHunter.by" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://tourhunter.by/" />
        <meta property="og:title" content={HOME_PAGE_TITLE} />
        <meta property="og:description" content={HOME_PAGE_META_DESCRIPTION} />
        <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" />

        <link rel="canonical" href="https://tourhunter.by/" />
      </Helmet>
      <Intro />
      <Operators />
      <PopularDirections />
      <Advantages />
      <Description />
    </div>
  );
}

export default Home;
