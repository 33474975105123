/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateInput } from 'rsuite';
import './css/Input.css';

function Input(props) {
  const [error, setError] = useState();
  const [lastCurdNumberLength, setLastCurdNumberLength] = useState();
  const [isChanged, setIsChanged] = useState(false);

  const {
    id, label, value, handleValueChange, validation, name, placeholder,
    disabled, type, required, validationError, maxLength, isValid,
    isValidChange,
  } = props;

  function handleDateChange(date) {
    if (date && !isNaN(date)) {
      handleValueChange(name, date.toLocaleDateString('en-GB'), id);
    } else {
      handleValueChange(name, '', id);
    }

    setError('');
  }

  function handleInputChange(e) {
    const { name: targetName, id: targetId } = e.target;
    let { value: targetValue } = e.target;

    if (validation
      && (name === 'holder' || name === 'lastNameUpper' || name === 'firstNameUpper' || name === 'passport' || name === 'identity')) {
      if (validation(targetValue)) {
        targetValue = targetValue.toUpperCase();
        e.target.value = targetValue;

        handleValueChange(targetName, targetValue, id);
        setError('');
      } else {
        setError(validationError);
      }
    } else if (validation && name === 'number') {
      if (validation(targetValue)) {
        if ((targetValue.length === 5 || targetValue.length === 10 || targetValue.length === 15)
        && targetValue.length > lastCurdNumberLength) {
          targetValue = `${targetValue.substring(0, targetValue.length - 1)} ${targetValue.substring(targetValue.length - 1)}`;
          e.target.value = targetValue;
        }

        setLastCurdNumberLength(targetValue.length);
        handleValueChange(targetName, targetValue, targetId);
        setError('');
      } else {
        setError(validationError);
      }
    } else if (validation && type === 'text') {
      if (validation(targetValue)) {
        handleValueChange(targetName, targetValue, targetId);
        setError('');
      } else {
        setError(validationError);
      }
    } else {
      setError('');
      handleValueChange(targetName, targetValue, targetId);
    }
  }

  function blurHandler() {
    if (isValidChange && required && value.length === 0) {
      setIsChanged(true);
      isValidChange(name, false);
      setError('Поле обязательно');
    }
  }

  return (
    <div className="input_wrapper">
      {label !== '' && (
        <div>
          {label}
        &nbsp;
          {required && <span className="input_required">*</span>}
        </div>
      )}

      {type === 'date' ? (
        <DateInput
          format="dd/MM/yyyy"
          onChange={handleDateChange}
          className={`form_input ${isChanged ? (isValid ? '' : 'input_invalid_input') : ''}`}
          placeholder={placeholder}
          onBlur={blurHandler}
        />
      ) : (
        <input
          id={id}
          name={name}
          value={value}
          // eslint-disable-next-line no-nested-ternary
          className={`form_input ${isChanged ? (isValid ? '' : 'input_invalid_input') : ''}`}
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          onBlur={blurHandler}
        />
      )}
      <div className="input_form_error">{error}</div>
    </div>
  );
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleValueChange: PropTypes.func,
  id: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.func,
  validationError: PropTypes.string,
  maxLength: PropTypes.number,
  isValidChange: PropTypes.func,
  isValid: PropTypes.bool,
};

Input.defaultProps = {
  required: false,
  disabled: false,
  name: '',
  handleValueChange: null,
  id: 0,
  placeholder: '',
  type: 'text',
  validation: null,
  validationError: '',
  maxLength: 50,
  label: '',
  isValidChange: null,
  isValid: true,
};

export default Input;
