/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({ ...state });

class DayPickerInputValue extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getDateText = (date) => {
    let result;

    if (date.from && date.to) {
      if (date.from.getMonth() === date.to.getMonth()) {
        if (date.from.getDate() === date.to.getDate()) {
          result = `${date.from.getDate()} ${this.getMonth(date.from)} ${date.from.getFullYear()}`;
        } else {
          result = `${date.from.getDate()} - ${date.to.getDate()} ${this.getMonth(date.from)} ${date.to.getFullYear()}`;
        }
      } else {
        result = `${date.from.getDate()} ${this.getMonth(date.from)} - ${date.to.getDate()} ${this.getMonth(date.to)} ${date.to.getFullYear()}`;
      }
    } else if (date.from && !date.to) {
      result = `с ${date.from.getDate()} ${this.getMonth(date.from)} -`;
    }

    return result;
  };

  getMonth = (date) => {
    let mounth;
    switch (date.getMonth()) {
      case 0:
        mounth = 'янв.';
        break;
      case 1:
        mounth = 'фев.';
        break;
      case 2:
        mounth = 'март';
        break;
      case 3:
        mounth = 'апр.';
        break;
      case 4:
        mounth = 'май';
        break;
      case 5:
        mounth = 'июн.';
        break;
      case 6:
        mounth = 'июл.';
        break;
      case 7:
        mounth = 'авг.';
        break;
      case 8:
        mounth = 'сен.';
        break;
      case 9:
        mounth = 'окт.';
        break;
      case 10:
        mounth = 'ноя.';
        break;
      case 11:
        mounth = 'дек.';
        break;

      default:
        break;
    }

    return mounth;
  };

  render() {
    const { departureDate } = this.props;
    return (
      <div>
        {this.getDateText(departureDate)}
      </div>
    );
  }
}

DayPickerInputValue.propTypes = {
  departureDate: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, null)(DayPickerInputValue);
