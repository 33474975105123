import React from 'react';
import PropTypes from 'prop-types';
import starIco from '../../images/star.svg';
import { getTouristsText, getDateAndNightsText } from '../../Utils/CommonUtils';
import {
  ORDER_WAIT_PAYMENT_STATUS, ORDER_WAIT_PAIMENT, ORDER_WAIT_APPROVAL_STATUS, ORDER_WAIT_APPROVAL,
  ORDER_APPROVED_STATUS, ORDER_APPROVED, ORDER_DECLINED_STATUS, ORDER_DECLINED,
} from '../../Utils/Constants/GlobalConstants';
import './css/Order.css';

function Order(props) {
  const { order } = props;

  function renderHotelCategory(starsCount) {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < starsCount; i++) {
      content.push(<img alt="" key={`or-stars-${i}`} className="tr_category_star" src={starIco} />);
    }
    return content;
  }

  function renderAirTrip(items) {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      content.push(<div key={`or-air-${i}`} className="tr_category_star">{items[i]}</div>);
    }
    return content;
  }

  return (
    <div>
      <div className="ord_wrapper">
        <div className="ord_tour_desc_w">
          <div className="ord_status_w">
            {order && order.status === ORDER_WAIT_PAYMENT_STATUS
             && <div className="ord_status ord_status_negative">{ORDER_WAIT_PAIMENT}</div>}

            {order && order.status === ORDER_WAIT_APPROVAL_STATUS
            && <div className="ord_status ord_status_in_process">{ORDER_WAIT_APPROVAL}</div>}

            {order && order.status === ORDER_APPROVED_STATUS
            && <div className="ord_status ord_status_approved">{ORDER_APPROVED}</div>}

            {order && order.status === ORDER_DECLINED_STATUS
            && <div className="ord_status ord_status_negative">{ORDER_DECLINED}</div>}
          </div>
          <img className="ord_hotel_img" src={`/i/im/${order.hotelId}_0_600_400_0.jpg`} alt="" />
          <div className="ord_tour_desc">
            <div className="ord_tour_order_num">{`Заказ №${order.externalId}`}</div>
            <div className="ord_tour_desc_line1_w">
              <div>
                <div>{renderHotelCategory(order.hotelCategory)}</div>
                <h3 className="ord_hotel_name">{order.hotelName}</h3>
                <div className="ord_location">
                  {`${order.resort}, ${order.countryTo}`}
                </div>
                <div className="ord_tour_desc_oprator">
                  {order.operator}
                </div>
              </div>
            </div>
            <div>
              <div className="ord_details">
                <div className="ord_details_title">Номер</div>
                <div>
                  { order.roomType ? order.roomType : ''}
                      &nbsp;
                  {`(${order.hotelPlaceName ? order.hotelPlaceName : ''}) - ${order.settlementType}`}
                </div>
                <div className="ord_details_desc">{order.hotelPlaceDesc ? order.hotelPlaceDesc : ''}</div>
              </div>
              <div className="ord_details">
                <div className="ord_details_title">Питание</div>
                <div>{order.meal ? order.meal : ''}</div>
              </div>
              <div className="ord_details">
                <div className="ord_details_title">Кто едет</div>
                <div>{getTouristsText(order.adultsCount, order.kidsCount)}</div>
                <div>{getDateAndNightsText(order)}</div>
              </div>
              <div className="ord_details">
                <div className="ord_details_title">Авиаперелет</div>
                {order.toTrip
                  && <div>{renderAirTrip(order.toTrip)}</div> }
                {order.backTrip
                  && <div>{renderAirTrip(order.backTrip)}</div>}
              </div>
              <div className="ord_details">
                <div className="ord_details_title">Стоимость</div>
                <div className="ord_details_price_orig">{`${order.originalPrice} ${order.currency}`}</div>
                <div className="ord_details_price_byn">{`${order.totalPriceByn} BYN`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Order.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

export default Order;
