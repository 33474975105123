import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import ToursResult from './ToursResult';

function ToursResultFunc(props) {
  const { countryFilter, text } = props;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ToursResult
      countryFilter={countryFilter}
      location={location}
      navigate={navigate}
      text={text}
    />
  );
}

ToursResultFunc.propTypes = {
  countryFilter: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object,
};

ToursResultFunc.defaultProps = {
  countryFilter: '',
  text: null,
};

export default ToursResultFunc;
