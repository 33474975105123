// eslint-disable-next-line import/prefer-default-export
export const CITY_OPTIONS = [
  {
    id: 1308,
    name: 'Минск',
  },
  {
    id: 1321,
    name: 'Брест',
  },
  // {
  //   id: 1368,
  //   name: 'Гродно',
  // },
  {
    id: 1367,
    name: 'Могилев',
  },
  {
    id: 1364,
    name: 'Витебск',
  },
  // {
  //   id: 1366,
  //   name: 'Гомель',
  // },
];
