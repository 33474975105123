import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchForm from './SearchForm';

function SearchFormFunc(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isDisabled, page, search, hotel,
  } = props;

  return (
    <SearchForm
      location={location}
      navigate={navigate}
      page={page}
      search={search}
      isDisabled={isDisabled}
      hotel={hotel}
    />
  );
}

SearchFormFunc.propTypes = {
  isDisabled: PropTypes.bool,
  page: PropTypes.string.isRequired,
  search: PropTypes.func,
  hotel: PropTypes.instanceOf(Object),
};

SearchFormFunc.defaultProps = {
  isDisabled: false,
  search: null,
  hotel: null,
};

export default SearchFormFunc;
