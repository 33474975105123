/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import './css/Popover.css';
import TouristsPopover from '../TouristsPopover/TouristsPopover';
import DestinationPopover from '../DestinationPopover/DestinationPopover';
import DayPickerComponent from '../DayPickerComponent/DayPickerComponent';
import NightsPopover from '../NightsPopover/NightsPopover';

class Popover extends React.Component {
  selectPopover = (id) => {
    switch (id) {
      case 'TOURISTS':
        return <TouristsPopover />;
      case 'NIGHTS':
        return <NightsPopover />;
      case 'DATE':
        return <DayPickerComponent />;
      case 'COUNTRYTO':
        return <DestinationPopover />;
      default:
        return null;
    }
  };

  render() {
    const { id } = this.props;
    const popover = this.selectPopover(id);
    return (
      <div className={`popover ${id === 'TOURISTS' ? 'tourists_popover' : ''}`}>
        {popover}
      </div>
    );
  }
}

Popover.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Popover;
