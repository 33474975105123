import { createStore } from 'redux';
import { addDays } from 'date-fns';
import searchFormReducer from './reducers/searchFormReducer';

function configureStore(state = {
  adults: 2,
  kids: [],
  depatureCity: { id: 1308, name: 'Минск' },
  departureDate: { from: addDays(new Date(), 14), to: addDays(new Date(), 16) },
  destination: {
    id: 119,
    countryId: 119,
    name: 'Турция',
    originalName: 'Turkey',
    type: 'country',
    rank: 0,
  },
  charterOnly: true,
  searchInputValue: '',
  selectedResorts: [],
  resortOptions: [],
  isPopupVisible: false,
  nightsFrom: 7,
  nightsTo: 9,
  searchResult: [],
  isLoginModalVisible: false,
  isUserLoggedIn: window.localStorage.getItem('isUserLoggedIn') === 'true',
  userName: {},
  availableDays: [],
  tabletFilterMenuVisibility: false,
  timeIsUpModalVisible: false,
  hotelDetailsFilterMenuVisibility: false,
}) {
  return createStore(searchFormReducer, state);
}

export default configureStore;
