/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Bars } from 'react-loader-spinner';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { getTourPrice } from '../../Utils/RestUtils';
import TourDescription from '../TourDescription/TourDescription';
import FlightRoundTrip from '../FlightRoundTrip/FlightRoundTrip';
import { ORDER_DETAILS_TITLE } from '../../Utils/Constants/SEO';
import './css/OrderDetails.css';

import { INCORRECT_EMAIL_FORMAT } from '../../Utils/Constants/GlobalConstants';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      priceData: {},
      phoneValue: '',
      isPhoneValid: false,
      emailValue: '',
      isEmailValid: false,
      emailWasFocused: false,
      phoneWasFocused: false,
      emailErrorMessage: '',
      phoneErrorMessage: '',
      tourPricePayload: {},
      hotelId: '',
      adults: '',
      kids: '',
      taRaiting: '',
      taReviews: '',
      dataValid: true,
      selectedRoundTripId: '',
      isSingleRoadTripOption: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    const queryParameters = new URLSearchParams(location.search);

    let requestId;
    if (queryParameters.has('request') && queryParameters.get('request') !== '') {
      if (!isNaN(queryParameters.get('request'))) {
        requestId = queryParameters.get('request');
      }
    }

    let offerId;
    if (queryParameters.has('offer') && queryParameters.get('offer') !== '') {
      if (!isNaN(queryParameters.get('offer'))) {
        offerId = queryParameters.get('offer');
      }
    }

    let sourceId;
    if (queryParameters.has('operator') && queryParameters.get('operator') !== '') {
      if (!isNaN(queryParameters.get('operator'))) {
        sourceId = queryParameters.get('operator');
      }
    }

    let countryId;
    if (queryParameters.has('country') && queryParameters.get('country') !== '') {
      if (!isNaN(queryParameters.get('country'))) {
        countryId = queryParameters.get('country');
      }
    }

    let hotelIdPayload;
    if (queryParameters.has('hotelId') && queryParameters.get('hotelId') !== '') {
      if (!isNaN(queryParameters.get('hotelId'))) {
        hotelIdPayload = queryParameters.get('hotelId');
        this.setState({ hotelId: hotelIdPayload });
      }
    }

    if (queryParameters.has('adults') && queryParameters.get('adults') !== '') {
      if (!isNaN(queryParameters.get('adults'))) {
        const adultsCount = queryParameters.get('adults');
        this.setState({ adults: adultsCount });
      }
    }

    if (queryParameters.has('kids') && queryParameters.get('kids') !== '') {
      if (!isNaN(queryParameters.get('kids'))) {
        const kids = queryParameters.get('kids');
        this.setState({ kids });
      }
    }

    if (queryParameters.has('taReviews') && queryParameters.get('taReviews') !== '') {
      if (!isNaN(queryParameters.get('taReviews'))) {
        const taReviews = queryParameters.get('taReviews');
        this.setState({ taReviews });
      }
    }

    if (queryParameters.has('taRaiting') && queryParameters.get('taRaiting') !== '') {
      if (!isNaN(queryParameters.get('taRaiting'))) {
        const taRaiting = queryParameters.get('taRaiting');
        this.setState({ taRaiting });
      }
    }

    const payload = {
      sourceId,
      offerId,
      countryId,
      requestId,
    };

    this.setState({ tourPricePayload: payload });

    getTourPrice(payload).then((result) => {
      this.setState({
        priceData: result.data,
        dataValid: result.data.dataValid,
      });

      if (result.data.roundTrips && result.data.roundTrips.length > 0) {
        this.setState({
          selectedRoundTripId: 0,
          selectedRoundTrip: result.data.roundTrips[0],
        });
      }

      if (result.data.roundTrips && result.data.roundTrips.length === 1) {
        this.setState({
          isSingleRoadTripOption: true,
        });
      }
    }).finally(() => {
      this.setState({ isDisabled: false });
    });
  }

  handleEmailChange = (e) => {
    const emailValue = e.target.value;
    this.setState({ emailValue });

    if (this.emailValidation(emailValue, false)) {
      this.setState({ isEmailValid: true });
    } else {
      this.setState({ isEmailValid: false });
    }
  };

  emailValidation = (emailValue, blur) => {
    const { emailWasFocused } = this.state;
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailValue || regex.test(emailValue) === false) {
      if (blur || emailWasFocused) {
        this.setState({ emailErrorMessage: INCORRECT_EMAIL_FORMAT });
      }
      return false;
    }

    this.setState({ emailErrorMessage: '' });
    return true;
  };

  handleEmailInputBlur = () => {
    const { emailValue } = this.state;
    if (emailValue.length > 0) {
      if (this.emailValidation(emailValue, true)) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
      this.setState({ emailWasFocused: true });
    }
  };

  handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    this.setState({ phoneValue });

    if (this.phoneValidation(phoneValue, false)) {
      this.setState({ isPhoneValid: true });
    } else {
      this.setState({ isPhoneValid: false });
    }
  };

  phoneValidation = (phoneValue, blur) => {
    const { phoneWasFocused } = this.state;
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\+]?[0-9]{12}$/i;
    if (!phoneValue || regex.test(phoneValue) === false) {
      if (phoneWasFocused || blur) {
        this.setState({ phoneErrorMessage: 'Неверный формат. Пример: +375291112233' });
      }
      return false;
    }

    this.setState({ phoneErrorMessage: '' });
    return true;
  };

  handlePhoneInputBlur = () => {
    const { phoneValue } = this.state;
    if (phoneValue.length > 0) {
      if (this.phoneValidation(phoneValue, true)) {
        this.setState({ isPhoneValid: true });
      } else {
        this.setState({ isPhoneValid: false });
      }
      this.setState({ phoneWasFocused: true });
    }
  };

  handleCheckoutClick = () => {
    const { navigate } = this.props;
    const {
      emailValue, phoneValue, tourPricePayload, selectedRoundTrip, priceData, hotelId,
      adults, kids,
    } = this.state;

    navigate('/checkout', {
      state: {
        adults,
        kids,
        hotelId,
        priceData,
        email: emailValue,
        phone: phoneValue,
        tourPricePayload,
        selectedRoundTrip,
      },
    });
  };

  selectRoundTrip = (id) => {
    const { priceData } = this.state;
    this.setState({
      selectedRoundTripId: id,
      selectedRoundTrip: priceData.roundTrips[id],
    });
  };

  // eslint-disable-next-line arrow-body-style
  renderRoundTrips = (trips, priceData) => trips.map((trip, index) => {
    // eslint-disable-next-line react/no-array-index-key
    const { selectedRoundTripId, isSingleRoadTripOption } = this.state;
    return (
      <div key={`round-trip-w${index}`} className="od_flight_wrapper">
        <FlightRoundTrip
          id={index}
          selectedRoundTripId={selectedRoundTripId}
          trip={trip}
          priceData={priceData}
          handleSelect={this.selectRoundTrip}
          scrollToPayment={this.scrollToPayment}
          isSingleRoadTripOption={isSingleRoadTripOption}
        />
      </div>
    );
  });

  scrollToPayment = () => {
    this.paymentRef.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const {
      priceData, isPhoneValid, phoneErrorMessage, emailErrorMessage,
      emailValue, isEmailValid, emailWasFocused, phoneValue, isDisabled,
      hotelId, adults, kids, taRaiting, taReviews, dataValid,
    } = this.state;

    return (
      <div className="content">
        <Helmet>
          <title>{`${ORDER_DETAILS_TITLE} ${priceData.hotelName ? priceData.hotelName : ''}`}</title>
        </Helmet>
        <div className="container">
          {(dataValid)
            ? (
              <div className={isDisabled ? 'invisible' : ''}>
                {(priceData || hotelId)
              && (
              <TourDescription
                hotelId={hotelId}
                priceData={priceData}
                adults={adults}
                kids={kids}
                taRaiting={taRaiting}
                taReviews={taReviews}
              />
              )}
                {(priceData && priceData.roundTrips && priceData.roundTrips.length > 0)
                  ? this.renderRoundTrips(priceData.roundTrips, priceData)
                  : (
                    <div className="od_flight_wrapper">
                      <div className="od_flight_not_found">
                        На данный момент расписания перелета нет
                      </div>
                    </div>
                  )}

                <div className="od_payment_wrapper" ref={(el) => { this.paymentRef = el; }}>
                  <div className="od_section_title">К оплате</div>
                  <div className="od_tour_price_total">
                    {`${priceData.originalPrice} ${priceData.currency}`}
                  </div>
                  <div className="od_tour_price_total_byn">
                    {`${priceData.totalPriceByn} BYN`}
                  </div>
                  <div className="od_contact_info">
                    <div className="od_input_wrapper">
                      <div>Ваш email</div>
                      <input
                        value={emailValue}
                        className={`od_form_input ${!isEmailValid && emailWasFocused ? 'od_invalid_input' : ''}`}
                        onChange={this.handleEmailChange}
                        placeholder="example@gmail.com"
                        onBlur={this.handleEmailInputBlur}
                      />
                    </div>
                    <div className="od_input_wrapper">
                      <div>Телефон для связи</div>
                      <input
                        value={phoneValue}
                        className="od_form_input"
                        onChange={this.handlePhoneChange}
                        placeholder="+375291122333"
                        onBlur={this.handlePhoneInputBlur}
                        maxLength={13}
                      />
                    </div>
                    <button
                      type="submit"
                      className={`btn btn__yellow od_ckeckout_btn ${isPhoneValid && isEmailValid ? '' : 'btn_dis'}`}
                      disabled={!isPhoneValid && !isEmailValid}
                      onClick={() => this.handleCheckoutClick()}
                    >
                      Перейти к бронированию
                    </button>
                  </div>
                  <div className="od_login_form_error">{ emailErrorMessage }</div>
                  <div className="od_login_form_error">{ phoneErrorMessage }</div>
                  {/* <p className="od_warning">Бронирование временно не доступно.</p> */}
                </div>
              </div>
            )
            : (
              <div className="od_info_outdated">
                Информация о туре устарела.
              </div>
            )}

          <div className={`od_spinner_wrapper ${isDisabled ? '' : 'od_invisible'}`}>
            <Bars
              height="40"
              width="40"
              color="#00BFFF"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

OrderDetails.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default OrderDetails;
