/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { exchangeRateValidation } from '../../Utils/ValidationUtil';
import './css/Form.css';

function Form(props) {
  const {
    id, inputValue, currency, submit,
  } = props;
  const [inputStateValue, setInputStateValue] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    if (exchangeRateValidation(value)) {
      setInputStateValue(value);
    }
  };

  async function handleSubmit() {
    const isSuccess = await submit(id, currency, inputStateValue);
    if (isSuccess) {
      setSuccess('Успешно обновлено');
      setError('');
    } else {
      setSuccess('');
      setError('Ошибка!');
    }
  }

  useEffect(() => {
    setInputStateValue(inputValue);
  }, []);

  return (
    <div className="ap_form_rate_w">
      <div className="ap_form_line_w">
        <div className="ap_form_curr">{currency}</div>
        <input
          className="ap_input"
          id={id}
          type="text"
          value={inputStateValue}
          onChange={handleChange}
          maxLength={4}
        />
        <button type="button" onClick={handleSubmit} className="btn btn__yellow">Обновить</button>
      </div>
      <div className="ap_form_error">{error}</div>
      <div className="ap_form_success">{success}</div>
    </div>
  );
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,

  submit: PropTypes.func.isRequired,
};

export default Form;
