import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './css/SearchForm.css';
import CityFrom from './CityFrom/CityFrom';
import Input from './Input/Input';
import {
  COUNTRY_TO_LABEL, DATE_LABEL, NIGHTS_LABEL, TOURISTS_LABEL,
  TOURISTS, NIGHTS, DATE, COUNTRYTO, SEARCH, TOUR, HOTEL, HOTEL_LABEL,
} from '../../Utils/Constants/HomePageConstants';
import {
  HOME_PAGE, TOUR_SEARCH_RESULT, HOTEL_DETAILS, TURKEY_COUNTRY_ID,
} from '../../Utils/Constants/GlobalConstants';
import { SORT_PRICE } from '../../Utils/Constants/ToursResultConstants';
import PinIco from '../../images/search-form-pin.svg';
import DateIco from '../../images/search-form-date.svg';
import NightIco from '../../images/search-form-nights-ico.svg';
import TouristsIco from '../../images/search-form-tourists-ico.svg';
import { changeCharterOnly } from '../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = () => {
    const searchUrl = this.buildSearchUrl();
    const { navigate, page, search } = this.props;

    if (page === HOME_PAGE) {
      navigate(`/search?${searchUrl}`, { state: { location: HOME_PAGE } });
    } else if (page === TOUR_SEARCH_RESULT && search) {
      search();
      navigate(`/search?${searchUrl}`, { state: { location: TOUR_SEARCH_RESULT } });
    } else if (page === HOTEL_DETAILS) {
      search();
      navigate(`/hotel?${searchUrl}`, { state: { location: HOTEL_DETAILS } });
    }
  };

  buildSearchUrl = () => {
    const {
      destination, depatureCity, selectedResorts, departureDate, nightsFrom,
      nightsTo, adults, kids, hotel, page, charterOnly, changeCharterOnly: changeCharterOnlyAction,
    } = this.props;

    const queryParameters = new URLSearchParams();

    queryParameters.append('from', depatureCity.id);
    queryParameters.append('to', destination.id);
    queryParameters.append('cities', selectedResorts.map((item) => item.id));
    queryParameters.append('departureFrom', departureDate.from ? departureDate.from.toLocaleDateString('en-GB') : '');
    queryParameters.append('departureTo', departureDate.to ? departureDate.to.toLocaleDateString('en-GB') : departureDate.from.toLocaleDateString('en-GB'));
    queryParameters.append('nightsFrom', nightsFrom);
    queryParameters.append('nightsTo', nightsTo);
    queryParameters.append('adults', adults);
    queryParameters.append('kids', kids.map((kid) => kid.age));
    queryParameters.append('sort', SORT_PRICE);

    if (hotel && page === HOTEL_DETAILS) {
      queryParameters.append('hotels', hotel.hotelId);
    }

    if (page === HOME_PAGE) {
      if (destination.id === TURKEY_COUNTRY_ID) {
        queryParameters.append('charter', true);
        changeCharterOnlyAction(true);
      } else {
        queryParameters.append('charter', false);
        changeCharterOnlyAction(false);
      }
    } else {
      queryParameters.append('charter', charterOnly);
    }

    return queryParameters.toString();
  };

  render() {
    const { isDisabled, page, hotel } = this.props;
    return (
      <div className="search__form">
        <CityFrom />
        <div className="sf__input__wrapper">
          {page === HOTEL_DETAILS
            ? <Input label={HOTEL_LABEL} ico={PinIco} id={HOTEL} value={hotel.hotelName} />
            : <Input label={COUNTRY_TO_LABEL} ico={PinIco} id={COUNTRYTO} />}
          <Input label={DATE_LABEL} ico={DateIco} id={DATE} />
          <Input label={NIGHTS_LABEL} ico={NightIco} id={NIGHTS} />
          <Input label={TOURISTS_LABEL} ico={TouristsIco} id={TOURISTS} />
          <button type="button" onClick={() => this.handleSubmit()} className="sf_submit" disabled={isDisabled}>
            {SEARCH}
            <span className="input_value_diss_part">
              &nbsp;
              {TOUR}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

SearchForm.propTypes = {
  adults: PropTypes.number.isRequired,
  kids: PropTypes.instanceOf(Array).isRequired,
  nightsFrom: PropTypes.number.isRequired,
  nightsTo: PropTypes.number.isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  departureDate: PropTypes.instanceOf(Object).isRequired,
  depatureCity: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  navigate: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  page: PropTypes.string.isRequired,
  search: PropTypes.func,
  hotel: PropTypes.instanceOf(Object),
  charterOnly: PropTypes.bool.isRequired,
  changeCharterOnly: PropTypes.func.isRequired,
};

SearchForm.defaultProps = {
  isDisabled: false,
  search: null,
  hotel: [],
};

export default connect(mapStateToProps, { changeCharterOnly })(SearchForm);
