import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OrderDetails from './OrderDetails';

function OrderDetailsDetailsFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <OrderDetails location={location} navigate={navigate} />
  );
}

export default OrderDetailsDetailsFunc;
