import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LOGIN_TITLE, EMAIL_ADDRESS_LABEL, PASSWORD_LABEL, ENTER,
  NO_ACCOUNT, START_REG, INCORRECT_EMAIL_FORMAT, BAD_CREDENTIALS,
} from '../../../Utils/Constants/GlobalConstants';
import { changeUserAuthStatus } from '../../../DataStore/actions/searchFormActions';

const mapStateToProps = (state) => ({ ...state });

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      passwordValue: '',
      errorMessage: '',
      emailWasFocused: false,
      isEmailValid: false,
      isPasswordValid: false,
    };
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ passwordValue: password }, () => {
      this.passwordValidator();
    });
  };

  handleEmailChange = (e) => {
    const { emailWasFocused } = this.state;
    const emailValue = e.target.value;
    this.setState({ emailValue });

    if (emailWasFocused) {
      if (this.emailValidation()) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
    }
  };

  passwordValidator = () => {
    const { passwordValue } = this.state;
    if (passwordValue.length >= 8) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };

  emailValidation = () => {
    const { emailValue } = this.state;
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailValue || regex.test(emailValue) === false) {
      this.setState({ errorMessage: INCORRECT_EMAIL_FORMAT });
      return false;
    }

    this.setState({ errorMessage: '' });
    return true;
  };

  handleEmailInputBlur = () => {
    const { emailValue } = this.state;
    if (emailValue.length > 0) {
      if (this.emailValidation()) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
      this.setState({ emailWasFocused: true });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { passwordValue, emailValue } = this.state;
    const { closeModal, changeUserAuthStatus: changeUserAuthStatusAction, location } = this.props;
    axios
      .post(
        '/api/auth/signin',
        {
          email: emailValue,
          password: passwordValue,
        },
      )
      .then((response) => {
      // eslint-disable-next-line no-underscore-dangle
        window.localStorage.setItem('accessToken', response.data.accessToken);
        window.localStorage.setItem('email', response.data.email);
        window.localStorage.setItem('isUserLoggedIn', true);

        changeUserAuthStatusAction(true);
        closeModal();

        if (location.pathname === '/profile') {
          window.location.reload();
        }
      }).catch((error) => {
        if (error.response) {
          this.setState({ errorMessage: BAD_CREDENTIALS });
        }
      });
  };

  render() {
    const {
      emailValue, passwordValue,
      isEmailValid, emailWasFocused, isPasswordValid, errorMessage,
    } = this.state;
    const { showRegForm } = this.props;
    return (
      <div>
        <div>
          <span className="loginModalTitle">{LOGIN_TITLE}</span>
        </div>
        <div className="login_form">
          <div className="modalInputWrapper">
            <div>{EMAIL_ADDRESS_LABEL}</div>
            <input
              value={emailValue}
              className={`login_form_inp form_input ${!isEmailValid && emailWasFocused ? 'invalidInput' : ''}`}
              onChange={this.handleEmailChange}
              placeholder="example@gmail.com"
              onBlur={this.handleEmailInputBlur}
            />
          </div>
          <div className="modalInputWrapper">
            <div>{PASSWORD_LABEL}</div>
            <input
              value={passwordValue}
              className={`login_form_inp form_input ${isEmailValid ? '' : ''}`}
              onChange={this.handlePasswordChange}
              type="password"
            />
          </div>
          <button
            type="submit"
            className={`btn btn__yellow login_btn ${isPasswordValid && isEmailValid ? '' : 'btn_dis'}`}
            disabled={!isPasswordValid && !isEmailValid}
            onClick={this.handleSubmit}
          >
            {ENTER}
          </button>
          <div className="showReg_wparrer">
            <span>
              {NO_ACCOUNT}
              &nbsp;
            </span>
            <button type="submit" className="showReg" onClick={showRegForm}>{START_REG}</button>
          </div>
          <div className="showReg_wparrer loginFormError">{ errorMessage }</div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  showRegForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  changeUserAuthStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, { changeUserAuthStatus })(LoginForm);
