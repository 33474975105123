import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { changeUserAuthStatus } from '../../../DataStore/actions/searchFormActions';
import './css/UserMenu.css';

const mapStateToProps = (state) => ({ ...state });

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleExitClick = () => {
    const { changeUserAuthStatus: changeUserAuthStatusAction, location, navigate } = this.props;
    axios
      .post('/api/auth/signoff')
      .then(() => {
      // eslint-disable-next-line no-underscore-dangle
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('email');
        window.localStorage.setItem('isUserLoggedIn', false);
        changeUserAuthStatusAction(false);

        if (location.pathname === '/profile') {
          navigate('/');
        }
      });
  };

  render() {
    return (
      <div>
        <div className="user_menu_item_w">
          <Link to="/profile" className="user_menu_link">Личный кабинет</Link>
        </div>
        <div className="user_menu_item_w">
          <button type="button" className="user_menu_item" onClick={this.handleExitClick}>Выйти</button>
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  changeUserAuthStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { changeUserAuthStatus })(UserMenu);
