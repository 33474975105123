/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import {
  getOrderById, approveOrder, declineOrder, deleteOrder,
  setInProgressOrder,
} from '../../../Utils/RestUtils';
import Order from '../../Order/Order';
import {
  SUCCESS_STATUS, SUCCESS_TEXT, ERROR_TEXT, ERROR_STATUS,
  ORDER_WAIT_APPROVAL_STATUS, ORDER_WAIT_APPROVAL,
  ORDER_APPROVED_STATUS, ORDER_APPROVED, ORDER_DECLINED_STATUS, ORDER_DECLINED,
} from '../../../Utils/Constants/GlobalConstants';
import './css/AdminOrder.css';

class AdminOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      message: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;

    const queryParameters = new URLSearchParams(location.search);

    if (queryParameters.has('id') && queryParameters.get('id') !== '') {
      const id = queryParameters.get('id');
      const token = window.localStorage.getItem('accessToken');

      getOrderById(id, token).then((response) => {
        if (response && response.data !== ERROR_STATUS) {
          this.setState({ order: response.data });
        }
      }).catch(() => {});
    }
  }

  handleApprove = (id) => {
    const token = window.localStorage.getItem('accessToken');

    approveOrder(id, token).then((resoponse) => {
      if (resoponse && resoponse.data === SUCCESS_STATUS) {
        this.setState({ message: SUCCESS_TEXT });
      } else {
        this.setState({ message: ERROR_TEXT });
      }
    }).catch(() => {});

    window.location.reload();
  };

  handleWaitApproval = (id) => {
    const token = window.localStorage.getItem('accessToken');

    setInProgressOrder(id, token).then((resoponse) => {
      if (resoponse && resoponse.data === SUCCESS_STATUS) {
        this.setState({ message: SUCCESS_TEXT });
      } else {
        this.setState({ message: ERROR_TEXT });
      }
    }).catch(() => {});

    window.location.reload();
  };

  handleDecline = (id) => {
    const token = window.localStorage.getItem('accessToken');

    declineOrder(id, token).then((resoponse) => {
      if (resoponse && resoponse.data === SUCCESS_STATUS) {
        this.setState({ message: SUCCESS_TEXT });
      } else {
        this.setState({ message: ERROR_TEXT });
      }
    }).catch(() => {});

    window.location.reload();
  };

  handleDelete = (id) => {
    const token = window.localStorage.getItem('accessToken');

    deleteOrder(id, token).then((resoponse) => {
      if (resoponse && resoponse.data === SUCCESS_STATUS) {
        this.setState({ message: SUCCESS_TEXT });
      } else {
        this.setState({ message: ERROR_TEXT });
      }
    }).catch(() => {});

    window.location.reload();
  };

  render() {
    const { order, message } = this.state;
    return (
      <div className="container">
        <div className="content">
          {order
          && (
          <div>
            <Order order={order} />
            <div className="adm_order_but_w">
              { order.status !== ORDER_WAIT_APPROVAL_STATUS
                && <button type="button" className="btn adm_order_but_ip_progress" onClick={() => this.handleWaitApproval(order.id)}>{ORDER_WAIT_APPROVAL}</button>}

              { order.status !== ORDER_APPROVED_STATUS
                && <button type="button" className="btn adm_order_but_approve" onClick={() => this.handleApprove(order.id)}>{ORDER_APPROVED}</button>}

              { order.status !== ORDER_DECLINED_STATUS
                && <button type="button" className="btn adm_order_but_decline" onClick={() => this.handleDecline(order.id)}>{ORDER_DECLINED}</button>}
            </div>
            <div className="adm_order_delete_w">
              <button type="button" className="btn adm_order_but_delete" onClick={() => this.handleDelete(order.id)}>Удалить</button>
            </div>
            {message.length > 0 && <div className="adm_order_message">{message}</div>}
          </div>
          )}
        </div>
      </div>
    );
  }
}

AdminOrder.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default AdminOrder;
