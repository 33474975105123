/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-trailing-spaces */
import React from 'react';
import { Link } from 'react-router-dom';
import './css/Verify.css';
import PropTypes from 'prop-types';
import { verifyEmail, setUserPassword } from '../../Utils/RestUtils';
import { PASSWORD_LABEL, SAVE } from '../../Utils/Constants/GlobalConstants';

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      passwordValue: '',
      email: '',
      isPasswordValid: false,
      isProcessed: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    const queryParameters = new URLSearchParams(location.search);

    let emailPayload;
    if (queryParameters.has('email') && queryParameters.get('email') !== '') {
      emailPayload = queryParameters.get('email');
      this.setState({ email: emailPayload });
    }

    let codePayload;
    if (queryParameters.has('code') && queryParameters.get('code') !== '') {
      codePayload = queryParameters.get('code');
    }

    let passwordPresent;
    if (queryParameters.has('pass') && queryParameters.get('pass') !== '') {
      const passwordPresentValue = queryParameters.get('pass');

      if (passwordPresentValue === 'true') {
        passwordPresent = true;
      } else {
        passwordPresent = false;
      }
    }

    if (emailPayload && codePayload) {
      const payload = {
        email: emailPayload,
        code: codePayload,
      };

      this.verify(payload, passwordPresent);
    } else {
      this.setState({ isProcessed: true, message: 'Произошла ошибка' });
    }
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ passwordValue: password }, () => {
      this.passwordValidator();
    });
  };

  passwordValidator = () => {
    const { passwordValue } = this.state;
    if (passwordValue.length >= 8) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };

  handleClick = () => {
    const { passwordValue, email } = this.state;
  
    const payload = {
      password: passwordValue,
      email,
    };

    setUserPassword(payload).then((response) => {
      if (response.data === 'success') {
        this.setState({ message: 'Регистрация в личном кабинете завершена' });
      } else {
        this.setState({ message: 'Произошла ошибка' });
      }
    }).catch(() => {
      this.setState({ message: 'Произошла ошибка' });
    }).finally(() => {
      this.setState({ isProcessed: true });
    });
  };

  verify(payload, passwordPresent) {
    verifyEmail(payload)
      .then((response) => {
        if (response.data === 'success') {
          this.setState({ message: 'Регистрация в личном кабинете завершена' });

          if (passwordPresent) {
            this.setState({ isProcessed: true });
          }
        } else {
          this.setState({ message: 'Произошла ошибка' });
          this.setState({ isProcessed: true });
        }
      }).catch(() => {
        this.setState({ message: 'Произошла ошибка' });
        this.setState({ isProcessed: true });
      });
  }

  render() {
    const {
      message, passwordValue, isPasswordValid, isProcessed,
    } = this.state;

    return (
      <div className="content">
        <div className="v_intro">
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="v_intro__wave"></div>
        </div>
        <div className="container">

          {isProcessed
            ? (
              <div className="v_message_wrap">
                <div className="v_message">{message}</div>
                <Link className="btn btn__yellow" to="/">На главную</Link>
              </div>
            )
            : (
              <div className="v_form_wrapper">
                <div>
                  <div className="v_pass">{PASSWORD_LABEL}</div>
                  <input
                    value={passwordValue}
                    className="login_form_inp form_input"
                    onChange={this.handlePasswordChange}
                    type="password"
                  />
                  <div className="v_pass_hint">Не менее 8 символов</div>
                  <button
                    type="submit"
                    className={`btn btn__yellow login_btn ${isPasswordValid ? '' : 'btn_dis'}`}
                    disabled={!isPasswordValid}
                    onClick={this.handleClick}
                  >
                    {SAVE}
                  </button>
                </div>
              </div>
            )}
          
          <div className="v_empty"></div>
        </div>
      </div>
    );
  }
}

Verify.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Verify;
