import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Checkout from './Checkout';

function CheckoutFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Checkout location={location} navigate={navigate} />
  );
}

export default CheckoutFunc;
