/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-trailing-spaces */
import React from 'react';
import { Link } from 'react-router-dom';
import './css/Footer.css';
import InstaIco from '../../images/insta-ico.png';
import PayAlfa from '../../images/pay-alfa.png';
import payApple from '../../images/pay-apple.png';
import PayBel from '../../images/pay-bel.png';
import PayBelcard from '../../images/pay-belcard.png';
import PayIdCheck from '../../images/pay-id-check.png';
import PayMaster from '../../images/pay-mastercard.png';
import PaySamsung from '../../images/pay-samsung.png';
import PayVisaSecure from '../../images/pay-visa-secure.png';
import PayVisa from '../../images/pay-visa.png';
import PayYa from '../../images/pay-ya.png';
import { getCurrentYear } from '../../Utils/DateUtil';
import { TOUR_HUNTER } from '../../Utils/Constants/FooterConstants';

function Footer() {
  function scrollTop() {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="foot_info_block_w">
          <div className="foot_info_block">
            <p className="foot_block_title">Наш адрес и телефоны</p>
            <p className="foot_info_text">ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ НОВИК НАТАЛЬЯ СТАНИСЛАВОВНА</p>
            <p className="foot_info_text">УНП 692232544</p>
            <p className="foot_info_text">220081, Республика Беларусь, д. Копище, ул. Братьев Райт 7, оф. 92</p>
            <p className="foot_info_text">
              Свидетельство о государственной регистрации №692232544, 
              выдано Минским райисполкомом 23.10.2023 г.
            </p>
            {/* <p className="foot_info_text">
              Интернет-магазин включен в Торговый реестр Республики Беларусь 01.01.2001 за №111111
            </p> */}
            <p className="foot_info_text">+375 (29) 156 99 38</p>
          </div>
          <div className="foot_info_block">
            <p className="foot_block_title">
              Информация
            </p>
            <p>
              <Link className="footer__nav__item" target="_blank" to="/file/oferta.pdf">
                Публичный договор
              </Link>
            </p>
            <p>
              <Link className="footer__nav__item" target="_blank" to="/file/pravila.pdf">
                Правила оплаты
              </Link>
            </p>
            <p>
              <Link className="footer__nav__item" target="_blank" to="/file/politika.pdf">
                Политика в отношении обработки персональных данных
              </Link>
            </p>
            <p>
              <Link className="footer__nav__item" target="_blank" to="/file/soglashenie.pdf">
                Соглашение об обработке персональных данных
              </Link>
            </p>
            <p>
              <Link className="footer__nav__item" to="/faq" onClick={scrollTop}>
                Часто задаваемые вопросы
              </Link>
            </p>
          </div>
          <div className="foot_info_block">
            <p className="foot_block_title">
              Время работы
            </p>
            <p className="footer__nav__item">
              Ежедневно, с 9.00 до 20.00
            </p>
          </div>
        </div>
        <div className="foot_payment_methods">
          <img className="foot_payment_item pay-alfa" alt="" src={PayAlfa} />
          <img className="foot_payment_item pay-visa" alt="" src={PayVisa} />
          <img className="foot_payment_item pay-visa-secure" alt="" src={PayVisaSecure} />
          <img className="foot_payment_item pay-id-check" alt="" src={PayIdCheck} />
          <img className="foot_payment_item pay-mastercard" alt="" src={PayMaster} />
          <img className="foot_payment_item pay-belcard" alt="" src={PayBelcard} />
          <img className="foot_payment_item pay-ya" alt="" src={PayYa} />
          <img className="foot_payment_item pay-bel" alt="" src={PayBel} />
          <img className="foot_payment_item pay-samsung" alt="" src={PaySamsung} />
          <img className="foot_payment_item pay-apple" alt="" src={payApple} />
        </div>
        <div className="foot_copyright_w">
          <div className="copyright">
            {TOUR_HUNTER}
            &nbsp;
            &copy;
            &nbsp;
            {getCurrentYear()}
          </div>
          <Link target="_blank" to="https://www.instagram.com/tourhunter.by" className="social">
            <img className="social__item" src={InstaIco} alt="" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
