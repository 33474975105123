import React, { createRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FallingLines } from 'react-loader-spinner';
import {
  LOGIN_TITLE, EMAIL_ADDRESS_LABEL, PASSWORD_LABEL, REGISTER,
} from '../../../Utils/Constants/GlobalConstants';
import { changeUserAuthStatus } from '../../../DataStore/actions/searchFormActions';
import './css/RegisterForm.css';

const mapStateToProps = (state) => ({ ...state });

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      passwordValue: '',
      emailWasFocused: false,
      isEmailValid: false,
      isPasswordValid: false,
      name: '',
      errorMessage: '',
      isSubmitSpinnerVisible: false,
    };
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ passwordValue: password }, () => {
      this.passwordValidator();
    });
  };

  handleEmailChange = (e) => {
    const { emailWasFocused } = this.state;
    const emailValue = e.target.value;
    this.setState({ emailValue });

    if (emailWasFocused) {
      if (this.emailValidation()) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
    }
  };

  passwordValidator = () => {
    const { passwordValue } = this.state;
    if (passwordValue.length >= 8) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };

  emailValidation = () => {
    const { emailValue } = this.state;
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailValue || regex.test(emailValue) === false) {
      // this.setState({ error: 'Email is not valid' });
      return false;
    }
    return true;
  };

  handleEmailInputBlur = () => {
    const { emailValue } = this.state;
    if (emailValue.length > 0) {
      if (this.emailValidation()) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
      this.setState({ emailWasFocused: true });
    }
  };

  register = (btnRef) => {
    this.setState({ isSubmitSpinnerVisible: true });
    btnRef.current.setAttribute('disabled', 'disabled');

    const { emailValue, passwordValue, name } = this.state;
    const { closeModal, changeUserAuthStatus: changeUserAuthStatusAction, location } = this.props;
    axios
      .post(
        '/api/auth/signup',
        {
          username: name,
          email: emailValue,
          password: passwordValue,
          role: ['user'],
        },
      )
      .then((res) => {
      // eslint-disable-next-line no-underscore-dangle
        window.localStorage.setItem('accessToken', res.data.accessToken);
        window.localStorage.setItem('email', res.data.email);
        window.localStorage.setItem('isUserLoggedIn', true);

        changeUserAuthStatusAction(true);
        closeModal();

        if (location.pathname === '/profile') {
          window.location.reload();
        }
      }).catch((error) => {
        if (error.code === 'ERR_BAD_REQUEST') {
          if (error.response.data.message === 'Error: Email is already in use!') {
            this.setState({ errorMessage: 'Пользователь с этим email уже зарегистрирован' });
          }
        }
      });
  };

  render() {
    const {
      emailValue, passwordValue,
      isEmailValid, emailWasFocused, isPasswordValid, errorMessage,
      isSubmitSpinnerVisible,
    } = this.state;

    const btnRef = createRef();

    return (
      <div>
        <div>
          <span className="loginModalTitle">{LOGIN_TITLE}</span>
        </div>
        <div className="login_form">
          <div className="modalInputWrapper">
            <div>{EMAIL_ADDRESS_LABEL}</div>
            <input
              value={emailValue}
              className={`form_input login_form_inp ${!isEmailValid && emailWasFocused ? 'invalidInput' : ''}`}
              onChange={this.handleEmailChange}
              placeholder="example@gmail.com"
              onBlur={this.handleEmailInputBlur}
            />
          </div>
          <div className="modalInputWrapper">
            <div>{PASSWORD_LABEL}</div>
            <input
              value={passwordValue}
              className={`form_input login_form_inp ${isEmailValid ? '' : ''}`}
              onChange={this.handlePasswordChange}
              type="password"
            />
          </div>
          <div className="reg_pass_hint">Не менее 8 символов</div>
          <button
            ref={btnRef}
            type="button"
            className={`btn btn__yellow reg_btn ${isPasswordValid && isEmailValid ? '' : 'btn_dis'}`}
            disabled={!isPasswordValid && !isEmailValid}
            onClick={() => this.register(btnRef)}
          >
            <div className="reg_butt_w">
              {REGISTER}
              <div className="reg_submit_spinner_w">
                <FallingLines
                  color="#003399"
                  width="30"
                  visible={isSubmitSpinnerVisible}
                  ariaLabel="falling-circles-loading"
                />
              </div>
            </div>
          </button>
          <div className="showReg_wparrer loginFormError">{ errorMessage }</div>
        </div>
      </div>
    );
  }
}

RegisterForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  changeUserAuthStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, { changeUserAuthStatus })(RegisterForm);
