import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CITY_FROM } from '../../../Utils/Constants/HomePageConstants';
import { CITY_OPTIONS } from '../../../Utils/Constants/CitiesFrom';
import CityFromAutosuggest from './CityFromAutosuggest/CityFromAutosuggest';
import './css/CityFrom.css';

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({
});

class CityFrom extends Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = {
      popupVisible: false,
    };
  }

  handleClick() {
    const { popupVisible } = this.state;
    if (!popupVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  render() {
    const { popupVisible } = this.state;
    const { depatureCity } = this.props;
    return (
      <div className="sf__city__from" ref={(node) => { this.node = node; }}>
        <div className="sf__city__text">{CITY_FROM}</div>
        <button type="button" className="sf__city__link" onClick={this.handleClick}>{depatureCity.name}</button>
        {popupVisible && (
          <CityFromAutosuggest options={CITY_OPTIONS} />
        )}
      </div>
    );
  }
}

CityFrom.propTypes = {
  depatureCity: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CityFrom);
