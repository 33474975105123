import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HotelDetails from './HotelDetails';

function HotelDetailsFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <HotelDetails location={location} navigate={navigate} />
    </div>
  );
}

export default HotelDetailsFunc;
