export default (state, action) => {
  switch (action.type) {
    // change Adults count
    case 'increaseAdults':
      if (state.adults < 4) {
        return {
          ...state,
          adults: state.adults + 1,
        };
      }
      return state;

    case 'decreaseAdults':
      if (state.adults > 1) {
        return {
          ...state,
          adults: state.adults - 1,
        };
      }
      return state;

    case 'setAdults':
      return {
        ...state,
        adults: action.payload,
      };

    // change Kids count
    case 'setKids':
      return {
        ...state,
        kids: action.payload,
      };

    // change Nights count
    case 'increaseNightsFrom':
      if (state.nightsFrom < 29 && state.nightsFrom === state.nightsTo) {
        return {
          ...state,
          nightsFrom: state.nightsFrom + 1,
          nightsTo: state.nightsTo + 1,
        };
      }
      if (state.nightsFrom < 29 && state.nightsFrom !== state.nightsTo) {
        return {
          ...state,
          nightsFrom: state.nightsFrom + 1,
        };
      }

      return state;

    case 'decreaseNightsFrom':
      if (state.nightsFrom > 1) {
        return {
          ...state,
          nightsFrom: state.nightsFrom - 1,
        };
      }
      return state;

    case 'setNightsFrom':
      return {
        ...state,
        nightsFrom: action.payload,
      };

    case 'increaseNightsTo':
      if (state.nightsTo < 29) {
        return {
          ...state,
          nightsTo: state.nightsTo + 1,
        };
      }
      return state;

    case 'decreaseNightsTo':
      if (state.nightsTo > 1 && state.nightsTo === state.nightsFrom) {
        return {
          ...state,
          nightsFrom: state.nightsFrom - 1,
          nightsTo: state.nightsTo - 1,
        };
      }
      if (state.nightsTo > 1 && state.nightsTo !== state.nightsFrom) {
        return {
          ...state,
          nightsTo: state.nightsTo - 1,
        };
      }
      return state;

    case 'setNightsTo':
      return {
        ...state,
        nightsTo: action.payload,
      };

    case 'changeDepatureCity':
      if (action.payload.name === 'Минск' || action.payload.name === 'Брест' || action.payload.name === 'Гродно'
      || action.payload.name === 'Могилев' || action.payload.name === 'Витебск' || action.payload.name === 'Гомель') {
        return {
          ...state,
          depatureCity: action.payload,
        };
      }
      return state;

    case 'changeSelectedResorts':
      return {
        ...state,
        selectedResorts: action.payload,
      };

    case 'changeResortOptions':
      return {
        ...state,
        resortOptions: action.payload,
      };

    case 'changeDestination':
      return {
        ...state,
        destination: action.payload,
      };

    case 'changeSearchInputValue':
      return {
        ...state,
        searchInputValue: action.payload,
      };

    case 'changeDeparturetDate':
      return {
        ...state,
        departureDate: action.payload,
      };

    case 'changeAvailableDays':
      return {
        ...state,
        availableDays: action.payload,
      };

    case 'changeSearchResult':
      return {
        ...state,
        searchResult: action.payload,
      };

    case 'changeLoginModalVisibility':
      return {
        ...state,
        isLoginModalVisible: action.payload,
      };

    case 'changeUserAuthStatus':
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };

    case 'changeTabletFilterMenuVisibility':
      return {
        ...state,
        tabletFilterMenuVisibility: action.payload,
      };

    case 'changeTimeIsUpVisibility':
      return {
        ...state,
        timeIsUpModalVisible: action.payload,
      };

    case 'changeHotelDetailsFilterMenuVisibility':
      return {
        ...state,
        hotelDetailsFilterMenuVisibility: action.payload,
      };

    case 'changeCharterOnly':
      return {
        ...state,
        charterOnly: action.payload,
      };

    default: return state;
  }
};
