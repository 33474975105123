import React from 'react';
import './css/Intro.css';
import SearchFormFunc from '../../SearchForm/SearchFormFunc';
import {
  INTRO_TITLE_LINE1, INTRO_TITLE_LINE2, INTRO_SUBTITLE_LINE1, INTRO_SUBTITLE_LINE2, INTRO_WARRANTY,
} from '../../../Utils/Constants/HomePageConstants';
import { HOME_PAGE } from '../../../Utils/Constants/GlobalConstants';
import Warranty from '../../../images/warranty.svg';

function Intro() {
  return (
    <div className="intro">
      <div className="container">
        <div className="intro__inner">
          <div className="intro__title">
            {INTRO_TITLE_LINE1}
            <br />
            {INTRO_TITLE_LINE2}
          </div>
          <div className="intro__subtitle">
            {INTRO_SUBTITLE_LINE1}
            <br />
            {INTRO_SUBTITLE_LINE2}
          </div>
          <div className="intro_warranty_w">
            <img alt="" src={Warranty} />
            <span className="intro_warranty">{INTRO_WARRANTY}</span>
          </div>
          <SearchFormFunc page={HOME_PAGE} />
        </div>
      </div>
      {/* eslint-disable-next-line react/self-closing-comp */}
      <div className="intro__wave"></div>
    </div>
  );
}

export default Intro;
