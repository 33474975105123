import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  ...state,
});

class TouristsInputValue extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    const { adults, kids } = this.props;
    let value;

    if (adults === 1) {
      value = `${adults} взрослый`;
    } else {
      value = `${adults} взрослых`;
    }

    if (kids.length > 0) {
      if (kids.length === 1) {
        value = `${value}, 1 ребенок`;
      } else {
        value = `${value}, ${kids.length} детей`;
      }
    }

    return (
      <div>
        {value}
      </div>
    );
  }
}

TouristsInputValue.propTypes = {
  adults: PropTypes.number.isRequired,
  kids: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, null)(TouristsInputValue);
