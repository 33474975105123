import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal';

function LoginModalFunk() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <LoginModal location={location} navigate={navigate} />
  );
}

export default LoginModalFunk;
