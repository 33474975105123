import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Verify from './Verify';

function VerifyFunc() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Verify location={location} navigate={navigate} />
  );
}

export default VerifyFunc;
