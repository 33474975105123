import axios from 'axios';

export function getSearchResult(payload) {
  return axios
    .post(
      '/api/search',
      payload,
    );
}

export function initTourSearch(payload) {
  return axios
    .post(
      '/api/search/init',
      payload,
    );
}

export function getToursPartial(payload) {
  return axios
    .post(
      '/api/search/tours',
      payload,
    );
}

export function getSearchStatus(requestId) {
  return axios
    .get(`/api/search/status/${requestId}`);
}

export function getTourDates(depatureCity, destination, resorts) {
  return axios
    .get(`/Main.svc/GetTourDates?dptCityId=${depatureCity}&countryId=${destination}&resorts=${resorts}`);
}

export function getHotels(countrId) {
  return axios
    .get(`/api/hotels/${countrId}`);
}

export function getTourPrice(payload) {
  return axios
    .post(
      '/api/price',
      payload,
    );
}

export function submitOrder(payload) {
  return axios
    .post(
      '/api/order/create',
      payload,
    );
}

export function verifyEmail(payload) {
  return axios
    .post(
      '/api/auth/verify',
      payload,
    );
}

export function setUserPassword(payload) {
  return axios
    .post(
      '/api/auth/pass',
      payload,
    );
}

export function getOrderById(id, token) {
  return axios
    .get(`/api/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

export function approveOrder(id, token) {
  return axios
    .post(`/api/order/approve/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

export function setInProgressOrder(id, token) {
  return axios
    .post(`/api/order/waitapproval/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

export function declineOrder(id, token) {
  return axios
    .post(`/api/order/decline/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

export function deleteOrder(id, token) {
  return axios
    .post(`/api/order/delete/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

export function getSystemSettings() {
  return axios
    .get('/api/system/settings');
}
