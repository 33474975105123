import React from 'react';
import PropTypes from 'prop-types';

function HotelInputValue(props) {
  const { value } = props;

  return (
    <div>{value}</div>
  );
}

HotelInputValue.propTypes = {
  value: PropTypes.string,
};

HotelInputValue.defaultProps = {
  value: '',
};

export default HotelInputValue;
