export const PHONE_NUMBER = '8(029) 156-99-38';
export const EMAIL = 'info@tourhunter.by';

export const HOME_PAGE = 'HOME_PAGE';
export const TOUR_SEARCH_RESULT = 'TOUR_SEARCH_RESULT';
export const HOTEL_DETAILS = 'HOTEL_DETAILS';
export const NOT_AVAILABLE = 'Временно недоступно';
export const TURKEY_COUNTRY_ID = 119;

// Order statuses
export const ORDER_WAIT_PAYMENT_STATUS = 'WAIT_PAYMENT';
export const ORDER_WAIT_APPROVAL_STATUS = 'WAIT_APPROVAL';
export const ORDER_APPROVED_STATUS = 'APPROVED';
export const ORDER_DECLINED_STATUS = 'DECLINED';

export const ORDER_WAIT_PAIMENT = 'Ожидает оплату';
export const ORDER_WAIT_APPROVAL = 'Ожидает подтверждение';
export const ORDER_APPROVED = 'Подтвержден';
export const ORDER_DECLINED = 'Отклонен';

// login modal
export const LOGIN_TITLE = 'Вход в личный кабинет';
export const EMAIL_ADDRESS_LABEL = 'Адрес электронной почты';
export const PASSWORD_LABEL = 'Пароль';
export const ENTER = 'Войти';
export const SAVE = 'Сохранить';
export const NO_ACCOUNT = 'У меня нет аккаунта.';
export const START_REG = 'Зарегистрироваться';
export const REGISTER = 'Регистрация';
// Errors
export const INCORRECT_EMAIL_FORMAT = 'Неверный формат электронной почты';
export const BAD_CREDENTIALS = 'Неверный пароль или адрес электронной почты';

export const SUCCESS_STATUS = 'SUCCESS';
export const ERROR_STATUS = 'ERROR';

export const SUCCESS_TEXT = 'Успешно';
export const ERROR_TEXT = 'Ошибка';

export const EGYPT_COUNTRY_ID = '40';
export const GEORGIA_COUNTRY_ID = '36';
export const UAE_COUNTRY_ID = '90';
export const TURKEY_ID = '119';
