import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function Timer(props) {
  const [timer, setTimer] = useState('00:00');

  const Ref = useRef(null);

  const { time, timeIsUp } = props;

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    return {
      total,
      // hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const {
      total, minutes, seconds,
    } = getTimeRemaining(e);

    if (total >= 0) {
      const min = minutes > 9 ? minutes : `0${minutes}`;
      const sec = seconds > 9 ? seconds : `0${seconds}`;

      setTimer(`${min}:${sec}`);

      if (min === '00' && sec === '00') {
        // eslint-disable-next-line no-use-before-define
        timeIsUp(clearTimer);
      }
    }
  };

  const getDeadTime = () => {
    const deadline = new Date();

    // deadline.setSeconds(deadline.getSeconds() + 10);
    deadline.setMinutes(deadline.getMinutes() + time);
    return deadline;
  };

  const clearTimer = () => {
    const deadLine = getDeadTime();
    setTimer(`${time}:00`);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(deadLine);
    }, 1000);
    Ref.current = id;
  };

  useEffect(() => {
    clearTimer();
  }, []);

  return (
    <span className="time_count_down">
      {timer}
    </span>
  );
}

Timer.propTypes = {
  time: PropTypes.number,
  timeIsUp: PropTypes.func,
};

Timer.defaultProps = {
  time: 20,
  timeIsUp: null,
};

export default Timer;
